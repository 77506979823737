import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// MATERIAL UI
import { 
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// ICONS
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// CONSTANTS
import { logoImage } from "../../constants/static_data/logoImage";
import { fontPreset } from "../../constants/styling/fontStyleConstant";
import { backgroundColor, colorPalette, neutralColor } from "../../constants/styling/colorPalette";
import { captureEventGAWithParams, captureEventPixel, captureEventToDB } from "../../constants/capture_event/captureEventConstant";
import { scrollToElem } from "../../constants/utils/scrollActions";
import { priceFormat } from "../../constants/djubliKreditConstant";
import { BARU_CONDITION, BEKAS_CONDITION } from "../../constants/utils/conditionName";
// ACTIONS
import { getSearchCount, listGroupModel, listGroupModelReset, listModelReset, listModelYearReset, notClipanError, resetGroupModel, resetMerk, resetModel, resetModelYear, selectMerk } from "../../actions/carActions";
import { getLiquidationEstimation, resetLiquidationEstimation } from "../../actions/danaTunaiActions";
import { resetKkbEstimation } from "../../actions/promoActions";
import { resetExteriorColorSellPrice, resetGroupModelSellPrice, resetKilometerSellPrice, resetModelSellPrice, resetModelYearSellPrice, resetNomorPolisSellPrice } from "../../actions/sellPriceAction";

const SelectBrandMenu = ({
  brands = [],
  popularBrands = JSON.parse(JSON.stringify(brands))
    .sort((a, b) => b.countResult - a.countResult)
    .splice(0, 8),
  selectedVal,
  label,
  disabled,
  category = "",
  page = "",
  condition = "semua",
  isShowCountResult = true,
  sellType = ""
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const downSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const location = useLocation()

  const [anchorEl, setAnchorEl] = useState(null)
  const [searchValue, setSearchValue] = useState("")
  const [brandOptions, setBrandOptions] = useState(brands)
  const [openDialog, setOpenDialog] = useState(location.hash === "#open-brand")
  // const [openDialog, setOpenDialog] = useState(false)

  const estimationKKBState = useSelector((state) => state.estimationKKBSimulation)
  const userLogin = useSelector((state) => state.userLogin)
  const selectedDropdown = useSelector((state) => state.selectedDropdown)
  const nonAdmin = () => {
    if (userLogin.userInfo?.id !== 0) return true;
    else return false;
  };

  // const handleOpenDialog = () => {
  //   if (downMediumScreen)
  //     scrollToElem({
  //       elemName: "MERK",
  //       options: { duration: 500, smooth: true, offset: -76 },
  //     });
  //   setOpenDialog(true);
  // };
  const handleCloseDialog = () => {
    window.history.back();
    // navigate(`${location.pathname}${location.search}`, { replace: true })
    // setOpenDialog(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    // if (downMediumScreen) navigate(`${location.pathname}#open-brand`)
    // if (downMediumScreen) handleOpenDialog();
    if (downMediumScreen) window.location.hash = "#merk-menu";
    else {
      scrollToElem({
        elemName: "MERK",
        options: { duration: 500, smooth: true, offset: -132 },
      });
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClearSelectedVal = () => {
    dispatch(resetMerk());

    if (category === "Cari Mobil") {
      // UPDATE SEARCH COUNT
      if (condition !== "semua") {
        dispatch(
          getSearchCount({
            condition: condition === "bekas" ? BEKAS_CONDITION : BARU_CONDITION,
            sellType
          })
        );
      } else {
        dispatch(getSearchCount({ sellType }));
      }
    } else if (category === "Dana Tunai") {
      dispatch(resetLiquidationEstimation());
    } else if (category === "KKB") {
      dispatch(resetKkbEstimation())
    } 

    if (selectedDropdown.groupModel !== null) {
      if (category === "Cek Harga Mobil") {
        dispatch(resetGroupModelSellPrice())
        dispatch(resetModelYearSellPrice())
        dispatch(resetModelSellPrice())
        dispatch(resetKilometerSellPrice())
        dispatch(resetExteriorColorSellPrice())
        dispatch(resetNomorPolisSellPrice())
      }
      // RESET SELECTED VALUE
      dispatch(resetGroupModel());
      dispatch(resetModelYear());
      dispatch(resetModel());
      // RESET LIST VALUE
      dispatch(listGroupModelReset());
      dispatch(listModelYearReset());
      dispatch(listModelReset());
    }
  };

  const handleClickWhatsApp = () => {
    if (nonAdmin()) {
      // SAVE TO DB
      captureEventGAWithParams({
        eventName: "Hubungi DjuBli - Merk Tidak Ada",
        params: { category: category, page: page },
      });
      // SAVE TO DB
      captureEventToDB({
        event: { category: category, eventName: "Hubungi DjuBli - Merk Tidak Ada", page: page },
      });
      // SAVE TO PIXEL
      captureEventPixel({
        eventName: `Hubungi DjuBli - Merk Tidak Ada`,
        params: { category: category, page: page },
      });
    }

    window.open(`https://wa.me/62895808993131?text=Hai DjuBli! Saya inign mencari mobil *${searchValue}* pada *${page}*%0A%0A Selengkapnya ada di ${window.location.href}`)
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    setBrandOptions(brands);
  }, [brands]);

  // useEffect(() => {
  //   if(downMediumScreen && !disabled) {
  //     if(location.hash === "#open-brand") handleOpenDialog()
  //     else setOpenDialog(false)
  //   }
  // }, [location.hash])

  useEffect(() => {
    const onHashChange = () => setOpenDialog(window.location.hash === "#merk-menu");
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, [])

  const buttonStyle = {
    height: "100%",
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: selectedVal === "" ? "14px 12px" : "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "white",
    borderColor: neutralColor.neutral50,
    color: neutralColor.neutral100,
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: backgroundColor,
      borderColor: colorPalette.primary,
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: backgroundColor,
      borderColor: colorPalette.primary,
    },
    "&:focus": {},
    "&:disabled": {
      boxShadow: "none",
      backgroundColor: neutralColor.neutral10,
      borderColor: neutralColor.neutral10,
    },
  };

  const selectBrand = (selectedBrand) => {
    if (nonAdmin()) {
      // SAVE TO DB
      captureEventGAWithParams({
        eventName: "Select Brand",
        params: { category: category, page: page },
      });
      // SAVE TO DB
      captureEventToDB({
        event: { category: category, eventName: "Select Brand", page: page },
      });
      // SAVE TO PIXEL
      captureEventPixel({
        eventName: `Select Brand`,
        params: { category: category, page: page },
      });
    }

    // RESET VALUE JIKA ADA SETELAH MERK YANG TERPILIH
    if (selectedDropdown.groupModel !== null) {
      // RESET SELECTED VALUE
      dispatch(resetGroupModel());
      dispatch(resetModelYear());
      dispatch(resetModel());
      // RESET LIST VALUE
      dispatch(listGroupModelReset());
      dispatch(listModelYearReset());
      dispatch(listModelReset());
    }

    if (category === "Cari Mobil") {
      if (condition !== "semua") {
        dispatch(
          getSearchCount({
            brandId: selectedBrand.id,
            condition: condition === "bekas" ? BEKAS_CONDITION : BARU_CONDITION,
            sellType
          })
        );
      } else {
        dispatch(getSearchCount({ brandId: selectedBrand.id, sellType }));
      }
    }

    dispatch(selectMerk(selectedBrand));
    dispatch(listGroupModel(selectedBrand.id, isShowCountResult));

    if (category === "Dana Tunai" || category === "KKB") {
      // TIDAK ADA MRP
      if (selectedBrand.isClipan === false) {
        dispatch(notClipanError())
        dispatch(resetLiquidationEstimation())
      } else {
        // ADA MRP
        dispatch(getLiquidationEstimation({ brandId: selectedBrand.id }));
      }

      if(category === "KKB" && estimationKKBState.estimation !== null) {
        dispatch(resetKkbEstimation())
      }
    }

    if (downMediumScreen) handleCloseDialog();
    else handleClose();
  };

  const searchSection = () => (
    <>
      <Box
        className="brands__search"
        sx={{ padding: downMediumScreen ? "1.5rem 1.5rem 1rem" : "1.5rem" }}
      >
        <TextField
          placeholder={"Cari Merek"}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);

            const result = brands.filter((brand) => {
              if (e.target.value === "") return brands;
              return brand.name
                .toLowerCase()
                .includes(e.target.value.toLowerCase());
            });
            setBrandOptions(result);
          }}
          size={"regular"}
          variant={"outlined"}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ cursor: "pointer" }}
                onClick={() => {}}
              >
                <SearchIcon height={18} width={18} />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Box>

      <Box
        className="root__brands"
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "16px",
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: downMediumScreen
            ? "calc(100vh - 146px - 1.7rem)"
            : "320px",
          padding: "0 1.5rem 1.5rem",
        }}
      >
        {searchValue === "" || !downMediumScreen ? (
          <Box className="popular__brands">
            <Typography
              variant="h6"
              sx={{ ...fontPreset.body16Bold, marginBottom: "8px" }}
            >
              Merek Populer
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={2}>
              {popularBrands.map((brand, index) => (
                <Grid item key={`popular-${brand.id}`} xs={6} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "8px",
                      alignItems: "center",
                      "&:hover": {
                        cursor: "pointer",
                        color: colorPalette.primary,
                      },
                    }}
                    onClick={() => selectBrand(brand)}
                  >
                    <Box
                      sx={{
                        padding: "4px",
                        height: "34px",
                        width: "34px",
                        border: `1px solid lightgray`,
                        borderRadius: "4px",
                        "&:hover": {
                          border: `1px solid ${colorPalette.primary}`,
                        },
                      }}
                    >
                      <LazyLoadImage
                        src={printLogoSrc(brand.id)}
                        alt={`logo-${brand.name}-${index + 1}`}
                        height={"34px"}
                        width={"34px"}
                      />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box>
                        <Typography
                          variant="body2"
                          sx={{ ...fontPreset.body14Reg }}
                        >
                          {brand.name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : null}

        {searchValue === "" || brandOptions.length !== 0 ? 
          <>
            <Divider />

            <Box className="alphabetical__brands">
              <Typography
                variant="body1"
                sx={{ ...fontPreset.body16Bold, marginBottom: "8px" }}
              >
                Pilih Merek
              </Typography>
              <ul
                style={{
                  listStyleType: "none",
                  margin: 0,
                  padding: 0,
                  columns: downMediumScreen ? "2" : "3",
                  columnGap: "16px",
                }}
              >
                {brandOptions.map((brand) => (
                  <li
                    key={`brand-${brand.id}`}
                    style={{ width: !downMediumScreen ? "144px" : "" }}
                    onClick={() => selectBrand(brand)}
                  >
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          ...fontPreset.body14Reg,
                          marginBottom: "8px",
                          transition: "color .1s ease",
                          color:
                            selectedVal.id === brand.id ? colorPalette.primary : "",
                          fontWeight:
                            selectedVal.id === brand.id ? "bold" : "regular",
                          "&:hover": {
                            cursor: "pointer",
                            color: colorPalette.primary,
                          },
                        }}
                      >
                        {brand.name}
                      </Typography>

                      {category !== "Dana Tunai" && isShowCountResult && (
                        <Typography
                          variant="body2"
                          sx={{
                            ...fontPreset.body14Reg,
                            marginBottom: "8px",
                            transition: "color .1s ease",
                            color: selectedVal.id === brand.id ? colorPalette.primary : "",
                            fontWeight: selectedVal.id === brand.id ? "bold" : "regular",
                            "&:hover": {
                              cursor: "pointer",
                              color: colorPalette.primary,
                            },
                          }}
                        >
                          ({priceFormat(brand.countResult ?? 0)})
                        </Typography>
                      )}
                    </Box>
                  </li>
                ))}
              </ul>
            </Box> 
          </> :
          NotFoundCallDjuBliElement({})
        }

        { brandOptions.length !== 0 && 
          <>
            <Divider />
            { NotFoundCallDjuBliElement({ title: "Tidak Menemukan Pilihan?" }) }
          </>
        }
      </Box>
    </>
  );

  const NotFoundCallDjuBliElement = ({ title = "Hasil pencarian tidak ditemukan.", body = "Coba hubungi DjuBli" }) => {
    return (
      <Box className="not__found__result"
        sx={{ 
          padding: "16px",
          display: "flex",
          alignItems: "center",
          rowGap: "12px",
          flexDirection: "column"
        }}
      >
        <Box>
          <Typography
            variant="body1"
            sx={downSmallScreen ? fontPreset.body14Bold : fontPreset.body16Bold}
            align="center"
          >
            { title }
          </Typography>
          <Typography
            variant="body1"
            sx={downSmallScreen ? fontPreset.body14Reg : fontPreset.body16Reg}
            align="center"
          >
            { body }
          </Typography>
        </Box>

        <Button
          variant="contained"
          onClick={handleClickWhatsApp}
          endIcon={<WhatsAppIcon />}
          size={downSmallScreen ? "small" : "regular"}
        >
          Hubungi DjuBli
        </Button>
      </Box>
    )
  };

  const printLogoSrc = (brandId) => {
    const resLogo = logoImage.filter((logo) => logo.id === brandId);

    if (resLogo.length === 0) return "";
    return resLogo[0].image;
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        columnGap: "8px",
        alignItems: "center",
      }}
    >
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        fullWidth
        disableRipple
        sx={buttonStyle}
        endIcon={
          <div
            className="clear__button"
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "2px",
              padding: "0",
              margin: "0",
            }}
          >
            {selectedVal !== "" && (
              <Box
                sx={{
                  padding: "0",
                  margin: "0",
                  borderRadius: "50%",
                  width: "25px",
                  height: "25px",
                  transition: "background-color .1s",
                  "&:hover": { bgcolor: neutralColor.neutral30 },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClearSelectedVal();
                }}
              >
                <CloseIcon
                  fontSize="small"
                  sx={{ color: neutralColor.neutral90 }}
                />
              </Box>
            )}
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </div>
        }
        disabled={disabled}
      >
        <Box>
          <Typography
            variant="body1"
            sx={
              selectedVal !== ""
                ? { ...fontPreset.body12Reg }
                : { ...fontPreset.body16Reg }
            }
            align="left"
          >
            {label}
          </Typography>
          <Typography
            variant="body2"
            sx={
              downMediumScreen
                ? { ...fontPreset.body16Bold }
                : { ...fontPreset.body18Bold }
            }
            align="left"
          >
            {selectedVal !== "" ? selectedVal.name : ""}
          </Typography>
        </Box>
      </Button>

      {downMediumScreen ? (
        <Dialog
          fullScreen
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <Box
            className="header__dialog"
            sx={{ display: "flex", padding: "8px 16px", alignItems: "center" }}
          >
            <Typography
              variant="body1"
              sx={{ ...fontPreset.body16Bold, flexGrow: 1 }}
              align="center"
            >
              Merek
            </Typography>

            <IconButton onClick={handleCloseDialog} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />

          <Box className="search__section">{searchSection()}</Box>
        </Dialog>
      ) : (
        // DESKTOP SCREEN
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box className="root__search" sx={{ maxWidth: "640px" }}>
            {searchSection()}
          </Box>
        </Popover>
      )}
    </div>
  );
};

SelectBrandMenu.propTypes = {
  brands: PropTypes.array,
  popularBrands: PropTypes.array,
  selectedVal: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  category: PropTypes.string,
  page: PropTypes.string,
};

export default SelectBrandMenu;
