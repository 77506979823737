import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

// Material UI Components
import {
  Box,
  Container,
  Typography,
  Dialog,
  Paper,
  useTheme,
  useMediaQuery,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Actions

// Components
import { LazyLoadImage } from 'react-lazy-load-image-component'

// Constants
import ScrapingDetailSection from "./ScrapingDetailSection";

/**
 * ScrapingScreen Component
 * Main screen for car scraping
 */
const ScrapingScreen = () => {
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))

  // Add new states
  const [openModal, setOpenModal] = useState(true);
  const [credential, setCredential] = useState('');
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Validation function
  const validateCredential = () => {
    if (credential === process.env.REACT_APP_SCRAPING_CREDENTIAL) {
      setOpenModal(false);
      setIsValid(true);
      setError('');
    } else {
      setError('Invalid credential');
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box 
      sx={{ 
        overflow: "hidden",
        minHeight: { xs: '100%', md: 'calc(90vh - 120px)' }
      }}>
        {/* SEO Meta Tags */}
        <Helmet>
          <title>Scraping Data Mobil</title>
          <meta
            name="description"
            content="Scraping data mobil dari marketplace."
          />
          <meta
            property="og:description"
            content="Scraping data mobil dari marketplace."
          />
          <meta property="og:site_name" content="DjuBli" />
          <link
            rel="canonical"
            href="https://www.djubli.com/scraping"
          />
        </Helmet>

        {/* Replace Modal with Dialog */}
        <Dialog
          open={openModal}
          disableEscapeKeyDown
          maxWidth="xs"
          fullWidth
          aria-labelledby="credential-dialog"
        >
          <Paper sx={{ p: 4 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Enter Credential
            </Typography>
            <FormControl fullWidth variant="outlined" error={!!error} sx={{ mt: 2 }}>
              <InputLabel htmlFor="outlined-adornment-password">Credential</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={credential}
                onChange={(e) => setCredential(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && validateCredential()}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showPassword ? 'hide credential' : 'show credential'}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Credential"
              />
              {error && (
                <Typography variant="caption" color="error" sx={{ mt: 1, ml: 2 }}>
                  {error}
                </Typography>
              )}
            </FormControl>
            <LoadingButton
              fullWidth
              variant="contained"
              onClick={validateCredential}
              sx={{ mt: 2 }}
            >
              Validate
            </LoadingButton>
          </Paper>
        </Dialog>
        
        <Container sx={{ position: 'relative', height: '100%', p: 1,  zIndex: 2 }}>
          
          <Typography
            variant="body2"
            component="h2"
            fontWeight="bold"
            gutterBottom
            fontSize={{ xs: "1rem", md: "2rem" }}
          >
            Scraping Data Mobil
          </Typography>
          {isValid ? (
            <ScrapingDetailSection />
          ): (
            <Box sx={{ mt: 4 }}>
              <LazyLoadImage
                src={`/images/not-found.png`}
                alt={`Anda tidak punya akses pada halaman ini`}
                loading="lazy"
                width={downMediumScreen ? '280px' : '440px'}
                height={downMediumScreen ? '159px' : '278px'}
                effect="blur"
              />
              <Typography 
                variant="h6" 
                component="h2" 
                gutterBottom 
                color="error"
              >
                Anda tidak punya akses pada halaman ini
              </Typography>
            </Box>
          )}
        </Container>

    </Box>
  );
};

export default ScrapingScreen;