import {
    SELECT_MERK,
    SELECT_GROUP_MODEL,
    SELECT_MODEL,
    SELECT_MODEL_YEAR,
    RESET_MERK,
    RESET_GROUP_MODEL,
    RESET_MODEL,
    RESET_MODEL_YEAR,
} from '../constants/brandConstants'
import { 
    RESET_EXTERIOR_COLOR,
    RESET_KILOMETER,
    RESET_NOMOR_POLIS,
    SELECT_EXTERIOR_COLOR,
    SELECT_KILOMETER,
    SELECT_NOMOR_POLIS,
} from '../constants/sellPriceContants'

// ============== INPUT DATA TO SELECTED VALUE ================

// SELECT MERK
// DESC     : SAVE SELECTED CAR BRAND ON STATE
// METHOD   : -
export const selectMerkSellPrice = (selectedBrand) => (dispatch) => {
    dispatch({ type: SELECT_MERK, payload: selectedBrand })
}

// SELECT GROUP MODEL
// DESC     : SAVE SELECTED CAR GROUP MODEL ON STATE
// METHOD   : -
export const selectGroupModelSellPrice = (selectedGroupModel) => (dispatch) => {
    dispatch({ type: SELECT_GROUP_MODEL, payload: selectedGroupModel })
}

// SELECT MODEL
// DESC     : SAVE SELECTED CAR MODEL ON STATE
// METHOD   : -
export const selectModelSellPrice = (selectedModel) => (dispatch) => {
    dispatch({ type: SELECT_MODEL, payload: selectedModel })
}

// SELECT MODEL YEAR
// DESC     : SAVE SELECTED CAR YEAR MODEL ON STATE
// METHOD   : -
export const selectModelYearSellPrice = (selectedModelYear) => (dispatch) => {
    dispatch({ type: SELECT_MODEL_YEAR, payload: selectedModelYear })
}


// SELECT EXTERIOR COLOR 
// DESC     : SAVE SELECTED EXTERIOR COLOR ON STATE 
// METHOD   : -
export const selectExteriorColorSellPrice = (selectedColor) => (dispatch) => {
    dispatch({ type: SELECT_EXTERIOR_COLOR, payload: selectedColor })
}

// SELECT KILOMETER
// DESC     : SAVE SELECTED KILOMETER ON STATE 
// METHOD   : -
export const selectKilometerSellPrice = (selectedKilometer) => (dispatch) => {
    dispatch({ 
        type: SELECT_KILOMETER, 
        payload: selectedKilometer 
    });
}

// SELECT NOMOR POLIS
// DESC     : SAVE SELECTED NOMOR POLIS ON STATE 
// METHOD   : -
export const selectNomorPolisSellPrice = (selectedNomorPolis) => (dispatch) => {
    dispatch({ type: SELECT_NOMOR_POLIS, payload: selectedNomorPolis })
}

// ========= RESET SELECTED VALUE =============

// RESET MERK
// DESC     : RESET STATE MERK
// METHOD   : -
export const resetMerkSellPrice = () => (dispatch) => {
    dispatch({ type: RESET_MERK })
}

// RESET GROUP MODEL
// DESC     : RESET STATE GROUP MODEL
// METHOD   : -
export const resetGroupModelSellPrice = () => (dispatch) => {
    dispatch({ type: RESET_GROUP_MODEL })
}

// RESET MODEL
// DESC     : RESET STATE MODEL
// METHOD   : -
export const resetModelSellPrice = () => (dispatch) => {
    dispatch({ type: RESET_MODEL })
}

// RESET MODEL YEAR
// DESC     : RESET STATE MODEL YEAR
// METHOD   : -
export const resetModelYearSellPrice = () => (dispatch) => {
    dispatch({ type: RESET_MODEL_YEAR })
}


// RESET EXTERIOR COLOR
// DESC     : RESET STATE EXTERIOR COLOR 
// METHOD   : -
export const resetExteriorColorSellPrice = () => (dispatch) => {
    dispatch({ type: RESET_EXTERIOR_COLOR })
}

// RESET KILOMETER
// DESC     : RESET STATE KILOMETER
// METHOD   : -
export const resetKilometerSellPrice = () => (dispatch) => {
    dispatch({ type: RESET_KILOMETER })
}

// RESET NOMOR POLIS
// DESC     : RESET STATE NOMOR POLIS
// METHOD   : -
export const resetNomorPolisSellPrice = () => (dispatch) => {
    dispatch({ type: RESET_NOMOR_POLIS })
}

