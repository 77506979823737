import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CodeIcon from '@mui/icons-material/Code';
import { neutralColor } from '../../constants/styling/colorPalette';

const SortIcon = ({ order, isOrder }) => {
  if (isOrder) {
    return order === 'asc' ? 
      <KeyboardArrowUpIcon color='primary' fontSize='small' sx={{ pl: 1 }} /> : 
      <KeyboardArrowDownIcon color='error' fontSize='small' sx={{ pl: 1 }} />;
  }
  return <CodeIcon fontSize='small' sx={{ transform: 'rotate(90deg)', color: neutralColor.neutral50, pl: 1 }} />;
};

export default SortIcon; 