// WARNA ELEMEN YANG ADA DI DJUBLI
// SESUAI DENGAN DESIGN SYSTEM YANG ADA DI FIGMA
export const colorPalette = {
    primary: '#00937C',
    clickable: '#2B91CB',
    green: '#00CD15',
    red1: '#D40000',
    red2: '#F97272',
    redText: '#F84141',
    yellow: '#DADE00',
    djubliMarket: '#5B82CF',
    djubliKKB: '#BE63F5',
    djubliCheck: '#F2575D',
    djubliCheck2: '#F2E1E2',
    blueColor: '#173559'
}

export const backgroundColor = '#f9f9f9'

// NEUTRAL COLOR
export const neutralColor = {
    neutral10: '#FFFFFF',
    neutral20: '#F5F5F5',
    neutral30: '#EDEDED',
    neutral40: '#E0E0E0',
    neutral50: '#C2C2C2',
    neutral60: '#9E9E9E',
    neutral70: '#757575',
    neutral80: '#616161',
    neutral90: '#404040',
    neutral100: '#1D1D1D',
}

// WARNA LAINNYA
export const whatsAppColor = '#22A515'