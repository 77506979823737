import React from "react";
import {
  Box,
  Button,
  Chip,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Skeleton,
} from "@mui/material";
import { priceFormat } from "../../constants/djubliKreditConstant";
import { neutralColor } from "../../constants/styling/colorPalette";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const ButtonESSamsat = ({ linkESSamsat, label }) => {
  if (!linkESSamsat) {
    return null;
  }
  return (
    <Button
      to={linkESSamsat}
      target="_blank"
      rel="noopener noreferrer"
      as={Link}
      sx={{
        display: "flex",
        direction: "row",
        alignItems: "center",
        gap: 0.5,
        width: "fit-content",
        textDecoration: "none",
        textTransform: "inherit",
        color: "black",
      }}
    >
      <Typography variant="body2" color="primary">{label}</Typography>
      <OpenInNewIcon sx={{ fontSize: 16, color: "#0F967E" }} />
    </Button>
  );
};

/**
 * CarTaxDetailSection Component
 * Displays detailed information about vehicle tax in a table format including:
 * - Vehicle details (brand, model, license plate)
 * - Tax components (PKB, SWDKLJ, PNBP)
 * - Total tax amount and due date
 *
 * @param {Object} taxDetails - Object containing all tax-related information
 */
const CarTaxDetailSection = ({ taxDetails, isWithTaxLoading }) => {
  const linkESSamsat = taxDetails?.["Link"] || "";
  // Add condition to check if taxDetails is empty
  if (!taxDetails?.Data || Object.keys(taxDetails?.Data).length === 0 || isWithTaxLoading) {
    return (
      <Paper
        elevation={0}
        sx={{
          borderRadius: "10px !important",
          border: "1px solid #D0D6DC",
        }}
      >
        <Box p={2}>
          <Typography variant="h6" mb={2}>Informasi Pajak Kendaraan</Typography>
          {isWithTaxLoading ? (
            <Skeleton variant="rectangular" height={100} />
          ) : (
            <Box
              borderRadius={1}
              border={1}
              borderColor="divider"
              px={2}
              py={4}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bgcolor={neutralColor.neutral30}
            >
              <Typography variant="subtitle1" color="gray" fontStyle="italic">
                Data Pajak Tidak Tersedia
              </Typography>
              <Typography variant="body2" color="gray" fontStyle="italic" display="flex" flexDirection="row" alignItems="center" >
                Silahkan mencoba melalui link disini
                <ButtonESSamsat linkESSamsat={linkESSamsat} label="Kunjungi E-Samsat" />
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          gap={1}
          sx={{
            mt: 2,
            p: 2,
            bgcolor: "#e7f4f2",
            borderEndStartRadius: 10,
            borderEndEndRadius: 10,
          }}
        >
          <Typography color="text.secondary" variant="subtitle2">
            Pajak yang harus dibayar:
          </Typography>
          <Typography variant="body1" color="text.secondary" fontWeight="bold" fontStyle="italic">
            Menyusul setelah inspeksi Kendaraan
          </Typography>
        </Box>
      </Paper>
    );
  }

  const taxDetailsData = Object.fromEntries(
    Object.entries(taxDetails.Data).sort(([keyA], [keyB]) => {
      if (keyA.toLowerCase().includes("overdue")) return -1;
      if (keyB.toLowerCase().includes("overdue")) return 1;
      if (keyA.toLowerCase().includes("total")) return 1;
      if (keyB.toLowerCase().includes("total")) return -1;
      return 0;
    })
  );

  const taxStatus = taxDetailsData.overdue || taxDetailsData.OVERDUE;
  const taxPaid = taxStatus ? taxDetailsData.total || taxDetailsData.TOTAL || 0 : 0  ;

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: "10px !important",
        border: "1px solid #D0D6DC",
      }}
    >
      {/* Header Section */}
      <Box p={2}>
        <Typography variant="h6" mb={2}>
          Informasi Pajak Kendaraan
        </Typography>

        <Typography display="flex" flexDirection={{xs: "column", md: "row"}} alignItems="center" variant="body2">
          Kunjungi E-Samsat untuk melihat detail pajak kendaraan, 
          <ButtonESSamsat linkESSamsat={linkESSamsat} label="Klik Disini" />
        </Typography>

        {/* Tax Details Table */}
        <Box borderRadius={1} border={1} borderColor="divider">
          <TableContainer>
            <Table size="small">
              {/* Table Header */}
              <TableHead>
                <TableRow sx={{ bgcolor: "#f5f5f5" }}>
                  {Object.keys(taxDetailsData).map((key) => (
                    <TableCell
                      key={key}
                      sx={
                        key.toLowerCase().includes("total")
                          ? {
                              position: "sticky",
                              right: 0,
                              bgcolor: "#f5f5f5",
                              zIndex: 1,
                            }
                          : {}
                      }
                    >
                      {key.toLowerCase().includes("overdue") ? "STATUS" : key}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* Table Content */}
              <TableBody>
                <TableRow>
                  {Object.keys(taxDetailsData).map((key) => (
                    <TableCell
                      key={key}
                      sx={{
                        minWidth: "120px",
                        ...(key.toLowerCase().includes("total")
                          ? {
                              position: "sticky",
                              right: 0,
                              bgcolor: "white",
                              fontWeight: "500",
                              zIndex: 1,
                            }
                          : {}),
                      }}
                    >
                      {key.toLowerCase().includes("overdue") ? (
                        <Chip
                          // variant="outlined"
                          label={
                            taxDetailsData[key]
                              ? "Pajak Tidak Aktif"
                              : "Pajak Aktif"
                          }
                          color={taxDetailsData[key] ? "error" : "success"}
                          sx={{
                            bgcolor: taxDetailsData[key]
                              ? "#DA5F5F"
                              : "#47A04C",
                            fontWeight: "bold",
                          }}
                        />
                      ) : key.includes("TANGGAL") ? (
                        new Date(taxDetailsData[key]).toLocaleDateString(
                          "id-ID",
                          {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          }
                        )
                      ) : typeof taxDetailsData[key] === "number" &&
                        !isNaN(taxDetailsData[key]) &&
                        !key.includes("TAHUN") ? (
                        `Rp ${priceFormat(taxDetailsData[key])}`
                      ) : (
                        taxDetailsData[key]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      {/* Total Tax Footer Section */}
      <Box
        display="flex"
        gap={1}
        sx={{
          p: 2,
          bgcolor: "#e7f4f2",
          borderEndStartRadius: 10,
          borderEndEndRadius: 10,
        }}
      >
        <Typography color="text.secondary" variant="subtitle2">
          Pajak yang harus dibayar:
        </Typography>
        <Typography
          variant="body1"
          color={taxStatus && "error.main"}
          fontWeight="bold"
        >
          Rp {priceFormat(taxPaid)}
        </Typography>
      </Box>
    </Paper>
  );
};

export default CarTaxDetailSection;
