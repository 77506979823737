import { useState, useEffect } from "react";

export const useLocationManager = ({ onInputChange }) => {
  // State to store the selected map position (latitude and longitude)
  const [selectedPosition, setSelectedPosition] = useState(null);
  // State to store the current search input value
  const [searchQuery, setSearchQuery] = useState("");
  // State to store location suggestions from the API
  const [suggestions, setSuggestions] = useState([]);
  // State to control the visibility of suggestions dropdown
  const [showSuggestions, setShowSuggestions] = useState(false);
  // State to handle debouncing of search requests
  const [debouncedTimeout, setDebouncedTimeout] = useState(null);

  const INDONESIA_BOUNDS = {
    north: 6,
    south: -11,
    west: 95,
    east: 141
  };

  const isWithinIndonesia = (lat, lng) => {
    return lat >= INDONESIA_BOUNDS.south && 
           lat <= INDONESIA_BOUNDS.north && 
           lng >= INDONESIA_BOUNDS.west && 
           lng <= INDONESIA_BOUNDS.east;
  };

  /**
   * Fetches address details from coordinates using reverse geocoding
   * @param {number} lat - Latitude
   * @param {number} lng - Longitude
   */
  const fetchAddress = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&countrycodes=id`
      );
      const data = await response.json();

      onInputChange({
        target: {
          name: "address",
          value: data.display_name,
        },
      });
      onInputChange({
        target: {
          name: "latitude",
          value: lat,
        },
      });
      onInputChange({
        target: {
          name: "longitude",
          value: lng,
        },
      });
      setSearchQuery(data.display_name);
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  /**
   * Handles changes in the search input field
   * Implements debouncing to prevent too many API calls
   * @param {Event} e - Input change event
   */
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (debouncedTimeout) {
      clearTimeout(debouncedTimeout);
    }

    if (value.trim()) {
      const newTimeout = setTimeout(async () => {
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
              value
            )}&countrycodes=id`
          );
          const data = await response.json();
          setSuggestions(data);
          setShowSuggestions(true);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      }, 500);

      setDebouncedTimeout(newTimeout);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  /**
   * Handles the selection of an address suggestion
   * Updates the position and address input with the selected suggestion
   * @param {Object} suggestion - Selected location suggestion
   */
  const handleSelectSuggestion = (suggestion) => {
    const lat = parseFloat(suggestion.lat);
    const lng = parseFloat(suggestion.lon);
    const newPosition = { lat, lng };
    setSelectedPosition(newPosition);

    onInputChange({
      target: {
        name: "address",
        value: suggestion.display_name,
      },
    });
    onInputChange({
      target: {
        name: "latitude",
        value: lat,
      },
    });
    onInputChange({
      target: {
        name: "longitude",
        value: lng,
      },
    });
    setShowSuggestions(false);
    setSearchQuery(suggestion.display_name);
  };

  /**
   * Handles clicks on the map
   * Updates the position and fetches the address for the clicked location
   * @param {Object} param0 - Object containing latLng array
   */
  const handleMapClick = ({ latLng }) => {
    const [lat, lng] = latLng;
    if (!isWithinIndonesia(lat, lng)) {
      alert('Please select a location within Indonesia');
      return;
    }
    setSelectedPosition({ lat, lng });
    fetchAddress(lat, lng);
  };

  /**
   * Effect hook to get user's current location on component mount
   * Uses browser's geolocation API to fetch coordinates
   */
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setSelectedPosition({ lat, lng });

          fetchAddress(lat, lng);
        },
        (error) => console.log("Error getting location:", error)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Return object containing all necessary state and handlers
  return {
    searchQuery,
    selectedPosition,
    handleSearchChange,
    handleSelectSuggestion,
    handleMapClick,
    suggestions,
    showSuggestions,
  };
};
