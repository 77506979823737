import React from 'react';
import { TextField, Box } from '@mui/material';

/**
 * LocationSearch Component
 * Renders a search input field with autocomplete suggestions for locations
 * 
 * @param {string} searchQuery - Current search input value
 * @param {Array} suggestions - Array of location suggestions from API
 * @param {boolean} showSuggestions - Controls visibility of suggestions dropdown
 * @param {Function} onSearchChange - Handler for search input changes
 * @param {Function} onSelectSuggestion - Handler for suggestion selection
 */
const LocationSearch = ({
  searchQuery,
  suggestions,
  showSuggestions,
  onSearchChange,
  onSelectSuggestion,
}) => (
  // Container with relative positioning for suggestions dropdown
  <Box sx={{ position: 'relative' }}>
    {/* Search input field */}
    <TextField
      fullWidth
      label="Lokasi"
      value={searchQuery}
      onChange={onSearchChange}
    />

    {/* Suggestions dropdown - only shown when there are suggestions and showSuggestions is true */}
    {showSuggestions && suggestions.length > 0 && (
      <Box sx={{
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        boxShadow: 3,
        borderRadius: 1,
        zIndex: 99999,
        maxHeight: '200px',
        overflowY: 'auto',
        width: '100%'
      }}>
        {/* Map through suggestions to create clickable suggestion items */}
        {suggestions.map((suggestion, index) => (
          <Box
            key={index}
            sx={{
              p: 1.5,
              cursor: 'pointer',
              '&:hover': { bgcolor: 'action.hover' },
              borderBottom: index < suggestions.length - 1 ? '1px solid #eee' : 'none'
            }}
            onClick={() => onSelectSuggestion(suggestion)}
          >
            {suggestion.display_name}
          </Box>
        ))}
      </Box>
    )}
  </Box>
);

export default LocationSearch; 