import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
// MATERIAL UI
import { Box, Button, Dialog, Divider, IconButton, InputAdornment, Popover, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
// ICONS
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
// CONSTANTS
import { fontPreset } from '../../constants/styling/fontStyleConstant'
import { backgroundColor, colorPalette, neutralColor } from '../../constants/styling/colorPalette'
import { captureEventGAWithParams, captureEventPixel, captureEventToDB } from '../../constants/capture_event/captureEventConstant'
import { scrollToElem } from '../../constants/utils/scrollActions'
import { priceFormat } from '../../constants/djubliKreditConstant'
import { BARU_CONDITION, BEKAS_CONDITION } from '../../constants/utils/conditionName'
// ACTIONS
import { getSearchCount, listModel, listModelReset, listModelYearByGroupModelId, listModelYearReset, resetGroupModel, resetModel, resetModelYear, resetModelYear2, selectGroupModel, selectModelYear, selectModelYear2 } from '../../actions/carActions'
import { getLiquidationEstimation } from '../../actions/danaTunaiActions'
import { resetKkbEstimation } from '../../actions/promoActions'

const SelectGroupModelMenu = ({ 
    groupModel = [],
    selectedVal,
    label,
    disabled,
    category = '',
    page = '',
    condition = "semua",
    isShowCountResult = true,
    bottonStyleProps = {},
    sellType = ""
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const downSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const location = useLocation()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null)
  const [searchValue, setSearchValue] = useState("")
  const [groupModelOptions, setGroupModelOptions] = useState(groupModel)
  const [openDialog, setOpenDialog] = useState(false)

  const estimationKKBState = useSelector((state) => state.estimationKKBSimulation)
  const selectedDropdown = useSelector((state) => state.selectedDropdown)
  const userLogin = useSelector((state) => state.userLogin)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const buttonStyle = { 
    height: "100%",
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    // padding: downMediumScreen && selectedVal === "" ? '14px 12px' : '6px 12px',
    padding: selectedVal === "" ? '14px 12px' : '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'white',
    borderColor: neutralColor.neutral50,
    color: neutralColor.neutral100,
    display: "flex",
    justifyContent: "space-between",
    '&:hover': {
      backgroundColor: backgroundColor,
      borderColor: colorPalette.primary,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: backgroundColor,
      borderColor: colorPalette.primary,
    },
    '&:focus': { },
    '&:disabled': { 
        backgroundColor: neutralColor.neutral10,
        borderColor: neutralColor.neutral10,
    },
  }

  const nonAdmin = () => {
    if(userLogin.userInfo?.id !== 0) return true
    else return false
  }

//   const handleOpenDialog = () => {
//     scrollToElem({ elemName: "GROUP_MODEL", options: { duration: 500, smooth: true, offset: -76 } })
//     setOpenDialog(true)
//   }
  
  const handleCloseDialog = () => { 
    window.history.back();
    //   navigate(`${location.pathname}${location.search}`, { replace: true })
      // setOpenDialog(false)
  }

  const handleClose = () => { setAnchorEl(null) }

  const handleClick = (event) => { 
    // if(downMediumScreen) handleOpenDialog()
    // if (downMediumScreen) navigate(`${location.pathname}#open-groupModel`)
    if (downMediumScreen) window.location.hash = "#open-groupModel";
    else {
        scrollToElem({ elemName: "GROUP_MODEL", options: { duration: 500, smooth: true, offset: -132 } })
        setAnchorEl(event.currentTarget)
    }
  }

  const handleClearSelectedVal = () => {
    dispatch(resetGroupModel())

    if(category === "Cari Mobil") {
        // UPDATE SEARCH COUNT
        if(condition !== "semua") {
            dispatch(getSearchCount({ 
                brandId: selectedDropdown.brands.id,
                condition: condition === 'bekas' ? BEKAS_CONDITION : BARU_CONDITION,
                sellType
            }))
        } else {
            dispatch(getSearchCount({ brandId: selectedDropdown.brands.id, sellType }))
        }
    }

    if(category === "Dana Tunai") {
        dispatch(getLiquidationEstimation({ brandId: selectedDropdown.brands.id }))
    }

    if(category === "KKB") {
        dispatch(resetKkbEstimation())
    }

    // RESET VALUE JIKA ADA SETELAH GROUP MODEL YANG TERPILIH
    if(selectedVal?.modelYear !== null) {
        // RESET SELECTED VALUE
        dispatch(resetModelYear())
        dispatch(resetModelYear2())
        dispatch(resetModel())
        // RESET LIST VALUE
        dispatch(listModelYearReset())
        dispatch(listModelReset())
    }
  }

  const handleClickWhatsApp = () => {
    if (nonAdmin()) {
        // SAVE TO DB
        captureEventGAWithParams({
          eventName: "Hubungi DjuBli - Group Model Tidak Ada",
          params: { category: category, page: page },
        });
        // SAVE TO DB
        captureEventToDB({
          event: { category: category, eventName: "Hubungi DjuBli - Group Model Tidak Ada", page: page },
        });
        // SAVE TO PIXEL
        captureEventPixel({
          eventName: `Hubungi DjuBli - Group Model Tidak Ada`,
          params: { category: category, page: page },
        });
      }

    window.open(`https://wa.me/62895808993131?text=Hai DjuBli! Saya inign mencari mobil *${selectedDropdown.brands.name} ${searchValue}* pada *${page}*%0A%0A Selengkapnya ada di ${window.location.href}`)
  }

  const selectGroupModelValue = (selectedGroupModel) => {
    // RESET VALUE JIKA ADA SETELAH GROUP MODEL YANG TERPILIH
    if(selectedVal?.modelYear !== null) {
        // RESET SELECTED VALUE
        dispatch(resetModelYear())
        dispatch(resetModelYear2())
        dispatch(resetModel())
        // RESET LIST VALUE
        dispatch(listModelYearReset())
        dispatch(listModelReset())
    }

    if(category === "Dana Tunai") {
        dispatch(getLiquidationEstimation({ groupModelId: selectedGroupModel.id }))
    } 
    
    if(category === "Cari Mobil") {
        if(condition !== "semua") {
            dispatch(
                getSearchCount({ 
                    brandId: selectedDropdown.brands.id,
                    groupModelId: selectedGroupModel.id,
                    condition: condition === 'bekas' ? BEKAS_CONDITION : BARU_CONDITION,
                    sellType
                })
            )
        } else {
            dispatch(getSearchCount({ brandId: selectedDropdown.brands.id, groupModelId: selectedGroupModel.id, sellType }))
        }
    }

    if(category === "KKB") {
        // RESET ESTIMATION VALUE
        if(estimationKKBState.estimation !== null) {
            dispatch(resetKkbEstimation())
        }
    }

    // HIT EVENT GOOGLE ANALYTICS (UNTUK DANA TUNAI)
    if(nonAdmin()) {
        // SAVE TO ANALYTICS
        captureEventGAWithParams({
            eventName: "Select Group Model",
            params: { category: category, page: page }
        })
        // SAVE TO DB
        captureEventToDB({
            event: { category: category, eventName: "Select Group Model", page: page }
        })
        // SAVE TO PIXEL
        captureEventPixel({
            eventName: "Select Group Model",
            params: { category: category, page: page }
        })
    }
    // JIKA VALUE YANG DIPILIH HUBUNGI DJUBLI
    // if (section === 'DanaTunai' && newValue.dropdown_id === 'not_found') {
    //     redirectWhatsApp({ brand: selectedValue.brands.name })
    // }

    // JIKA VALUE YANG DIPILIH GROUP MODEL
    dispatch(selectGroupModel(selectedGroupModel))
    dispatch(listModelYearByGroupModelId(selectedGroupModel.id))

    // SELECT MODEL YEAR VALUE DENGAN SEMUA
    // KHUSUS SECTION CARI MOBIL
    if(category === "Cari Mobil") {
        dispatch(selectModelYear("Semua"))
        dispatch(selectModelYear2("Semua"))

        dispatch(listModel(selectedGroupModel.id))
    }
    
    if(downMediumScreen) handleCloseDialog()
    else handleClose()
  }

  const searchSection = () => (
    <>
        <Box className='groupModel__search'
            sx={{ padding: downMediumScreen ? "1.5rem 1.5rem 1rem" : "1.5rem" }}
        >
            <TextField
                placeholder={"Cari Group Model"}
                value={searchValue}
                onChange={(e) => {
                    setSearchValue(e.target.value)

                    const searchResult = groupModel.filter((groupModelItem) => {
                        if (e.target.value === "") return groupModelItem
                        return groupModelItem.name.toLowerCase().includes(e.target.value.toLowerCase())
                    })
                    setGroupModelOptions(searchResult)
                }}
                size={"regular"}
                variant={"outlined"}
                InputProps={{ 
                    endAdornment: 
                        <InputAdornment
                            position="end"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {}}
                        >
                            <SearchIcon height={18} width={18} />
                        </InputAdornment>
                }}
                fullWidth
            />
        </Box>

        <Box className='root__groupModel' 
            sx={{ 
                display: "flex",
                flexDirection: "column",
                rowGap: "16px",
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: downMediumScreen ? "calc(100vh - 146px - 1.7rem)" : "320px",
                padding: downMediumScreen ? "0 1.5rem 1.5rem" : "0 1.5rem 1.5rem"
            }}
        >
            { searchValue === "" || groupModelOptions.length !== 0 ? 
                <Box className='alphabetical__groupModel'>
                    <Typography variant='body1' sx={{ ...fontPreset.body16Bold, marginBottom: "8px" }}>
                        Pilih Group Model
                    </Typography>
                    <ul style={{ 
                            listStyleType: "none", 
                            margin: 0,
                            padding: 0,
                            columns: downMediumScreen ? "2" : "3",
                            columnGap: "18px"
                        }}
                    >
                        { groupModelOptions.map((groupModel) => (
                            <li key={`groupModel-${groupModel.id}`}
                                onClick={() => selectGroupModelValue(groupModel)}
                                style={{ marginBottom: downMediumScreen ? "12px" : "8px", width: downMediumScreen ? "" : "140px" }}
                            >
                                <Box 
                                    sx={{ 
                                        display: "flex",
                                        justifyContent: "space-between",
                                        // flexDirection: "column",
                                        columnGap: "4px",
                                        alignItems: "flex-start",
                                        transition: "color .1s ease",
                                        "&:hover": { 
                                            cursor: "pointer",
                                            color: colorPalette.primary,
                                        }
                                    }}
                                > 
                                    <Typography
                                        variant='body2'
                                        sx={{ ...fontPreset.body14Reg,
                                            marginBottom: "8px",
                                            color: selectedVal.id === groupModel.id ? colorPalette.primary : "",
                                            fontWeight: selectedVal.id === groupModel.id ? "bold" : "regular" }}
                                    >
                                        {groupModel.name}
                                    </Typography>

                                    { category !== "Dana Tunai" && isShowCountResult  && (
                                        <Typography 
                                            variant='body2'
                                            sx={{ 
                                                ...fontPreset.body14Reg,
                                                marginBottom: "8px",
                                                transition: "color .1s ease",
                                                color: selectedVal.id === groupModel.id ? colorPalette.primary : "",
                                                fontWeight: selectedVal.id === groupModel.id ? "bold" : "regular",
                                                "&:hover": { 
                                                    cursor: "pointer",
                                                    color: colorPalette.primary,
                                                }
                                            }}
                                        >
                                            ({priceFormat(groupModel.countResult ?? 0)})
                                        </Typography>
                                    )}
                                </Box>
                            </li>
                        ))}
                    </ul>
                </Box> :
                NotFoundCallDjuBliElement({ }) }

            { groupModelOptions.length !== 0 && 
                <>
                    <Divider />
                    { NotFoundCallDjuBliElement({ title: "Tidak Menemukan Pilihan?" }) }
                </>
            }
        </Box>
    </>
  )

  const NotFoundCallDjuBliElement = ({ title = "Hasil pencarian tidak ditemukan.", body = "Coba hubungi DjuBli" }) => {
    return (
      <Box className="not__found__result"
        sx={{ 
          padding: "16px",
          display: "flex",
          alignItems: "center",
          rowGap: "12px",
          flexDirection: "column"
        }}
      >
        <Box>
          <Typography
            variant="body1"
            sx={downSmallScreen ? fontPreset.body14Bold : fontPreset.body16Bold}
            align="center"
          >
            { title }
          </Typography>
          <Typography
            variant="body1"
            sx={downSmallScreen ? fontPreset.body14Reg : fontPreset.body16Reg}
            align="center"
          >
            { body }
          </Typography>
        </Box>

        <Button
          variant="contained"
          onClick={handleClickWhatsApp}
          endIcon={<WhatsAppIcon />}
          size={downSmallScreen ? "small" : "regular"}
        >
          Hubungi DjuBli
        </Button>
      </Box>
    )
  }

  useEffect(() => {
    setGroupModelOptions(groupModel)
  }, [groupModel])

//   useEffect(() => {
//     if(downMediumScreen && !disabled) {
//       if(location.hash === "#open-groupModel") handleOpenDialog()
//       else setOpenDialog(false)
//     }
//   }, [location.hash])

    useEffect(() => {
        const onHashChange = () => setOpenDialog(window.location.hash === "#open-groupModel");
        window.addEventListener("hashchange", onHashChange);
        return () => window.removeEventListener("hashchange", onHashChange);
    }, [])

  return (
    <div style={{ height: "100%", display: "flex", columnGap: "8px", alignItems: "center" }}>
        <Button 
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            fullWidth
            disableRipple
            sx={{...buttonStyle, ...bottonStyleProps}}
            endIcon={
                <div className='clear__button'
                    style={{ display: "flex", alignItems: "center", columnGap: "2px", padding: "0", margin: "0" }}
                >
                    { selectedVal !== "" &&
                        <Box sx={{ 
                                padding: "0",
                                margin: "0",
                                borderRadius: "50%",
                                width: "25px",
                                height: "25px",
                                transition: "background-color .1s",
                                "&:hover": { bgcolor: neutralColor.neutral30 }
                            }}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleClearSelectedVal()
                            }}
                        >
                            <CloseIcon fontSize="small" sx={{ color: neutralColor.neutral90 }} />
                        </Box>
                    }
                    { open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon/> }
                </div>
            }

            disabled={disabled}
        >
            <Box>
                <Typography 
                    variant='body1'
                    sx={selectedVal !== "" ? { ...fontPreset.body12Reg } : { ...fontPreset.body16Reg }}
                    align="left"
                >
                    { label }
                </Typography>
                <Typography 
                    variant='body2'
                    sx={downMediumScreen ? { ...fontPreset.body16Bold } : { ...fontPreset.body18Bold }}
                    align="left"
                >
                    { selectedVal !== "" ? selectedVal.name : "" }
                </Typography>
            </Box>
        </Button>

        { downMediumScreen ? 
            <Dialog
                fullScreen
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <Box className='header__dialog'
                    sx={{ display: "flex", padding: "8px 16px", alignItems: "center" }}
                >
                    <Typography
                        variant='body1'
                        sx={{ ...fontPreset.body16Bold, flexGrow: 1 }}
                        align="center"
                    >
                        Group Model
                    </Typography>

                    <IconButton
                        onClick={handleCloseDialog}
                        size="small"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />

                <Box className='search__section'>
                    { searchSection() }
                </Box>
            </Dialog> :
          // DESKTOP SCREEN
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box className='root__search' 
                    sx={{ maxWidth: "640px" }}
                >
                    { searchSection() }
                </Box>
            </Popover>
        }

    </div>
  )
}

SelectGroupModelMenu.propTypes = {
    groupModel: PropTypes.array,
    selectedVal: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    category: PropTypes.string,
    page: PropTypes.string,
}

export default SelectGroupModelMenu