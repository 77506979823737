import React from "react";
import { Paper, Typography, Box,
  Table, 
  TableHead, 
  TableRow, 
  TableCell, } from "@mui/material";
import { priceFormat } from "../../../constants/djubliKreditConstant";
import {
  PenaltySection,
  PenaltyGridContainer,
  PenaltyInfoItem,
  PenaltySectionTitle,
} from "./PenaltyDetailSectionComponents";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import { MarginPopover } from "./PopoverDetails/MarginPopover";
// import { PenaltyKmPopover } from "./PopoverDetails/PenaltyKmPopover";
import MarketPriceTable from "../MarketPriceTable";
/**
 * PenaltyDetailSection Component
 * Displays detailed information about vehicle penalties including:
 * - Color penalties
 * - Kilometer penalties
 * - Yearly base penalties
 *
 * @param {Object} salePriceData - Contains sale price data
 * @param {boolean} isLoading - Indicates if the data is loading
 * @param {Function} handleOpenMarketPriceModal - Function to open market price modal
 */
const PenaltyDetailSection = ({
  scrapedDate,
  salePriceData,
  isLoading,
  handleOpenMarketPriceModal,
}) => {
  // Market Price section data
  const marketPriceData = [
    { label: "Total Data", value: salePriceData?.data?.length || 0 },
    {
      label: "Harga Tertinggi",
      rightIcon: <ArrowDropUpIcon color="primary" />,
      value: `Rp ${priceFormat(salePriceData?.highest_price || 0)}`,
    },
    {
      label: "Harga Terendah",
      rightIcon: <ArrowDropDownIcon color="error" />,
      value: `Rp ${priceFormat(salePriceData?.lowest_price || 0)}`,
    },
    {
      label: "Harga Rata-rata",
      value: `Rp ${priceFormat(salePriceData?.average_price || 0)}`,
    },
    {
      label: "Kilometer Rata-rata",
      value: `${priceFormat(salePriceData?.average_kilometer || 0)} km`,
    }
  ];

  // const taxDetailsData = salePriceData?.Tax?.Data
  //   ? Object.fromEntries(
  //       Object.entries(salePriceData?.Tax?.Data).sort(([keyA], [keyB]) => {
  //         if (keyA.toLowerCase().includes("overdue")) return -1;
  //         if (keyB.toLowerCase().includes("overdue")) return 1;
  //         if (keyA.toLowerCase().includes("total")) return 1;
  //         if (keyB.toLowerCase().includes("total")) return -1;
  //         return 0;
  //       })
  //     )
  //   : {};

  // const taxPaid = taxDetailsData.total || taxDetailsData.TOTAL;
  // const taxStatus = taxDetailsData.overdue || taxDetailsData.OVERDUE;
  // const kmPenaltyPrice = Math.abs(salePriceData?.km_penalty_price || 0);
  // // const carAgePenaltyPrice = Math.abs(salePriceData?.car_age_penalty_price || 0);
  // const totalPenaltyPrice = Math.abs(salePriceData?.total_penalty_price || 0);
  // const marginPenaltyPrice = Math.abs(salePriceData?.margin_price || 0);

  return (
    <Paper
      sx={{
        borderRadius: "10px !important",
        boxShadow: "none",
        border: "1px solid #D0D6DC",
        p: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Detail Perhitungan
      </Typography>

      {/* Market Price Section */}
      <PenaltySection hasBorder>
        <PenaltySectionTitle title="Perhitungan Harga Pasar" />
        <Box py={2}>
          <MarketPriceTable 
            setViewMore={handleOpenMarketPriceModal}
            isPagination={false}
            maxRows={5}
            tableData={salePriceData?.data}
            salePriceData={salePriceData}
            tableStyleProps={{
              minHeight: 200,
            }}
          />
        </Box>
        {/* <PenaltyGridContainer gridTemplateColumns="repeat(5, 1fr)">
          {marketPriceData.map((item, index) => (
            <PenaltyInfoItem key={index} {...item} />
          ))}
        </PenaltyGridContainer> */}

        <Typography variant="body2" mt={2} ml={2}>
          Catatan:
        </Typography>
        <ul style={{ paddingLeft: 20, marginTop: 5, marginLeft: 15 }}>
          <li>
            <Box display="flex" flexDirection={{ xs: "column", md: "row" }} alignItems={{ md:"center" }}>
              <Typography variant="body2" color="text.secondary">
                Perhitungan harga pasar didapatkan dari data{" "}
                <strong>Harga Cash</strong> dari data harga pasar.
              </Typography>
            </Box>
          </li>
          <li>
            <Typography variant="body2" color="text.secondary">*Data harga pasar diambil pada tanggal <strong>{scrapedDate}</strong></Typography>
          </li>
        </ul>
      </PenaltySection>

      {/* Kilometer Penalty Section */}
      {/* <PenaltySection hasBorder>
        <PenaltySectionTitle title="Penalti Kilometer" />
        <Grid container columnSpacing={2} sx={{ px: 1 }}>
          <Grid item xs={12} md={6}>
            <PenaltyInfoItem>
              <Typography variant="subtitle2" fontWeight="500">
                Catatan:
              </Typography>
              <ul style={{ paddingLeft: 20, marginTop: 5 }}>
                <li>
                  <Typography variant="body2" color="text.secondary">
                  Penalti kilometer dihitung berdasarkan perbedaan kilometer dari rata-rata kilometer di pasar.
                  <PenaltyKmPopover />
                  </Typography>
                </li>
              </ul>
            </PenaltyInfoItem>
          </Grid>

          <Grid item xs={12} md={6} sx={{ height: "inherit" }}>
            <PenaltyInfoItem>
              <Typography variant="subtitle2" fontWeight="500">
                Hasil Perhitungan (Penalti Kilometer):
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                p={1}
                borderBottom="1px solid #E8E8E8"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={3}
                  alignItems="center"
                >
                  <Typography variant="body1">
                    <span>Penalti</span> =
                  </Typography>
                  <Typography variant="body1" fontStyle="italic">
                  {salePriceData?.km_penalty_price < 0 ? `- Rp ${priceFormat(kmPenaltyPrice)}` : `+ Rp ${priceFormat(kmPenaltyPrice)}`}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap={1} p={1} mt={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={3}
                  alignItems="center"
                >
                  <Typography variant="body1">Kategori =</Typography>
                  <Typography variant="body1" fontStyle="italic">
                    {salePriceData?.usage}
                  </Typography>
                </Box>
              </Box>
            </PenaltyInfoItem>
          </Grid>
        </Grid>
      </PenaltySection> */}

      {/* Margin Penalty Section */}
      {/* <PenaltySection hasBorder>
        <PenaltySectionTitle title="Penalti Market Movement" />
        <Grid container columnSpacing={2} sx={{ px: 1 }}>
          <Grid item xs={12} md={6}>
            <PenaltyInfoItem>
              <Typography variant="subtitle2" fontWeight="500">
                Catatan:
              </Typography>
              <ul style={{ paddingLeft: 20, marginTop: 5 }}>
                <li>
                  <Typography variant="body2" color="text.secondary">
                   Penalti market movement dihitung berdasarkan jumlah data yang tersedia di marketplace. Semakin sedikit data yang tersedia, semakin besar persentase yang diterapkan (maksimal 20%). Sebaliknya, semakin banyak data yang tersedia, semakin kecil persentase yang diterapkan (minimal 10%).
                  <MarginPopover />
                  </Typography>
                </li>
              </ul>
            </PenaltyInfoItem>
          </Grid>

          <Grid item xs={12} md={6} sx={{ height: "inherit" }}>
            <PenaltyInfoItem>
              <Typography variant="subtitle2" fontWeight="500">
                Hasil Perhitungan (Penalti Market Movement):
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                p={1}
                borderBottom="1px solid #E8E8E8"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={3}
                  alignItems="center"
                >
                  <Typography variant="body1">
                    <span>Penalti</span> =
                  </Typography>
                  <Typography variant="body1" fontStyle="italic">
                  {salePriceData?.margin_price < 0 ? `- Rp ${priceFormat(marginPenaltyPrice)}` : `+ Rp ${priceFormat(marginPenaltyPrice)}`}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap={1} p={1} mt={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  gap={3}
                  alignItems="center"
                >
                  <Typography variant="body1">Kategori =</Typography>
                  <Typography variant="body1" fontStyle="italic">
                    {salePriceData?.margin_category}
                  </Typography>
                </Box>
              </Box>
            </PenaltyInfoItem>
          </Grid>
        </Grid>
      </PenaltySection> */}

      {/* Harga Rekomendasi Section */}
      {/* <PenaltySection>
        <PenaltySectionTitle title="Harga Rekomendasi" />
        <Grid container columnSpacing={2} sx={{ px: 1 }}>
          <Grid item xs={12} md={6}>
            <PenaltyInfoItem>
              <Typography variant="subtitle2" fontWeight="500">
                Catatan:
              </Typography>
              <ul style={{ paddingLeft: 20, marginTop: 5 }}>
                <li>
                  <Typography variant="body2" color="text.secondary">
                    Harga rekomendasi dihitung dengan mengurangi total penalti dan pajak kendaraan dari rata-rata harga pasar.
                  </Typography> 
                </li>
              </ul>
            </PenaltyInfoItem>
          </Grid>

          <Grid item xs={12} md={6} sx={{ height: "inherit" }}>
            <PenaltyInfoItem>
              <Typography variant="subtitle2" fontWeight="500">
                Hasil Perhitungan (Harga Rekomendasi):
              </Typography>
              <Box display="flex" flexDirection="column" gap={1} p={1}>
                <Box display="flex" flexDirection="row" gap={1}>
                  <Typography variant="body1" pt={2}>
                    Harga Rekomendasi
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      p: 2,
                      borderRadius: "10px",
                      color: "#667085",
                    }}
                  >
                  <Typography variant="body1" fontStyle="italic">= Rata-rata harga pasar - Total Penalti - Pajak Kendaraan
                  </Typography>
                    <Typography variant="body1" fontStyle="italic">
                      = Rp {priceFormat(salePriceData?.average_price)}{" "}
                      - Rp {priceFormat(totalPenaltyPrice)}{" "}
                      - Rp {priceFormat(taxStatus ? taxPaid : 0)}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontStyle="italic"
                      fontWeight="500"
                    >
                      = Rp {priceFormat(salePriceData?.recommended_price_1)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </PenaltyInfoItem>
          </Grid>
        </Grid>
      </PenaltySection> */}
    </Paper>
  );
};

export default PenaltyDetailSection;
