import React from 'react';
import { 
  Box, 
  Typography, 
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper
} from '@mui/material';
import { priceFormat } from '../../constants/djubliKreditConstant';
import CloseIcon from '@mui/icons-material/Close';
import { neutralColor } from '../../constants/styling/colorPalette';
import MarketPriceTable from './MarketPriceTable';




/**
 * MarketPriceSection Component
 * Displays a modal dialog showing market price data for vehicles with:
 * - Vehicle details (brand, model, variant, year)
 * - Sortable table of market listings
 * - Pagination
 * - Average market price
 * 
 * @param {Object} carDetails - Contains car details
 * @param {number} averagePrice - Average price data
 * @param {Array} tableData - Array of market listings
 * @param {boolean} open - Controls dialog visibility
 * @param {Function} onClose - Handler for closing the dialog
 * @param {string} scrapedDate - Scraped date data
 */
const MarketPriceSection = ({ carDetails, averagePrice = 0, scrapedDate, salePriceData, tableData, open, onClose }) => {

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      {/* Dialog Header */}
      <DialogTitle sx={{ pb: 1 }}>
        Harga Pasar
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        {/* Vehicle Details Section */}
        <Box p={2} pt={0}>
          <Box sx={{  gap: 1, mb: 2 }}>
            <Paper 
              elevation={0} 
              sx={{ 
                  flex: 1, 
                  p: 1, 
                  pl: 2,
                  border: `1px solid ${neutralColor.neutral30}`,
                  bgcolor: neutralColor.neutral20
                }}
            >
              <Typography variant="h5" fontWeight="bold" >
                {`${carDetails.merk} ${carDetails.groupModel} ${carDetails.modelYear}`}
              </Typography>
              <Typography variant="subtitle1" >
                {carDetails.model}
              </Typography>
            </Paper>
          </Box>

          {/* Replace table with new component */}
          <MarketPriceTable 
            tableData={tableData}
            salePriceData={salePriceData}
          />
          <Box display="flex" flexDirection="row" >
            <Typography variant="caption" fontStyle="italic" pt={2}>* Data harga pasar diambil pada tanggal <strong>{scrapedDate}</strong></Typography>
          </Box>
        </Box>

        {/* Average Price Footer */}
        <Box 
          sx={{
            p: 2,
            bgcolor: '#e7f4f2',
            borderEndStartRadius: 10,
            borderEndEndRadius: 10,
          }}
        >
          <Typography>
            Harga rata-rata pasar: <strong>Rp {priceFormat(averagePrice)}</strong>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MarketPriceSection;
