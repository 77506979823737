import axios from "axios"
import { 
    GET_ALL_BANK_FAIL,
    GET_ALL_BANK_REQUEST,
    GET_ALL_BANK_SUCCESS,
    GET_ESTIMATION_SIM_FAIL,
    GET_ESTIMATION_SIM_REQUEST,
    GET_ESTIMATION_SIM_RESET,
    GET_ESTIMATION_SIM_SUCCESS,
    GET_STATUS_SLIK_FAIL,
    GET_STATUS_SLIK_REQUEST,
    GET_STATUS_SLIK_SUCCESS,
    GET_VARIANT_FOREIGN_FAIL,
    GET_VARIANT_FOREIGN_REQUEST,
    GET_VARIANT_FOREIGN_RESET,
    GET_VARIANT_FOREIGN_SUCCESS,
    GET_VARIANT_FOREIGN_WEB_FAIL,
    GET_VARIANT_FOREIGN_WEB_REQUEST,
    GET_VARIANT_FOREIGN_WEB_SUCCESS,
    POST_CEK_SLIK_FAIL,
    POST_CEK_SLIK_REQUEST,
    POST_CEK_SLIK_RESET,
    POST_CEK_SLIK_SUCCESS,
    POST_EVENT_FAIL,
    POST_EVENT_REQUEST,
    POST_EVENT_SUCCESS,
    POST_SIMULATION_FAIL,
    POST_SIMULATION_REQUEST,
    POST_SIMULATION_SUCCESS,
    SUBMIT_BPKB_FAIL,
    SUBMIT_BPKB_REQUEST,
    SUBMIT_BPKB_RESET,
    SUBMIT_BPKB_SUCCESS,
    UPDATE_VARIANT_FOREIGN_SECTION,
    UPLOAD_DOKUMEN_BPKB_FAIL,
    UPLOAD_DOKUMEN_BPKB_REQUEST,
    UPLOAD_DOKUMEN_BPKB_SUCCESS,
    VARIANT_FOREIGN_STATUS_RESET
} from "../constants/danaTunaiConstant"

// GET ALL BANK LIST
// DESC     : GET ALL BANK LIST FROM DB
// METHOD   : GET
export const getAllBankList = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_BANK_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/bank`)
        
        dispatch({ type: GET_ALL_BANK_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_ALL_BANK_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// GET VARIANT BY FOREIGN ID WEB
// DESC     : GET MRP, INTERESET CAR BY TOKEN
// METHOD   : GET
export const getVariantByForeignIdWeb = ({ token }) => async (dispatch) => {
    try {
        dispatch({ type: GET_VARIANT_FOREIGN_WEB_REQUEST })

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        }

        const res = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api-admin/leasing/variantByForeignWeb`, config)
        const { data } = res

        dispatch({ type: GET_VARIANT_FOREIGN_WEB_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_VARIANT_FOREIGN_WEB_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// GET VARIANT BY FOREIGN ID
// DESC     : GET MRP, INTERESET CAR BY MODEL YEAR ID, LEASING ID
// METHOD   : GET
export const getVariantByForeignId = ({ modelYearId, leasingId = 1 }) => async (dispatch) => {
    try {
        dispatch({ type: GET_VARIANT_FOREIGN_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api-admin/leasing/variantByForeign?modelYearId=${modelYearId}&leasingId=${leasingId}`)

        dispatch({ type: GET_VARIANT_FOREIGN_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_VARIANT_FOREIGN_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// SUBMIT CEK PROFIL DANA TUNAI
// DESC     : SUBMIT CEK PROFIL DANA TUNAI ON GADAI BPKB PAGE
// METHOD   : POST
export const submitCekProfilDanaTunai = ({ 
    name,
    phoneNumber,
    carBrand,
    groupModel,
    yearCreated,
    licensePlate = '',
    BPKBOwner,
    nominal,
    time,
    images = []
}) => async (dispatch) => {
    try {
        dispatch({ type: SUBMIT_BPKB_REQUEST })

        const config = {
            headers: { 'Content-Type': 'multipart/form-data' }
        }

        const formData = new FormData()
        formData.append('name', name)
        formData.append('phoneNumber', phoneNumber)
        formData.append('carBrand', carBrand)
        formData.append('groupModel', groupModel)
        formData.append('yearCreated', yearCreated)
        formData.append('licensePlate', licensePlate)
        formData.append('BPKBOwner', BPKBOwner)
        formData.append('nominal', nominal)
        formData.append('time', time)
        if(images.length !== 0) {
            images.forEach((val) => { formData.append('images', val) })
        }
        
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_PRODUCTION}/api/users/BPKBDisbursement`,
            formData,
            config
        )

        dispatch({ type: SUBMIT_BPKB_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: SUBMIT_BPKB_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// SUBMIT CEK PROFIL
// DESC     : SUBMIT CEK SLIK FORM ON GADAI BPKB PAGE
// METHOD   : POST
export const submitCekProfil = ({ name, bankAccountNumber, bankAccountName, bank, images = [] }) => async (dispatch, getState) => {
    try {
        dispatch({ type: POST_CEK_SLIK_REQUEST })

        const { userLogin: { userInfo } } = getState()

        const formData = new FormData()
        formData.append('name', name)
        if(typeof bank !== "undefined" && bank !== null) {
            formData.append('bankId', bank.id)
            formData.append('bankAccountName', bankAccountName)
            formData.append('bankAccountNumber', bankAccountNumber)
        }
        if(images.length !== 0) {
            images.forEach((val) => { formData.append('images', val) })
        }
        
        const config = {
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': userInfo.token
            }
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_PRODUCTION}/api/users/BPKBDisbursement/slik`,
            formData,
            config
        )

        dispatch({ type: POST_CEK_SLIK_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: POST_CEK_SLIK_FAIL,
            payload: error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message
        })
    }
}

// UPLOAD DOKUMEN DANA TUNAI
// DESC     : UPLOAD DOKUMEN DANA TUNAI
// METHOD   : POST
export const uploadDokumenDanaTunai = ({ bpkbId, photos }) => async (dispatch) => {
    try {
        dispatch({ type: UPLOAD_DOKUMEN_BPKB_REQUEST })

        const config = {
            headers: { 'Content-Type': 'multipart/form-data' }
        }

        const formData = new FormData()
        formData.append('bpkbId', bpkbId)
        photos.forEach((val) => { formData.append('images', val) })

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_PRODUCTION}/api/users/BPKBDisbursement`,
            formData,
            config
        )

        dispatch({ type: UPLOAD_DOKUMEN_BPKB_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: UPLOAD_DOKUMEN_BPKB_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// ======== RESET KE STATE AWAL =========

// RESET VARIANT BY FOREIGN ID
// DESC     : RESET VARIANT BY FOREIGN ID ON STATE
// METHOD   : -
export const resetVariantByForeignId = () => (dispatch) => {
    dispatch({ type: GET_VARIANT_FOREIGN_RESET })
}

// RESET SUBMIT DANA TUNAI
// DESC     : RESET SUBMIT DANA TUNAI FORM ON STATE
// METHOD   : -
export const resetSubmitDanaTunai = () => (dispatch) => {
    dispatch({ type: SUBMIT_BPKB_RESET })
}

// RESET SUBMIT CEK SLIK
// DESC     : RESET SUBMIT CEK SLIK ON STATE
// METHOD   : -
export const resetSubmitCekSlik = () => (dispatch) => {
    dispatch({ type: POST_CEK_SLIK_RESET })
}

// RESET DANA TUNAI STATUS
// DESC     : RESET DANA TUNAI STATUS ON STATE
//            RESET STATUS SUKSES -> FALSE
// METHOD   : -
export const resetDanaTunaiStatus = () => (dispatch) => {
    dispatch({ type: VARIANT_FOREIGN_STATUS_RESET })
}

// UPDATE SECTION VARIANT BY FOREIGN ID
// DESC     : UPDATE SECTION VARIANT BY FOREIGN ID ON STATE
// METHOD   : -
export const updateSectionVariantByForeignId = ({ section }) => (dispatch) => {
    dispatch({ type: UPDATE_VARIANT_FOREIGN_SECTION, payload: section })
}

// POST EVENT
// DESC     : SUBMIT EVENT'S USER ON DB
// METHOD   : POST
export const postEvent = ({ event }) => async (dispatch) => {
    try {
        dispatch({ type: POST_EVENT_REQUEST })

        const config = { headers: { 'Content-Type': 'application/json' } }
        
        const { data } = await axios.post(
            `${process.env.REACT_APP_API_PRODUCTION}/api/event/postEvent`,
            { eventName: event },
            config
        )

        dispatch({ type: POST_EVENT_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: POST_EVENT_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// GET SLIK STATUS
// DESC     : GET SLIK STATUS BY USER
// METHOD   : GET
export const getSlikStatus = () => async (dispatch, getState) => {
    try {
        dispatch({ type: GET_STATUS_SLIK_REQUEST })

        const { userLogin: { userInfo } } = getState()
        
        const config = {
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': userInfo.token
            }
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_PRODUCTION}/api/users/BPKBDisbursement/slik`,
            config
        )

        dispatch({ type: GET_STATUS_SLIK_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_STATUS_SLIK_FAIL,
            payload: error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message
        })
    }
}

// POST SIMULATION
// DESC     : SAVE SIMULATION DANA TUNAI ON DB
// METHOD   : POST
export const postSimulation = ({ 
    brandId,
    groupModelId,
    modelId,
    modelYearId,
    nominal,
    installmentType,
    insuranceType,
    bpkbOwnerShip
}) => async (dispatch, getState) => {
    try {
        dispatch({ type: POST_SIMULATION_REQUEST })

        const { userLogin: { userInfo } } = getState()
        
        const config = {
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': userInfo.token
            }
        }

        const formData = new FormData()
        formData.append('brandId', brandId)
        formData.append('groupModelId', groupModelId)
        formData.append('modelId', modelId)
        formData.append('modelYearId', modelYearId)
        formData.append('nominal', nominal)
        formData.append('installmentType', installmentType)
        formData.append('insuranceType', insuranceType)
        formData.append('bpkbOwnerShip', bpkbOwnerShip)

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_PRODUCTION}/api/users/BPKBDisbursement/saveSimulation`,
            formData,
            config
        )

        dispatch({ type: POST_SIMULATION_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: POST_SIMULATION_FAIL,
            payload: error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message
        })
    }
}

// GET LIQUIDATION ESTIMATION
// DESC     : GET LIQUIDATION ESTIMATION ON DANA TUNAI PAGE
// METHOD   : GET
export const getLiquidationEstimation = ({ 
    brandId = null,
    groupModelId = null,
    modelYearId = null,
    modelId = null
}) => async (dispatch) => {
    try {
        dispatch({ type: GET_ESTIMATION_SIM_REQUEST })
        
        const printType = () => {
            if(brandId !== null){ 
                return 'brand'
            }
            if(groupModelId !== null) { 
                return 'groupModel' 
            } 
            if(modelYearId !== null){ 
                return 'years'
            } 
            if (modelId !== null) {
                return 'models'
            }
        }

        const config = {
            headers: { 
                'Content-Type': 'application/json',
            }
        }

        const printParameters = () => {
            if(brandId !== null){ 
                return ''
            }
            if(groupModelId !== null) {
                return ''
            }
            if(modelYearId !== null) {
                return `&groupModelId=${modelYearId?.groupModelId ?? ''}&years=${modelYearId?.year ?? ''}`
            } 
            if(modelId !== null) {
                return `&groupModelId=${modelId?.groupModelId ?? ''}&years=${modelId?.year ?? ''}`
            }
        }

        const { data } = await axios.get(
            `${process.env.REACT_APP_API_PRODUCTION}/api/disbursement/${modelId?.id ?? modelYearId?.groupModelId ?? groupModelId ?? brandId}?type=${printType()}${printParameters()}`,
            config
        )

        dispatch({ type: GET_ESTIMATION_SIM_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_ESTIMATION_SIM_FAIL,
            payload: error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message
        })
    }
}

// RESET LIQUIDATION ESTIMATION
// DESC     : RESET LIQUIDATION ESTIMATION ON STATE
// METHOD   : -
export const resetLiquidationEstimation = () => (dispatch) => {
    dispatch({ type: GET_ESTIMATION_SIM_RESET })
}