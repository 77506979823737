import React from "react";
import { useLocation } from "react-router-dom";
import {
  Alert,
  Container,
  Typography,
  Grid,
  Box,
  Button,
  Snackbar,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import axios from "axios";
import { styled, keyframes } from "@mui/material/styles";

// Component imports
import CarDetailSection from "./CarDetailSection";
import PenaltyDetailSection from "./PenaltyDetail/PenaltyDetailSection";
import MarketPriceSection from "./MarketPriceSection";
import CarTaxDetailSection from "./CarTaxDetailSection";
import PriceAnalysisSection from "./PriceAnalysisSection";
import CarInspectionModal from "./InspectionModal/CarInspectionModal";

/**
 * ResultSellPrice Component
 * Displays comprehensive car pricing analysis including market prices, penalties, and tax details
 */
const ResultSellPrice = () => {
  // URL and Navigation hooks
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isSalePriceLoading, setSalePriceLoading] = React.useState(true);
  const [isWithTaxLoading, setWithTaxLoading] = React.useState(false);
  const [salePriceData, setSalePriceData] = React.useState({});

  // Modal state management
  const [openModal, setOpenModal] = React.useState(false);
  const [openMarketPriceModal, setOpenMarketPriceModal] = React.useState(false);

  // Form state management
  const [formData, setFormData] = React.useState({
    name: "",
    phone: "",
    address: "",
    latitude: null,
    longitude: null,
  });

  // Add new state for error
  const [error, setError] = React.useState(null);

  // Add to imports at the top
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  /**
   * Extract and parse URL query parameters
   */
  const carDetails = {
    merk: queryParams.get("merk"),
    groupModel: queryParams.get("groupModel"),
    modelYear: queryParams.get("modelYear"),
    model: queryParams.get("model"),
    kilometer: parseInt(queryParams.get("kilometer")),
    color: queryParams.get("color"),
    colorHex: queryParams.get("colorHex"),
    nopol: queryParams.get("nopol"),
    pictureUrl: queryParams.get("pictureUrl"),
    mobilImage: "/images/ilustrasi-mobil.png",
  };

  // Add pulsing animation keyframes
  const pulseAnimation = keyframes`
    0% {
      box-shadow: 0 0 0 0 rgba(255, 43, 10, 0.5);
      transform: scale(1.01);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(255, 43, 10, 0);
      transform: scale(1);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 43, 10, 0);
      transform: scale(1);
    }
  `;

  // Create styled button component with animation
  const AnimatedButton = styled(Button)`
    animation: ${pulseAnimation} 1.5s infinite;
    transition: all .1s ease;
    &:hover {
      transform: scale(1.02);
      animation: none;
    }
  `;

  /**
   * Modal handlers
   */
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    // Reset form data to initial state
    setFormData({
      name: "",
      phone: "",
      address: "",
      latitude: null,
      longitude: null,
    });
  };
  const handleOpenMarketPriceModal = () => setOpenMarketPriceModal(true);
  const handleCloseMarketPriceModal = () => setOpenMarketPriceModal(false);

  /**
   * Form handlers
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleSubmit = async () => {
    try {
      const details = { ...salePriceData };

      const payload = {
        name: formData.name,
        phone: formData.phone,
        address: formData.address,
        Latitude: formData.latitude || null,
        Longitude: formData.longitude || null,
        Brand: carDetails.merk,
        GroupModel: carDetails.groupModel,
        Model: carDetails.model,
        Year: carDetails.modelYear,
        Kilometer: carDetails.kilometer,
        PlateNumber: carDetails.nopol,
        RecommendedPrice: salePriceData.recommended_price_1,
        Details: details,
      };

      const response = await fetch(
        `${process.env.REACT_APP_PYTHON_API_PRODUCTION}/inspection-submission`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Submission successful:", result);

      // Close modal only if submission was successful
      handleCloseModal();

      // Replace toast.success with Snackbar
      setSnackbar({
        open: true,
        message: "Pengajuan inspeksi berhasil dikirim!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setSnackbar({
        open: true,
        message: "Gagal mengirim pengajuan. Silakan coba lagi.",
        severity: "error",
      });
    }
  };

  const fetchAppraisalDataFromApi = async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PYTHON_API_PRODUCTION}/appraise`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: false,
        }
      );
      const data = response.data;
      setSalePriceData(data);
      setSalePriceLoading(false);
      setWithTaxLoading(false);
      setError(null);
    } catch (error) {
      setSalePriceLoading(false);
      setWithTaxLoading(false);
      setError("Terjadi kesalahan saat mengambil data. Silakan coba lagi nanti.");
    }
  };
  const fetchAppraisalDataFromApiWithSkipTax = async (payload) => {

    try {
      // Skip tax if plate number contains "B"
    const response = await axios.post(
      `${process.env.REACT_APP_PYTHON_API_PRODUCTION}/appraise`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        withCredentials: false,
      }
    );
    const data = response.data;
    setSalePriceData(data);
    setSalePriceLoading(false);
    setError(null);
    } catch (error) {
      setSalePriceLoading(false);
      setError("Terjadi kesalahan saat mengambil data. Silakan coba lagi nanti.");
    }
  };

  const scrapedDate = salePriceData?.["Scraped Date"] ? new Date(salePriceData?.["Scraped Date"]).toLocaleDateString(
    "id-ID",
    {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }
  ) : '';

  React.useEffect(() => {
    const fetchAppraisalData = async () => {
      const payload = {
        merk: carDetails.merk,
        group_model: carDetails.groupModel,
        model: carDetails.model,
        tahun: carDetails.modelYear,
        kilometer: carDetails.kilometer,
        plate_kd: carDetails.nopol?.split(" ")[0] || "",
        plate_number: carDetails.nopol?.split(" ")[1] || "",
        plate_seri: carDetails.nopol?.split(" ")[2] || "",
        color: carDetails.color,
      };
      // Skip tax if plate number contains "B"
      if (payload.plate_kd.includes("B")) {
        setWithTaxLoading(true);

        await fetchAppraisalDataFromApiWithSkipTax({...payload, skip_tax: true});
        await fetchAppraisalDataFromApi(payload);
      }else{
        await fetchAppraisalDataFromApi(payload);
      }
    };  

    fetchAppraisalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box pb="3em" sx={{ overflow: "hidden" }}>
      <Helmet>
        <title>Hasil Perhitungan Harga Mobil</title>
        <meta
          name="description"
          content="Hasil perhitungan harga jual mobil bekas"
        />
      </Helmet>

      <Container sx={{ py: 1 }}>

        {/* Main Content Grid */}
        <Grid container spacing={1}>
          {/* Left Column - Car Details */}
          <Grid item xs={12} md={3} height="inherit">
            <CarDetailSection {...carDetails} />
          </Grid>

          {/* Right Column - Price Analysis and Actions */}
          <Grid item xs={12} md={9} spacing={1}>
            <Box display="flex" flexDirection="column" height="100%" gap={1}>
              <PriceAnalysisSection
                isLoading={isSalePriceLoading}
                salePriceData={salePriceData}
                handleOpenMarketPriceModal={handleOpenMarketPriceModal}
              />


              {/* Action Buttons */}
              <Box
                display="flex"
                gap={2}
                justifyContent="flex-end"
                width="100%"
              >
                <AnimatedButton
                  variant="contained"
                  color="primary"
                  onClick={handleOpenModal}
                  fullWidth
                >
                  Ajukan Inspeksi Kendaraan Sekarang
                </AnimatedButton>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Error Alert */}
        {error && (
          <Box sx={{ mt: 3 }}>
            <Alert
              variant="outlined"
              severity="error"
              sx={{ bgcolor: "#FDE8E8" }}
            >
              {error}
            </Alert>
          </Box>
        )}
          <Box display="flex" flexDirection="column" gap={2} py={2}>
            <PenaltyDetailSection
              scrapedDate={scrapedDate}
              salePriceData={salePriceData}
              isLoading={isSalePriceLoading}
              handleOpenMarketPriceModal={handleOpenMarketPriceModal}
            />
            <CarTaxDetailSection taxDetails={salePriceData?.Tax} isWithTaxLoading={isWithTaxLoading} />
          </Box>

              {/* Warning Section */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: "2px solid #E5C4B3",
                    bgcolor: "#FFF3E0",
                    p: 1,
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color="warning.dark"
                    fontWeight="500"
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 1,
                    }}
                  >
                    <WarningAmberIcon color="warning" />
                    Perhatian:
                  </Typography>
                  <Box
                    component="ul"
                    sx={{
                      pl: 2,
                      m: 1,
                      listStyleType: "disc",
                      "& li": {
                        color: "warning.dark",
                        mb: 1,
                      },
                    }}
                  >
                    <li>
                      <Typography variant="body2" color="warning.dark">
                        Harga yang ditampilkan di atas bukan merupakan harga
                        final. Harga tersebut dapat berubah setelah
                        memperhitungkan hasil inspeksi kendaraan.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="body2" color="warning.dark">
                        Untuk mendapatkan penawaran harga yang lebih akurat,
                        kami sarankan untuk melakukan inspeksi kendaraan secara
                        langsung.
                      </Typography>
                    </li>
                  </Box>
                </Box>
              </Grid>

        {/* Modals */}
        <MarketPriceSection
          salePriceData={salePriceData}
          carDetails={carDetails}
          open={openMarketPriceModal}
          onClose={handleCloseMarketPriceModal}
          averagePrice={salePriceData?.average_price}
          scrapedDate={scrapedDate}
          tableData={salePriceData?.data || []}
        />
      </Container>

      <CarInspectionModal
        open={openModal}
        onClose={handleCloseModal}
        formData={formData}
        onInputChange={handleInputChange}
        onSubmit={handleSubmit}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ResultSellPrice;
