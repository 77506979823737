import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Map, Marker, ZoomControl } from 'pigeon-maps';

// Default coordinates for Jakarta, Indonesia as the initial map center
const DEFAULT_POSITION = [-6.200000, 106.816666];
// Default zoom level for initial map display
const DEFAULT_ZOOM = 14;

/**
 * MapComponent - A reusable map component using pigeon-maps
 * 
 * @param {Object} selectedPosition - Contains lat and lng of selected location
 * @param {Function} onMapClick - Callback function when user clicks on map
 */
const MapComponent = ({ selectedPosition, onMapClick }) => {
  // State to track current zoom level of the map
  const [currentZoom, setCurrentZoom] = useState(DEFAULT_ZOOM);

  /**
   * Handles map zoom and bounds changes
   * Updates the current zoom level state
   */
  const handleMapChange = ({ zoom }) => {
    setCurrentZoom(zoom);
  };

  return (
    // Container for the map with fixed height
    <Box sx={{ height: '300px' }}>
      <Map
        height={300}
        // Use selected position if available, otherwise use default Jakarta coordinates
        center={selectedPosition ? [selectedPosition.lat, selectedPosition.lng] : DEFAULT_POSITION}
        zoom={currentZoom}
        maxZoom={19} // Maximum zoom level allowed
        onClick={onMapClick}
        onBoundsChanged={handleMapChange}
      >
        {/* Zoom control buttons for the map */}
        <ZoomControl />
        
        {/* Display marker only when a position is selected */}
        {selectedPosition && (
          <Marker
            width={50}
            anchor={[selectedPosition.lat, selectedPosition.lng]}
          />
        )}
      </Map>
    </Box>
  );
};

export default MapComponent; 