import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
// ACTIONS
import axios from 'axios'
import GetCookie from '../../hooks/GetCookie'

const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    // if (/windows phone/i.test(userAgent)) {
    //     return "Windows Phone";
    // }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "other"
}

export const captureEventToDB = async ({ event = null }) => {
    // console.log('//////////////////')
    // console.log('captureEventToDB')
    // console.log(`event: ${event}`)
    // console.log('//////////////////')

    if(JSON.parse(localStorage.getItem('user'))?.id !== 0) {
        const config = { headers: { 'Content-Type': 'application/json' } }

        if(typeof event !== "undefined" && event !== null) {
            try {
                await axios.post(
                    `${process.env.REACT_APP_API_PRODUCTION}/api/event/postEvent`,
                    { eventName: { 
                        ...event,
                        source: GetCookie({ cookieName: "_srcAds" }) !== undefined ? GetCookie({ cookieName: "_srcAds" }) : 'Web',
                        userId: GetCookie({ cookieName: "_visitorId" }),
                        platform: getMobileOperatingSystem()
                      }
                    },
                    config
                )
            } catch (error) {
                console.log('event fail', error)
            }
        } else {
            try {
                await axios.post(
                    `${process.env.REACT_APP_API_PRODUCTION}/api/event/postEvent`,
                    { 
                        eventName: { 
                            source: GetCookie({ cookieName: "_srcAds" }) !== undefined ? GetCookie({ cookieName: "_srcAds" }) : 'Web',
                            userId: GetCookie({ cookieName: "_visitorId" }),
                            platform: getMobileOperatingSystem()
                        }},
                    config
                )
            } catch (error) {
                console.log('event fail', error)
            }
        }
    }
}

export const captureEventGAWithAction = ({ category, action, params = null }) => {
    // console.log('=====')
    // console.log('captureEventGAWithAction')
    // console.log(`cat: ${category}, act: ${action}, par: ${params}`)
    // console.log(params)
    // const inputPar = {
    //     category: 'test',
    //     hehe: 'mantap',
    //     ...params
    // }
    // console.log(inputPar)
    // console.log('=====')

    if(JSON.parse(localStorage.getItem('user'))?.id !== 0) {
        if(typeof params !== "undefined" && params !== null) {
            ReactGA.event({ 
                category,
                action,
                ...params,
                source: GetCookie({ cookieName: "_srcAds" }) !== undefined ? GetCookie({ cookieName: "_srcAds" }) : 'Web', 
                userId: GetCookie({ cookieName: "_visitorId" }),
                platform: getMobileOperatingSystem()
            })
        } else {
            ReactGA.event({ 
                category,
                action,
                source: GetCookie({ cookieName: "_srcAds" }) !== undefined ? GetCookie({ cookieName: "_srcAds" }) : 'Web',
                userId: GetCookie({ cookieName: "_visitorId" }),
                platform: getMobileOperatingSystem()
            })
        }
    }
}

export const captureEventGAWithParams = ({ eventName, params = null }) => {
    // console.log('*********')
    // console.log('captureEventGAWithParams')
    // console.log(`event: ${eventName}, par: ${params}`)
    // const inputPar = {
    //     category: 'test',
    //     hehe: 'mantap',
    //     ...params
    // }
    // console.log(inputPar)
    // console.log('*********')

    if(JSON.parse(localStorage.getItem('user'))?.id !== 0) {
        if(typeof params !== "undefined" && params !== null) {
            ReactGA.event(
                eventName,
                { ...params,
                  source: GetCookie({ cookieName: "_srcAds" }) !== undefined ? GetCookie({ cookieName: "_srcAds" }) : 'Web',
                  userId: GetCookie({ cookieName: "_visitorId" }),
                  platform: getMobileOperatingSystem()
                }
            )
        } else {
            ReactGA.event(
                eventName,
                { source: GetCookie({ cookieName: "_srcAds" }) !== undefined ? GetCookie({ cookieName: "_srcAds" }) : 'Web',
                  userId: GetCookie({ cookieName: "_visitorId" }),
                  platform: getMobileOperatingSystem()
                }
            )
        }
    }
}

export const capturePageview = ({ page, pixel = true }) => {
    // console.log('^^^^^^^^^^^^^^^')
    // console.log('capturePageview')
    // console.log(`page: ${page}, pixel: ${pixel}`)
    // console.log('^^^^^^^^^^^^^^^')
    
    if(JSON.parse(localStorage.getItem('user'))?.id !== 0) {
        ReactGA.send({ hitType: "pageview", page })
        if(pixel) ReactPixel.pageView()
    }
}

export const captureEventPixel = ({ eventName, params }) => {
    if(JSON.parse(localStorage.getItem('user'))?.id !== 0) {
        ReactPixel.trackCustom( eventName, params )
    }
}

export const captureEventPageView = ({ pageTitle }) => {
    if(JSON.parse(localStorage.getItem('user'))?.id !== 0) {
        // DB
        captureEventToDB({
            event: { category: "pageview", eventName: `View Halaman ${pageTitle}` }
        })
        // GOOGLE ANALYTICS
        captureEventGAWithParams({
            eventName: `View Halaman ${pageTitle}`,
            params: { category: "pageview" }
        })
        // META PIXEL
        captureEventPixel({
            eventName: `View Halaman ${pageTitle}`,
            params: { category: "pageview" }
        })
    }
}