import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { 
  Box, 
  Grid,
  Typography, 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@mui/material';
import axios from 'axios';
import ScrapingVariantMenu from './ScrapingVariantMenu';

// Actions
import {
  listMerk,
  resetMerk,
  resetGroupModel,
  resetModel,
  resetModelYear,
} from "../../actions/carActions";

const ScrapingTriggerSection = ({ onRefresh }) => {
  const dispatch = useDispatch();
  const selectedValue = useSelector((state) => state.sellPriceSelected);

  const [open, setOpen] = useState(false);
  const [openCash, setOpenCash] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCash, setLoadingCash] = useState(false);
  const [loadingAppraisal, setLoadingAppraisal] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    scraping_olx: true,
    scraping_mobil123: true,
    clean_scraped_data: true,
    export_to_google_sheets: true
  });

  const [availableCarModels, setAvailableCarModels] = useState({});
  const [openAppraisalConfirm, setOpenAppraisalConfirm] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckAll = (event) => {
    setCheckboxes({
      scraping_olx: event.target.checked,
      scraping_mobil123: event.target.checked,
      clean_scraped_data: event.target.checked,
      export_to_google_sheets: event.target.checked
    });
  };

  const handleChange = (event) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    axios.post(`${process.env.REACT_APP_PYTHON_API_PRODUCTION}/scrape-marketplace`, checkboxes)
      .catch(error => {
        console.error('Error during scraping:', error);
      })
      .finally(() => {
        setLoading(false);
      });
    handleClose();
    onRefresh();
  };

  const handleCashOpen = () => {
    setOpenCash(true);
    dispatch(resetMerk());
    dispatch(resetGroupModel());
    dispatch(resetModel());
    dispatch(resetModelYear());
  };

  const handleCashClose = () => {
    setOpenCash(false);
  };

  const handleCashSubmit = () => {
    setLoadingCash(true);
    const payload = { 
      brand: selectedValue.brands?.name,
      groupModel: selectedValue.groupModel?.name,
      model: selectedValue.model?.name,
      modelYear: selectedValue.modelYear,
    };
    axios.post(`${process.env.REACT_APP_PYTHON_API_PRODUCTION}/scrape-cash`, payload)
      .catch(error => {
        console.error('Error during cash scraping:', error);
      })
      .finally(() => {
        setLoadingCash(false);
      });
    handleCashClose();
    onRefresh();
  };

  const handleAppraisalOpen = () => {
    setOpenAppraisalConfirm(true);
  };

  const handleAppraisalClose = () => {
    setOpenAppraisalConfirm(false);
  };

  const handleAppraisalTest = () => {
    setLoadingAppraisal(true);
    axios.post(`${process.env.REACT_APP_PYTHON_API_PRODUCTION}/appraisal-test`)
      .catch(error => {
        console.error('Error during appraisal test:', error);
      })
      .finally(() => {
        setLoadingAppraisal(false);
        handleAppraisalClose();
        onRefresh();
      });
  };

  const allChecked = Object.values(checkboxes).every(value => value);

  // Initialize data and cleanup on unmount
  useEffect(() => {
    // Initialize data
    dispatch(listMerk());

    // Add new API call
    const fetchAvailableCarModels = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PYTHON_API_PRODUCTION}/available-car-model`);
        const filteredData = filterNonZeroCounts(response?.data?.data);
        setAvailableCarModels(filteredData);
      } catch (error) {
        setAvailableCarModels({});
        console.error("Error fetching available car models:", error);
      }
    };

    fetchAvailableCarModels();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box 
      sx={{ 
        height: '100%', 
        borderBottom: '1px solid #D0D6DC',
        mb: 2,
        pb: 2
      }}
    >
      <Box mb={2}>
        <Typography variant="h6">
          Scraping Trigger
        </Typography>
      </Box>
      
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 2, sm: 4 }, 
          mb: 2,
          '& .MuiButton-root': {
            width: { xs: '100%', sm: 'auto' }
          }
        }}
      >
        <Button 
          variant="contained" 
          onClick={handleClickOpen}
        >
          Scraping Marketplace
        </Button>
        <Button 
          variant="contained" 
          color="warning"
          onClick={handleCashOpen}
        >
          Scraping Cash
        </Button>
        <Button 
          variant="contained" 
          color="info"
          onClick={handleAppraisalOpen}
          disabled={loadingAppraisal}
        >
          {loadingAppraisal ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Appraisal Test'
          )}
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>Scraping Marketplace</DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allChecked}
                  onChange={handleCheckAll}
                />
              }
              label="Check All"
              sx={{
                borderBottom: '1px solid #D0D6DC',
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.scraping_olx}
                  onChange={handleChange}
                  name="scraping_olx"
                />
              }
              label="Scraping OLX"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.scraping_mobil123}
                  onChange={handleChange}
                  name="scraping_mobil123"
                />
              }
              label="Scraping Mobil123"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.clean_scraped_data}
                  onChange={handleChange}
                  name="clean_scraped_data"
                />
              }
              label="Clean Scraped Data"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.export_to_google_sheets}
                  onChange={handleChange}
                  name="export_to_google_sheets"
                />
              }
              label="Export to Google Sheets"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions sx={{ gap: 2 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Run Scraping'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCash} onClose={handleCashClose} fullWidth maxWidth="xs">
        <DialogTitle>Scraping Cash</DialogTitle>
          <DialogContent sx={{ pt: 0 }}>
            <Box display="flex" flexDirection="column" gap={1.5} sx={{ pt: 0 }}>
              <Typography variant="body2">
                Scraping cash detail untuk marketplace Mobil123.
              </Typography>
              <Grid container spacing={2}>
                <ScrapingVariantMenu availableCarModels={availableCarModels} />
              </Grid>
              <Typography variant="caption" fontWeight="500">
                Catatan:
              </Typography>
              <ul style={{ paddingLeft: 20, marginTop: 0 }}>
                <li>
                  <Typography variant="caption" color="text.secondary">
                    Apabila varian mobil <strong>tidak diisi</strong>, maka scraping akan dilakukan untuk <strong>semua varian</strong>.
                  </Typography>
                </li>
                <li>
                  <Typography variant="caption" color="text.secondary">
                    Apabila varian mobil <strong>diisi semua atau sebagian</strong>, maka scraping akan dilakukan untuk <strong>varian tersebut</strong>.
                  </Typography>
                </li>
              </ul>
            </Box>
          </DialogContent>
          <DialogActions sx={{ gap: 2 }}>
            <Button onClick={handleCashClose}>Cancel</Button>
            <Button variant="contained" disabled={loadingCash} onClick={handleCashSubmit}>Run Scraping</Button>
          </DialogActions>
      </Dialog>

      <Dialog open={openAppraisalConfirm} onClose={handleAppraisalClose} fullWidth maxWidth="xs">
        <DialogTitle>Konfirmasi Appraisal Test</DialogTitle>
        <DialogContent>
          <Typography>
            Apakah Anda yakin ingin menjalankan Appraisal Test?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ gap: 2 }}>
          <Button onClick={handleAppraisalClose}>Cancel</Button>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleAppraisalTest}
            disabled={loadingAppraisal}
          >
            {loadingAppraisal ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Run Test'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const filterNonZeroCounts = (data) => {
  const result = {};
  
  // Iterate through brands
  for (const brand in data) {
      const filteredModels = {};
      
      // Iterate through models
      for (const model in data[brand]) {
          const filteredYears = {};
          
          // Iterate through years
          for (const year in data[brand][model]) {
              const filteredVariants = {};
              
              // Iterate through variants
              for (const variant in data[brand][model][year]) {
                  // Only include variants with count > 0
                  if (data[brand][model][year][variant].count > 0) {
                      filteredVariants[variant] = data[brand][model][year][variant];
                  }
              }
              
              // Only include years that have variants with non-zero counts
              if (Object.keys(filteredVariants).length > 0) {
                  filteredYears[year] = filteredVariants;
              }
          }
          
          // Only include models that have years with non-zero counts
          if (Object.keys(filteredYears).length > 0) {
              filteredModels[model] = filteredYears;
          }
      }
      
      // Only include brands that have models with non-zero counts
      if (Object.keys(filteredModels).length > 0) {
          result[brand] = filteredModels;
      }
  }
  
  return result;
}

export default ScrapingTriggerSection;
