// DESIGN SYSTEM DI FIGMA
export const fontStyleConstant = {
    heading: { fontSize: '40px', fontWeight: 'bold' },
    heading2: { fontSize: '36px', fontWeight: 'bold' },
    heading3: { fontSize: '24px', fontWeight: 'bold' },
    mobileHeading1: { fontSize: '20px', fontWeight: 'bold' },
    mobileHeading2: { fontSize: '18px', fontWeight: 'bold' },
    mobileHeading3: { fontSize: '16px', fontWeight: 'bold' },
    subHeading: { fontSize: '30px', fontWeight: 'bold' },
    meta: { fontSize: '20px' },
    textBigBold: { fontSize: '16px', fontWeight: 'bold' },
    textBig: { fontSize: '16px' },
    textSmallBold: { fontSize: '12px', fontWeight: 'bold' },
    textSmall: { fontSize: '12px' },
}

export const fontPreset = {
    body34Bold: { fontSize: "34px", fontWeight: "600" },
    body34Reg: { fontSize: "34px", fontWeight: "400" },
    body34Lig: { fontSize: "34px", fontWeight: "300" },
    body32Bold: { fontSize: "32px", fontWeight: "600" },
    body32Reg: { fontSize: "32px", fontWeight: "400" },
    body32Lig: { fontSize: "32px", fontWeight: "300" },
    body30Bold: { fontSize: "30px", fontWeight: "600" },
    body30Reg: { fontSize: "30px", fontWeight: "400" },
    body30Lig: { fontSize: "30px", fontWeight: "300" },
    body28Bold: { fontSize: "28px", fontWeight: "600" },
    body28Reg: { fontSize: "28px", fontWeight: "400" },
    body28Lig: { fontSize: "28px", fontWeight: "300" },
    body26Bold: { fontSize: "26px", fontWeight: "600" },
    body26Reg: { fontSize: "26px", fontWeight: "400" },
    body26Lig: { fontSize: "26px", fontWeight: "300" },
    body24Bold: { fontSize: "24px", fontWeight: "600" },
    body24Reg: { fontSize: "24px", fontWeight: "400" },
    body24Lig: { fontSize: "24px", fontWeight: "300" },
    body22Reg: { fontSize: "22px", fontWeight: "400" },
    body22Bold: { fontSize: "22px", fontWeight: "600" },
    body22Lig: { fontSize: "22px", fontWeight: "300" },
    body20Bold: { fontSize: "20px", fontWeight: "600" },
    body20Reg: { fontSize: "20px", fontWeight: "400" },
    body20Lig: { fontSize: "20px", fontWeight: "300" },
    body18Bold: { fontSize: "18px", fontWeight: "600" },
    body18Reg: { fontSize: "18px", fontWeight: "400" },
    body18Lig: { fontSize: "18px", fontWeight: "300" },
    body16Bold: { fontSize: "16px", fontWeight: "600" },
    body16Reg: { fontSize: "16px", fontWeight: "400" },
    body16Lig: { fontSize: "16px", fontWeight: "300" },
    body14Bold: { fontSize: "14px", fontWeight: "600" },
    body14Reg: { fontSize: "14px", fontWeight: "400" },
    body14Lig: { fontSize: "14px", fontWeight: "300" },
    body13Bold: { fontSize: "13px", fontWeight: "600" },
    body13Reg: { fontSize: "13px", fontWeight: "400" },
    body13Lig: { fontSize: "13px", fontWeight: "300" },
    body12Bold: { fontSize: "12px", fontWeight: "600" },
    body12Reg: { fontSize: "12px", fontWeight: "400" },
    body12Lig: { fontSize: "12px", fontWeight: "300" },
    body10Bold: { fontSize: "10px", fontWeight: "600" },
    body10Reg: { fontSize: "10px", fontWeight: "400" },
    body10Lig: { fontSize: "10px", fontWeight: "300" },
    body8Bold: { fontSize: "8px", fontWeight: "600" },
    body8Reg: { fontSize: "8px", fontWeight: "400" },
    body8Lig: { fontSize: "8px", fontWeight: "300" },
}