import axios from "axios"
import { 
    APPLY_CREDIT_KKB_FAIL,
    APPLY_CREDIT_KKB_REQUEST,
    APPLY_CREDIT_KKB_RESET,
    APPLY_CREDIT_KKB_SUCCESS,
    GET_KKB_ESTIMATION_FAIL,
    GET_KKB_ESTIMATION_REQUEST,
    GET_KKB_ESTIMATION_RESET,
    GET_KKB_ESTIMATION_SUCCESS,
    GET_OTHER_PLATFORM_FAIL,
    GET_OTHER_PLATFORM_REQUEST,
    GET_OTHER_PLATFORM_RESET,
    GET_OTHER_PLATFORM_SUCCESS,
    GET_OTHER_PROMO_CARS_FAIL,
    GET_OTHER_PROMO_CARS_REQUEST,
    GET_OTHER_PROMO_CARS_SUCCESS,
    GET_PROMO_BANNER_FAIL, 
    GET_PROMO_BANNER_REQUEST, 
    GET_PROMO_BANNER_SUCCESS, 
    GET_PROMO_BY_MYID_FAIL, 
    GET_PROMO_BY_MYID_REQUEST, 
    GET_PROMO_BY_MYID_SUCCESS, 
    GET_PROMO_CAR_LIST_FAIL, 
    GET_PROMO_CAR_LIST_REQUEST, 
    GET_PROMO_CAR_LIST_RESET, 
    GET_PROMO_CAR_LIST_SUCCESS
} from "../constants/promoConstants"

// GET BANNER PROMO
// DESC     : GET LIST BANNER PROMO ON KKB PAGE
// METHOD   : GET
export const getBannerPromo = ({ promoId = null }) => async (dispatch) => {
    try {
        dispatch({ type: GET_PROMO_BANNER_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api-admin/banner/getBannerForWeb/${promoId !== null ? `?promoId=${promoId}` : ''}`)

        dispatch({ type: GET_PROMO_BANNER_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_PROMO_BANNER_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

// GET PROMO
// DESC     : GET PROMO PER CAR VARIANT (BY MODEL YEAR ID)
// METHOD   : GET
export const getPromo = ({ modelYearId = null }) => async (dispatch) => {
    try {
        dispatch({ type: GET_PROMO_BY_MYID_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/promo/getAllPromoByModelYearId/kkb/promo?${modelYearId != null ? `modelYearId=${modelYearId}` : ''}`)

        dispatch({ type: GET_PROMO_BY_MYID_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_PROMO_BY_MYID_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

// GET PROMO CARS
// DESC     : GET LIST ALL PROMO CARS ON KKB PAGE
// METHOD   : GET
export const getPromoCars = ({ modelYearId, limit = 12 }) => async (dispatch) => {
    try {
        dispatch({ type: GET_PROMO_CAR_LIST_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/cars?limit=${limit}&modelYearId=${modelYearId}&categoryId=2`)

        dispatch({ type: GET_PROMO_CAR_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_PROMO_CAR_LIST_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// CLEAR PROMO CARS
// DESC     : RESET PROMO CAR LIST ON STATE
// METHOD   : -
export const clearPromoCars = () => (dispatch) => {
    dispatch({ type: GET_PROMO_CAR_LIST_RESET })
}

// GET OTHER PROMO CARS
// DESC     : GET OTHER PROMO CAR LIST ON KKB
// METHOD   : GET
export const getOtherPromoCars = () => async (dispatch) => {
    try {
        dispatch({ type: GET_OTHER_PROMO_CARS_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/promo/getAllPromo/kkb/promo`)

        dispatch({ type: GET_OTHER_PROMO_CARS_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_OTHER_PROMO_CARS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

// APPLY CREDIT KKB
// DESC     : SUBMIT CREDIT KKB FORM REQUEST ON KKB
// METHOD   : POST
export const applyCreditKKB = ({ 
    modelYearId,
    carName,
    installment,
    tdp,
    tenor,
    leasingId = 1,
    name,
    phone
}) => async (dispatch) => {
    try {
        dispatch({ type: APPLY_CREDIT_KKB_REQUEST })

        const config = {
            headers: { 'Content-Type': 'application/json' }
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_API_PRODUCTION}/api/kkb/`,
            { carName, modelYearId, installment, tdp, tenor, leasingId, name, phone },
            config
        )

        dispatch({ type: APPLY_CREDIT_KKB_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: APPLY_CREDIT_KKB_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

// RESET APPLY CREDIT KKB
// DESC     : RESET APPLY CREDIT KKB ON STATE
// METHOD   : -
export const resetApplyCreditKKB = () => (dispatch) => {
    dispatch({ type: APPLY_CREDIT_KKB_RESET })
}

// GET OTHER PLATFORM
// DESC     : GET CARS ON OTHER PLATFORM (BY MODEL YEAR ID)
// METHOD   : GET
export const getOtherPlatform = ({ modelYearId }) => async (dispatch) => {
    try {
        dispatch({ type: GET_OTHER_PLATFORM_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/otherPlatformPrice/?modelYearId=${modelYearId}`)

        dispatch({ type: GET_OTHER_PLATFORM_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_OTHER_PLATFORM_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// RESET GET OTHER PLATFORM
// DESC     : RESET GET OTHER PLATFOM STATE
// METHOD   : -
export const resetGetOtherPlatform = () => (dispatch) => {
    dispatch({ type: GET_OTHER_PLATFORM_RESET })
}

// GET KKB ESTIMATION
// DESC     : GET ESTIMATION VALUE OF KKB SIMULATION
// METHOD   : -
export const getKkbEstimation = ({ 
    modelId = "1234",
    type = null,
    groupModelId = null,
    years = null
}) => async (dispatch) => {
    try {
        dispatch({ type: GET_KKB_ESTIMATION_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/kkb/estimateTDP/${modelId}?${type !== null ? `type=${type}&` : ""}${groupModelId !== null ? `groupModelId=${groupModelId}` : ""}${years !== null ? `&years=${years}` : ""}`)

        dispatch({ type: GET_KKB_ESTIMATION_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_KKB_ESTIMATION_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// RESET KKB ESTIMATION
// DESC     : RESET KKB ESTIMATION ON STATE
// METHOD   : -
export const resetKkbEstimation = () => (dispatch) => {
    dispatch({ type: GET_KKB_ESTIMATION_RESET })
}