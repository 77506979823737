import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Stack,
  CircularProgress,
  Button,
  TablePagination,
  Popover
} from '@mui/material';
import ScrapingTriggerSection from './ScrapingTriggerSection';
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const colorStatus = {
  "Completed": {backgroundColor: '#e8f5e9', tagColor: '#4caf50'},
  "In Progress": {backgroundColor: '#fff3e0', tagColor: '#ff9800'},
  "Failed": {backgroundColor: '#ffcdd2', tagColor: '#f44336'}
}

const ScrapingDetailSection = () => {
  const [scrapingLogs, setScrapingLogs] = useState([]);
  const [orderBy, setOrderBy] = useState('Start Date');
  const [order, setOrder] = useState('desc');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  
  const [filters, setFilters] = useState({
    category: "",
    section: "",
    triggerDate: "",
    scrapeStatus: ""
  });

  const [refreshInterval, setRefreshInterval] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');

  useEffect(() => {
    fetchScrapingLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hasInProgressLogs = scrapingLogs.some(log => log['Scrape Status'] === 'In Progress');

    if (refreshInterval) {
      clearInterval(refreshInterval);
    }

    if (hasInProgressLogs) {
      const interval = setInterval(() => fetchScrapingLogs({autoRefresh: true}), 30000);
      setRefreshInterval(interval);
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrapingLogs]);

  const convertToLocalDate = (date) => {
    if (!date) return '';
    
    try {
      // Convert date string to ISO format
      const dateStr = date.replace(' ', 'T') + 'Z';
      return formatInTimeZone(
        parseISO(dateStr),
        'Asia/Jakarta',
        'yyyy-MM-dd HH:mm:ss'
      );
    } catch (error) {
      console.error('Date parsing error:', error);
      return '-';
    }
  };

  const fetchScrapingLogs = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_PYTHON_API_PRODUCTION}/scraping-logs`);
      const data = await response.json();
      const _data = data?.data.map(log => ({
        ...log,
        'Start Date': log['Start Date'] ? convertToLocalDate(log['Start Date']) : '',
        'End Date': log['End Date'] ? convertToLocalDate(log['End Date']) : '',
        'Trigger Date': log['Trigger Date'] ? convertToLocalDate(log['Trigger Date']) : ''
      }));
      setScrapingLogs(_data);
    } catch (error) {
      console.error('Error fetching scraping logs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortData = (data) => {
    return [...data].sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      if (order === 'desc') {
        if (bValue < aValue) return -1;
        if (bValue > aValue) return 1;
      } else {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
      }
      return 0;
    });
  };

  const SortableTableCell = ({ id, label, width }) => (
    <TableCell
      sx={{ 
        width,
        ...(id === 'Scrape Status' && {
          position: 'sticky',
          right: 0,
          backgroundColor: '#F9FAFB',
          borderLeft: '1px solid #D0D6DC',
          zIndex: 2
        })
      }}
    >
      <TableSortLabel
        active={orderBy === id}
        direction={orderBy === id ? order : 'asc'}
        onClick={() => handleRequestSort(id)}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );

  const columns = [
    // { id: 'Scrape ID', label: 'Scrape ID', width: '100px' },
    { id: 'Trigger Date', label: 'Trigger Date', width: '120px' },
    { id: 'Category', label: 'Category', width: '120px' },
    { id: 'Section', label: 'Section', width: '120px' },
    { id: 'Start Date', label: 'Start Date', width: '120px' },
    { id: 'End Date', label: 'End Date', width: '120px' },
    { id: 'Duration', label: 'Duration', width: '100px' },
    { id: 'Latest Process', label: 'Latest Process', width: '200px' },
    { id: 'Process Status', label: 'Process Status', width: '150px' },
    { id: 'Scrape Status', label: 'Scrape Status', width: '100px' },
  ];

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const getFilteredData = (data) => {
    return data.filter(log => {
      const matchCategory = !filters.category || log.Category === filters.category;
      const matchSection = !filters.section || log.Section === filters.section;
      const matchStatus = !filters.scrapeStatus || log['Scrape Status'] === filters.scrapeStatus;
      const matchTriggerDate = !filters.triggerDate || log['Trigger Date'].includes(filters.triggerDate);
      return matchCategory && matchSection && matchStatus && matchTriggerDate;
    });
  };

  const getUniqueValues = (field) => {
    return [...new Set(scrapingLogs.map(log => log[field]))];
  };

  const handleResetFilters = () => {
    setFilters({
      category: "",
      section: "",
      triggerDate: "",
      scrapeStatus: ""
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = (data) => {
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const handlePopoverOpen = (event, content) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent('');
  };

  const renderCellContent = (value, maxWidth) => {
    if (!value || value === '-') return '-';
    
    const stringValue = String(value);
    if (stringValue.length > 50) { // Threshold untuk memotong teks
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxWidth }}>
          <Typography variant="caption" noWrap sx={{ maxWidth: `calc(${maxWidth} - 40px)` }}>
            {stringValue}
          </Typography>
          <IconButton
            size="small"
            onClick={(e) => handlePopoverOpen(e, stringValue)}
          >
            <MoreHorizIcon fontSize="small" />
          </IconButton>
        </Box>
      );
    }
    return <Typography variant="caption">{stringValue}</Typography>;
  };

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        height: '100%', 
        borderRadius: 2, 
        border: '1px solid #D0D6DC',
        p: 2
      }}
    >
      <ScrapingTriggerSection 
        onRefresh={fetchScrapingLogs}
      />
      <Box mb={2}>
        <Typography variant="h6">
          Scraping Logs
        </Typography>
      </Box>
      
      <Box mb={3}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          sx={{
            width: '100%',
            mb: { xs: 2, sm: 0 }
          }}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            sx={{
              width: '100%',
              flexWrap: { sm: 'wrap' },
              '& .MuiFormControl-root': {
                width: { xs: '100%', sm: 150 }
              }
            }}
          >
            <TextField
              size="small"
              name="triggerDate"
              label="Trigger Date"
              type="date"
              value={filters.triggerDate}
              onChange={handleFilterChange}
              InputLabelProps={{ shrink: true }}
            />
            <FormControl size="small">
              <InputLabel>Category</InputLabel>
              <Select
                name="category"
                value={filters.category}
                label="Category"
                onChange={handleFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                {getUniqueValues('Category').map(category => (
                  <MenuItem key={category} value={category}>{category}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small">
              <InputLabel>Section</InputLabel>
              <Select
                name="section"
                value={filters.section}
                label="Section"
                onChange={handleFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                {getUniqueValues('Section').map(section => (
                  <MenuItem key={section} value={section}>{section}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small">
              <InputLabel>Scrape Status</InputLabel>
              <Select
                name="scrapeStatus"
                value={filters.scrapeStatus}
                label="Scrape Status"
                onChange={handleFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                {getUniqueValues('Scrape Status').map(status => (
                  <MenuItem key={status} value={status}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Stack
            direction={{ xs: 'row', sm: 'row' }}
            spacing={2}
            sx={{
              justifyContent: { xs: 'space-between', sm: 'flex-start' }
            }}
          >
            <Button 
              variant="text" 
              color="primary"
              onClick={handleResetFilters}
              size="small"
              sx={{ width: '100px' }}
            >
              Reset Filter
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={fetchScrapingLogs}
              size="small"
              endIcon={<RefreshIcon />}
              sx={{ width: '100px' }}
            >
              Refresh
            </Button>
          </Stack>
        </Stack>
      </Box>

      <TableContainer 
        sx={{ 
          borderRadius: '8px 8px 0 0',
          border: '1px solid #D0D6DC',
          borderBottom: 'none',
          position: 'relative',
          overflowX: 'auto',
          maxWidth: '100%',
          minHeight: 200
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1
            }}
          >
            <CircularProgress />
          </Box>
        ) : null}

        <Table sx={{ minWidth: 1200 }}>
          <TableHead sx={{ backgroundColor: '#F9FAFB' }}>
            <TableRow>
              {columns.map((column) => (
                <SortableTableCell
                  key={column.id}
                  id={column.id}
                  label={column.label}
                  width={column.width}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData(sortData(getFilteredData(scrapingLogs))).map((log) => (
              <TableRow 
                key={log['Scrape ID']}
                sx={{
                  backgroundColor: colorStatus[log['Scrape Status']]?.backgroundColor || 'inherit'
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{ 
                      width: column.width,
                      ...(column.id === 'Scrape Status' && {
                        position: 'sticky',
                        right: 0,
                        borderLeft: '1px solid #D0D6DC',
                        backgroundColor: colorStatus[log['Scrape Status']]?.backgroundColor || 'inherit',
                      })
                    }}
                  >
                    {column.id === 'Scrape Status' ? (
                      <Box
                        sx={{
                          backgroundColor: colorStatus[log['Scrape Status']]?.tagColor || 'inherit',
                          color: 'white',
                          px: 1,
                          py: 0.25,
                          borderRadius: 1,
                          display: 'inline-block'
                        }}
                      >
                        <Typography variant="caption">{log[column.id]}</Typography>
                      </Box>
                    ) : (
                      renderCellContent(log[column.id], column.width)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, maxWidth: 400 }}>
          <Typography>{popoverContent}</Typography>
        </Box>
      </Popover>

      <TablePagination
        component="div"
        count={getFilteredData(scrapingLogs).length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        sx={{
          borderRadius: '0 0 8px 8px',
          border: '1px solid #D0D6DC',
          borderTop: 'none'
        }}
      />
    </Paper>
  );
};

export default ScrapingDetailSection;
