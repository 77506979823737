import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material';
import LocationSearch from '../../../components/inspectionRequestForm/LocationSearch';
import MapComponent from '../../../components/inspectionRequestForm/MapComponent';
import { useLocationManager } from '../../../hooks/inspectionRequestForm/useLocationManager';

/**
 * CarInspectionModal Component
 * Modal dialog for submitting vehicle inspection requests.
 * Includes form fields for personal info and location selection.
 * 
 * @param {boolean} open - Controls modal visibility state
 * @param {Function} onClose - Handler for closing the modal
 * @param {Object} formData - Contains form field values:
 *   - name: User's full name
 *   - phone: User's contact number
 *   - address: Selected location address
 * @param {Function} onInputChange - Handler for form input changes
 * @param {Function} onSubmit - Handler for form submission
 */
const CarInspectionModal = ({ 
  open, 
  onClose, 
  formData, 
  onInputChange, 
  onSubmit 
}) => {
  // Custom hook for managing location-related functionality
  const {
    searchQuery,        // Current text in location search field
    selectedPosition,   // Selected map coordinates {lat, lng}
    handleSearchChange, // Handler for location search input changes
    handleSelectSuggestion, // Handler for when user selects a location suggestion
    handleMapClick,     // Handler for map click events
    suggestions,        // Array of location suggestions
    showSuggestions,    // Boolean to control suggestions dropdown visibility
  } = useLocationManager({ onInputChange });

  return (
    // Main modal dialog container
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
    >
      {/* Modal title */}
      <DialogTitle sx={{ pb: 1 }}>
        Inspeksi Kendaraan
      </DialogTitle>

      <DialogContent>
        {/* Instructional text for users */}
        <Box sx={{ color: 'text.secondary', mb: 2, fontSize: '0.875rem' }}>
          Silakan isi form berikut agar kami dapat segera menghubungi Anda
        </Box>

        {/* Form fields container */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Name input field */}
          <TextField
            fullWidth
            label="Nama"
            name="name"
            value={formData.name}
            onChange={onInputChange}
          />

          {/* Phone number input field */}
          <TextField
            fullWidth
            label="Nomor Telepon"
            name="phone"
            value={formData.phone}
            onChange={(e) => {
              // Only allow numbers and plus symbol
              const value = e.target.value.replace(/[^\d+]/g, '');
              // Ensure plus symbol is only at the beginning
              const formattedValue = value.replace(/^\++/, '+').replace(/(?!^)\+/g, '');
              
              // Get the maximum length based on the prefix
              const maxLength = formattedValue.startsWith('+62') ? 15 : 14;
              
              // Truncate the value if it exceeds the maximum length
              const truncatedValue = formattedValue.slice(0, maxLength);
              
              onInputChange({
                target: {
                  name: 'phone',
                  value: truncatedValue
                }
              });
            }}
            inputProps={{
              pattern: '^([+]?62|0)[0-9]{9,13}$' // Pattern: +62/0 followed by 9-13 digits
            }}
            helperText="Format: +62812xxxxx (min 9, max 13 digit) atau 0812xxxxx (min 9, max 13 digit)"
            error={formData.phone && !/^([+]?62|0)[0-9]{9,13}$/.test(formData.phone)}
          />

          {/* Location search component with autocomplete */}
          <LocationSearch
            searchQuery={searchQuery}
            suggestions={suggestions}
            showSuggestions={showSuggestions}
            onSearchChange={handleSearchChange}
            onSelectSuggestion={handleSelectSuggestion}
          />

          {/* Interactive map component for location selection */}
          <MapComponent
            selectedPosition={selectedPosition}
            onMapClick={handleMapClick}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ pr: 3, pb: 2, display: 'flex', gap: 1 }}>
        {/* Cancel button */}
        <Button 
          variant="outlined" 
          onClick={onClose} 
          sx={{ textTransform: 'capitalize' }}
        >
          Batal
        </Button>
        {/* Submit button */}
        <Button 
          variant="contained" 
          onClick={onSubmit} 
          sx={{ textTransform: 'capitalize' }}
          disabled={
            !formData.name || 
            !formData.phone || 
            !selectedPosition ||
            !/^([+]?62|0)[0-9]{9,13}$/.test(formData.phone)
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CarInspectionModal;
