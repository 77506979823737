import axios from 'axios'
import {
    SELECT_MERK,
    SELECT_GROUP_MODEL,
    SELECT_MODEL,
    SELECT_MODEL_YEAR,
    RESET_MERK,
    RESET_GROUP_MODEL,
    RESET_MODEL,
    RESET_MODEL_YEAR,
    BRAND_LIST_REQUEST,
    BRAND_LIST_SUCCESS,
    BRAND_LIST_FAIL,
    GROUP_MODEL_LIST_REQUEST,
    GROUP_MODEL_LIST_SUCCESS,
    GROUP_MODEL_LIST_FAIL,
    MODEL_LIST_REQUEST,
    MODEL_LIST_SUCCESS,
    MODEL_LIST_FAIL,
    MODEL_YEAR_LIST_REQUEST,
    MODEL_YEAR_LIST_SUCCESS,
    MODEL_YEAR_LIST_FAIL,
    MODEL_LIST_RESET,
    GROUP_MODEL_LIST_RESET,
    MODEL_YEAR_LIST_RESET,
    CAR_DETAILS_REQUEST,
    CAR_DETAILS_SUCCESS,
    CAR_DETAILS_FAIL,
    COLOR_REQUEST,
    COLOR_SUCCESS,
    COLOR_FAIL,
    YEAR_OF_USAGE_REQUEST,
    YEAR_OF_USAGE_SUCCESS,
    YEAR_OF_USAGE_FAIL,
    YEAR_OF_USAGE_RESET,
    SET_FOCUS_FIELD,
    MODEL_LIST_BY_YEAR_REQUEST,
    MODEL_LIST_BY_YEAR_SUCCESS,
    MODEL_LIST_BY_YEAR_FAIL,
    RESET_ALL_SELECTED_VALUE,
    RESET_FOCUS_FIELD,
    RESET_CLIPAN_ERROR,
    NOT_IS_CLIPAN,
    SELECT_MODEL_YEAR_2,
    RESET_MODEL_YEAR_2,
    MODEL_LIST_BY_RANGE_YEAR_REQUEST,
    MODEL_LIST_BY_RANGE_YEAR_SUCCESS,
    RESET_CLIPAN_ERROR_ON_SIMULASI,
    CAR_DETAILS_RESET,
} from '../constants/brandConstants'
import { CAR_HERO_FAIL, CAR_HERO_REQUEST, CAR_HERO_RESET, CAR_HERO_SUCCESS, RESET_HERO_CATEGORY, SEARCH_CAR_AMOUNT_FAIL, SEARCH_CAR_AMOUNT_REQUEST, SEARCH_CAR_AMOUNT_SUCCESS, SELECT_HERO_CATEGORY } from '../constants/carPerCategoryConstant'
import { RESET_CITY_AT_DJUBLI_CHECK, RESET_EXTERIOR_COLOR_AT_DJUBLI_CHECK, SELECT_CITY_AT_DJUBLI_CHECK, SELECT_EXTERIOR_COLOR_AT_DJUBLI_CHECK } from '../constants/djubliCheckConstant'
import { 
    RESET_CITY_AT_FILTER,
    RESET_EXTERIOR_COLOR_AT_FILTER,
    RESET_INTERIOR_COLOR_AT_FILTER,
    RESET_SUBDISTRICT_AT_FILTER,
    SELECT_CITY_AT_FILTER,
    SELECT_EXTERIOR_COLOR_AT_FILTER,
    SELECT_INTERIOR_COLOR_AT_FILTER,
    SELECT_SUBDISTRICT_AT_FILTER,
} from '../constants/filterShowPerCategory'

// ============== INPUT DATA TO SELECTED VALUE ================

// SELECT MERK
// DESC     : SAVE SELECTED CAR BRAND ON STATE
// METHOD   : -
export const selectMerk = (selectedBrand) => (dispatch) => {
    dispatch({ type: SELECT_MERK, payload: selectedBrand })
}

// SELECT GROUP MODEL
// DESC     : SAVE SELECTED CAR GROUP MODEL ON STATE
// METHOD   : -
export const selectGroupModel = (selectedGroupModel) => (dispatch) => {
    dispatch({ type: SELECT_GROUP_MODEL, payload: selectedGroupModel })
}

// SELECT MODEL
// DESC     : SAVE SELECTED CAR MODEL ON STATE
// METHOD   : -
export const selectModel = (selectedModel) => (dispatch) => {
    dispatch({ type: SELECT_MODEL, payload: selectedModel })
}

// SELECT MODEL YEAR
// DESC     : SAVE SELECTED CAR YEAR MODEL ON STATE
// METHOD   : -
export const selectModelYear = (selectedModelYear) => (dispatch) => {
    dispatch({ type: SELECT_MODEL_YEAR, payload: selectedModelYear })
}

// SELECT MODEL YEAR 2
// DESC     : SAVE SELECTED CAR YEAR MODEL ON STATE (SECOND INPUT ON RANGE)
//            EX => 2021 - 2022 = MODELYEAR: 2021, MODELYEAR2: 2022
// METHOD   : -
export const selectModelYear2 = (selectedModelYear) => (dispatch) => {
    dispatch({ type: SELECT_MODEL_YEAR_2, payload: selectedModelYear })
}

// SELECT EXTERIOR COLOR ON FILTER
// DESC     : SAVE SELECTED EXTERIOR COLOR ON STATE (ON FILTER MENU)
// METHOD   : -
export const selectExteriorColorAtFilter = (selectedColor) => (dispatch) => {
    dispatch({ type: SELECT_EXTERIOR_COLOR_AT_FILTER, payload: selectedColor })
}

// SELECT INTERIOR COLOR ON FILTER
// DESC     : SAVE SELECTED INTERIOR COLOR ON STATE (ON FILTER MENU)
// METHOD   : -
export const selectInteriorColorAtFilter = (selectedColor) => (dispatch) => {
    dispatch({ type: SELECT_INTERIOR_COLOR_AT_FILTER, payload: selectedColor })
}

// SELECT CITY ON FILTER
// DESC     : SAVE SELECTED CITY ON STATE (ON FILTER MENU)
// METHOD   : -
export const selectCityAtFilter = (selectedCity) => (dispatch) => {
    dispatch({ type: SELECT_CITY_AT_FILTER, payload: selectedCity })
}

// SELECT SUB DISTRICT ON FILTER
// DESC     : SAVE SELECTED SUB DISTRICT ON STATE (ON FILTER MENU)
// METHOD   : -
export const selectSubDistrictAtFilter = (selectedSubDistrict) => (dispatch) => {
    dispatch({ type: SELECT_SUBDISTRICT_AT_FILTER, payload: selectedSubDistrict })
}

// SELECT INTERIOR COLOR ON DJUBLI CHECK
// DESC     : SAVE SELECTED INTERIOR COLOR ON STATE (ON BOOKING DJUBLI CHECK INSPECTION)
// METHOD   : -
export const selectExteriorColorAtDjubliCheck = (selectedColor) => (dispatch) => {
    dispatch({ type: SELECT_EXTERIOR_COLOR_AT_DJUBLI_CHECK, payload: selectedColor })
}

// SELECT CITY ON DJUBLI CHECK
// DESC     : SAVE SELECTED CITY ON STATE (ON BOOKING DJUBLI CHECK INSPECTION)
// METHOD   : -
export const selectCityAtDjubliCheck = (selectedCity) => (dispatch) => {
    dispatch({ type: SELECT_CITY_AT_DJUBLI_CHECK, payload: selectedCity })
}

// SET FOCUS FIELD
// DESC     : SAVE FOCUS FIELD NAME ON STATE (ON SELECT VARIANT)
// METHOD   : -
export const setFocusField = (fieldName) => (dispatch) => {
    dispatch({ type: SET_FOCUS_FIELD, payload: fieldName })
}

// ========= RESET SELECTED VALUE =============

// RESET MERK
// DESC     : RESET STATE MERK
// METHOD   : -
export const resetMerk = () => (dispatch) => {
    dispatch({ type: RESET_MERK })
}

// RESET GROUP MODEL
// DESC     : RESET STATE GROUP MODEL
// METHOD   : -
export const resetGroupModel = () => (dispatch) => {
    dispatch({ type: RESET_GROUP_MODEL })
}

// RESET MODEL
// DESC     : RESET STATE MODEL
// METHOD   : -
export const resetModel = () => (dispatch) => {
    dispatch({ type: RESET_MODEL })
}

// RESET MODEL YEAR
// DESC     : RESET STATE MODEL YEAR
// METHOD   : -
export const resetModelYear = () => (dispatch) => {
    dispatch({ type: RESET_MODEL_YEAR })
}

// RESET YEAR 2
// DESC     : RESET STATE YEAR 2
//            EX => 2021 - 2022 = MODELYEAR: 2021, ((MODELYEAR2: 2022))
// METHOD   : -
export const resetModelYear2 = () => (dispatch) => {
    dispatch({ type: RESET_MODEL_YEAR_2 })
}

// RESET EXTERIOR COLOR AT FILTER
// DESC     : RESET STATE EXTERIOR COLOR ON FILTER
// METHOD   : -
export const resetExteriorAtFilter = () => (dispatch) => {
    dispatch({ type: RESET_EXTERIOR_COLOR_AT_FILTER })
}

// RESET INTERIOR COLOR AT FILTER
// DESC     : RESET STATE INTERIOR COLOR ON FILTER
// METHOD   : -
export const resetInteriorAtFilter = () => (dispatch) => {
    dispatch({ type: RESET_INTERIOR_COLOR_AT_FILTER })
}

// RESET CITY AT FILTER
// DESC     : RESET STATE CITY ON FILTER
// METHOD   : -
export const resetCityAtFilter = () => (dispatch) => {
    dispatch({ type: RESET_CITY_AT_FILTER })
}

// RESET SUB DISTRICT AT FILTER
// DESC     : RESET STATE SUB DISTRICT ON FILTER
// METHOD   : -
export const resetSubDistrictAtFilter = () => (dispatch) => {
    dispatch({ type: RESET_SUBDISTRICT_AT_FILTER })
}

// RESET CITY AT DJUBLI CHECK
// DESC     : RESET STATE CITY ON BOOK DJUBLI CHECK
// METHOD   : -
export const resetCityAtDjubliCheck = () => (dispatch) => {
    dispatch({ type: RESET_CITY_AT_DJUBLI_CHECK })
}

// RESET EXTERIOR COLOR AT DJUBLI CHECK
// DESC     : RESET STATE EXTERIOR COLOR ON BOOK DJUBLI CHECK
// METHOD   : -
export const resetExteriorColorAtDjubliCheck = () => (dispatch) => {
    dispatch({ type: RESET_EXTERIOR_COLOR_AT_DJUBLI_CHECK })
}

// RESET ALL SELECTED VALUE 
// DESC     : RESET STATE ALL SELECTED VALUE 
// METHOD   : -
export const resetAllSelectedValue = () => (dispatch) => {
    dispatch({ type: RESET_ALL_SELECTED_VALUE })
}

// RESET FOCUS FIELD
// DESC     : RESET STATE FOCUS FIELD
// METHOD   : -
export const resetFocusField = () => (dispatch) => {
    dispatch({ type: RESET_FOCUS_FIELD })
}

// ========== GET LIST CAR VARIANT ===========

// LIST MERK
// DESC     : GET ALL BRAND LIST FROM DB
// METHOD   : GET
export const listMerk = () => async (dispatch) => {
    try {
        dispatch({ type: BRAND_LIST_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/brands?limit=50`)

        dispatch({ type: BRAND_LIST_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: BRAND_LIST_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// LIST GROUP MODEL
// DESC     : GET ALL GROUP MODEL FROM BRAND ID
// METHOD   : GET
export const listGroupModel = (idBrand, showCountModel = true) => async (dispatch) => {
    try {
        dispatch({ type: GROUP_MODEL_LIST_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/groupModels?brandId=${idBrand}&limit=50&by=name&sort=asc&showCountModel=${showCountModel}`)

        dispatch({ type: GROUP_MODEL_LIST_SUCCESS, payload: data.data })
        dispatch({ type: SET_FOCUS_FIELD, payload: 'GROUP_MODEL' })
    } catch (error) {
        dispatch({
            type: GROUP_MODEL_LIST_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// LIST MODEL YEAR
// DESC     : GET ALL GROUP MODEL LIST FROM MODEL ID
// METHOD   : GET
export const listModelYear = (idModel) => async (dispatch) => {
    try {
        dispatch({ type: MODEL_YEAR_LIST_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api-admin/modelYears/model/${idModel}?by=year&sort=asc`)

        dispatch({ type: MODEL_YEAR_LIST_SUCCESS, payload: data.data })
        dispatch({ type: SET_FOCUS_FIELD, payload: 'MODEL_YEAR' })
    } catch (error) {
        dispatch({
            type: MODEL_YEAR_LIST_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// LIST MODEL YEAR BY GROUP MODEL ID
// DESC     : GET ALL MODEL YEAR LIST FROM GROUP MODEL ID
// METHOD   : GET
export const listModelYearByGroupModelId = (groupModelId) => async (dispatch) => {
    try {
        dispatch({ type: MODEL_YEAR_LIST_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api-admin/modelYears/getModelYearByGroupModel/${groupModelId}`)

        dispatch({ type: MODEL_YEAR_LIST_SUCCESS, payload: data.data })
        dispatch({ type: SET_FOCUS_FIELD, payload: 'MODEL_YEAR' })
    } catch (error) {
        dispatch({
            type: MODEL_YEAR_LIST_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// LIST MODEL
// DESC     : GET ALL MODEL LIST FROM GROUP MODEL ID
// METHOD   : GET
export const listModel = (idGroupModel) => async (dispatch) => {
    try {
        dispatch({ type: MODEL_LIST_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/models?limit=50&groupModelId=${idGroupModel}&by=countResult&sort=desc`)

        dispatch({ type: MODEL_LIST_SUCCESS, payload: data.data })
    } catch (error) {
        dispatch({
            type: MODEL_LIST_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// LIST MODEL BY YEAR
// DESC     : GET ALL MODEL LIST FROM YEAR INPUT
// METHOD   : GET
export const listModelByYear = (year) => async (dispatch, getState) => {
    try {
        dispatch({ type: MODEL_LIST_BY_YEAR_REQUEST })

        const { selectedDropdown: { groupModel } } = getState()

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api-admin/models/getModelByYear?year=${year}&groupModelId=${groupModel.id}&limit=50`)

        dispatch({ type: MODEL_LIST_BY_YEAR_SUCCESS, payload: data.message })
        dispatch({ type: SET_FOCUS_FIELD, payload: 'MODEL' })
    } catch (error) {
        dispatch({
            type: MODEL_LIST_BY_YEAR_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// LIST MODEL BY RANGE YEAR
// DESC     : GET ALL MODEL LIST FROM RANGE YEAR
//            EX => YEARSTART: 2021, YEAREND: 2022 (2021 - 2022)
// METHOD   : GET
export const listModelByRangeYear = ({ yearStart, yearEnd = yearStart, focusToModel = false }) => async (dispatch, getState) => {
    try {
        dispatch({ type: MODEL_LIST_BY_RANGE_YEAR_REQUEST })

        const { selectedDropdown: { groupModel } } = getState()

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api-admin/models/getModelByYear?rangeYear[]=${yearStart}&rangeYear[]=${yearEnd}&groupModelId=${groupModel.id}&limit=50`)

        dispatch({ type: MODEL_LIST_BY_RANGE_YEAR_SUCCESS, payload: data.message })
        if(focusToModel) dispatch({ type: SET_FOCUS_FIELD, payload: 'MODEL' })
    } catch (error) {
        dispatch({
            type: MODEL_LIST_BY_YEAR_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// LIST YEAR OF USAGE
// DESC     : GET YEAR LIST FROM SELECTED YEAR TO CURRENT YEAR
//            EX => YEAR: 2013, (2013 - CURRENT YEAR)
// METHOD   : GET
export const listYearOfUsage = (year) => async (dispatch) => {
    try {
        dispatch({ type: YEAR_OF_USAGE_REQUEST })

        let years = []
        let currentYear = new Date().getFullYear()
        for (let index = parseInt(year); index <= currentYear; index++) {
            years.push({ id: index, year: index })
        }

        dispatch({ type: YEAR_OF_USAGE_SUCCESS, payload: years })
    } catch (error) {
        dispatch({
            type: YEAR_OF_USAGE_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// LIST COLOR
// DESC     : GET ALL COLOR LIST FROM DB
// METHOD   : GET
export const listColor = () => async (dispatch) => {
    try {
        dispatch({ type: COLOR_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/colors?by=countResult&sort=desc`)

        dispatch({ type: COLOR_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: COLOR_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// ============= RESET LIST CAR VARIANT =================

// LIST GROUP MODEL RESET
// DESC     : RESET LIST GROUP MODEL ON STATE
// METHOD   : -
export const listGroupModelReset = () => async (dispatch) => {
    dispatch({ type: GROUP_MODEL_LIST_RESET })
}

// LIST MODEL RESET
// DESC     : RESET LIST MODEL ON STATE
// METHOD   : -
export const listModelReset = () => async (dispatch) => {
    dispatch({ type: MODEL_LIST_RESET })
}

// LIST MODEL YEAR RESET
// DESC     : RESET LIST MODEL YEAR ON STATE
// METHOD   : -
export const listModelYearReset = () => async (dispatch) => {
    dispatch({ type: MODEL_YEAR_LIST_RESET })
}

// LIST YEAR OF USAGE RESET
// DESC     : RESET LIST YEAR OF USAGE ON STATE
// METHOD   : -
export const listYearOfUsageReset = () => async (dispatch) => {
    dispatch({ type: YEAR_OF_USAGE_RESET })
}

// ========= DANA TUNAI (GADAI BPKB) ==============

// NOT CLIPAN ERROR
// DESC     : SET STATUS NOT CLIPAN IF FETCHED DATA 'data.isClipan' = FALSE 
//            TO TRIGGER MESSAGE
// METHOD   : -
export const notClipanError = () => (dispatch) => {
    dispatch({ type: NOT_IS_CLIPAN })
}

// RESET CLIPAN ERROR
// DESC     : RESET CLIPAN ERROR STATUS ON STATE
// METHOD   : -
export const resetClipanError = () => (dispatch) => {
    dispatch({ type: RESET_CLIPAN_ERROR })
}

// RESET CLIPAN ERROR ON SIMULASI
// DESC     : RESET CLIPAN ERROR STATUS STATE ON SIMULASI PAGE
// METHOD   : -
export const resetClipanErrorOnSimulasi = () => (dispatch) => {
    dispatch({ type: RESET_CLIPAN_ERROR_ON_SIMULASI })
}

// =========== GET DETAILS ============

// CAR DETAIL
// DESC     : GET CAR DETAIL DATA BY CAR ID
// METHOD   : GET
export const carDetail = (carId) => async (dispatch) => {
    try {
        dispatch({ type: CAR_DETAILS_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/cars/${carId}`)

        dispatch({ type: CAR_DETAILS_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: CAR_DETAILS_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// CAR DETAIL ALT (ALTERNATIVE API FOR WEB)
// DESC     : GET CAR DETAIL DATA BY CAR ID
// METHOD   : GET
export const carDetailAlt = (carId) => async (dispatch) => {
    try {
        dispatch({ type: CAR_DETAILS_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/cars/web/${carId}`)

        dispatch({ type: CAR_DETAILS_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: CAR_DETAILS_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// RESET CAR DETAIL
// DESC     : RESET CAR DETAIL DATA ON STATE
// METHOD   : -
export const resetCarDetail = () => (dispatch) => {
    // dispatch({ type: CAR_DETAILS_SUCCESS_RESET })
    dispatch({ type: CAR_DETAILS_RESET })
}

// =========== GET CAR HERO ================

// GET CAR HERO
// DESC     : GET CAR HERO BY CAR ID
// METHOD   : GET
export const getCarHero = (carId) => async (dispatch) => {
    try {
        dispatch({ type: CAR_HERO_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}/api/cars/web/${carId}`)

        dispatch({ type: CAR_HERO_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: CAR_HERO_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}

// CAR HERO RESET
// DESC     : RESET CAR HERO DATA ON STATE
// METHOD   : -
export const carHeroReset = () => async (dispatch) => {
    dispatch({ type: CAR_HERO_RESET })
}

// SELECT CATEGORY HERO
// DESC     : SAVE SELECTED CATEGORY HERO ON STATE
//            (((THIS IS UNUSED)))
// METHOD   : -
export const selectCategoryHero = (selectedCategory) => (dispatch) => {
    dispatch({ type: SELECT_HERO_CATEGORY, payload: selectedCategory })
}

// RESET HERO CATEGORY
// DESC     : RESET HERO CATEGORY ON STATE
// METHOD   : -
export const resetHeroCategory = () => (dispatch) => {
    dispatch({ type: RESET_HERO_CATEGORY })
}

// GET SEARCH COUNT
// DESC     : GET LISTING TOTAL ON SELECTED VARIANT
// METHOD   : GET
export const getSearchCount = ({ 
    brandId = '',
    groupModelId = '',
    modelId = '',
    exteriorColorId = '',
    minYear = '',
    maxYear = '',
    minKm = '',
    maxKm = '',
    cityId = '',
    condition = '',
    sellType = ''
 }) => async (dispatch) => {
    try {
        dispatch({ type: SEARCH_CAR_AMOUNT_REQUEST })

        const url = new URL(`${process.env.REACT_APP_API_PRODUCTION}/api/modelYears/countAllNewRefactor`)

        if(brandId !== "") url.searchParams.set('brandId', brandId)
        if(groupModelId !== "") url.searchParams.set('groupModelId', groupModelId)
        if(modelId !== "") url.searchParams.set('modelId', modelId)
        if(exteriorColorId !== "") url.searchParams.set('exteriorColorId', exteriorColorId)
        if(minYear !== "") url.searchParams.set('minYear', minYear)
        if(maxYear !== "") url.searchParams.set('maxYear', maxYear)
        if(minKm !== "") url.searchParams.set('minKm', minKm)
        if(maxKm !== "") url.searchParams.set('maxKm', maxKm)
        if(cityId !== "") url.searchParams.set('cityId', cityId)
        if(condition !== "") url.searchParams.set('condition', condition)
        if(sellType !== "") url.searchParams.set('sellType', sellType)

        // console.log("url", url)
        const { data } = await axios.get(url)

        dispatch({ type: SEARCH_CAR_AMOUNT_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: SEARCH_CAR_AMOUNT_FAIL,
            payload: error.response && error.response.data.errors 
                ? error.response.data.errors 
                : error.message
        })
    }
}