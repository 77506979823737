import React, { useState } from 'react';
import {  useNavigate } from "react-router-dom";
import { Box, Typography, Divider, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { priceFormat } from '../../constants/djubliKreditConstant';
import EditIcon from '@mui/icons-material/Edit';

/**
 * CarDetailSection Component
 * Displays detailed information about a car including its image and specifications
 * 
 * @param {string} merk - Car brand/manufacturer
 * @param {string} groupModel - Car model group
 * @param {string} modelYear - Year of the car model
 * @param {string} model - Specific car model
 * @param {number} kilometer - Car's mileage in kilometers
 * @param {string} color - Car color
 * @param {string} nopol - License plate number
 * @param {string} mobilImage - URL of the car image
 */
const CarDetailSection = ({
  merk,
  groupModel,
  modelYear,
  model,
  kilometer,
  color,
  colorHex,
  nopol,
  pictureUrl,
  mobilImage
}) => {
  const navigate = useNavigate();
  // Add state for modal
  const [openModal, setOpenModal] = useState(false);

  /**
   * Navigation handler
   */
  const handleBack = () => {
    navigate("/cek-harga-jual-mobil");
  };

  // Add modal handlers
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirm = () => {
    handleBack();
    handleCloseModal();
  };

  // Array of car details to be displayed
  const details = [
    { label: 'Kilometer', value: ()=>`${priceFormat(kilometer)} km` },
    { label: 'Nomor Polisi', value: ()=>nopol },
    { label: 'Warna', value: ()=>{
      return <>
        {colorHex ? <Box sx={{ width: 15, height: 15, backgroundColor: `#${colorHex}`, borderRadius: "50%", marginRight: "5px", border: '1px solid #E0E3E7' }} /> : null}
        {color}
      </>
    } },
  ];

  return (
    <>
      <Paper 
        elevation={0} 
        sx={{ 
          width: 'inherit', 
          height: '100%', 
          p: 0, 
          borderRadius: 2, 
          border: '1px solid #D0D6DC' 
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            width: "inherit",
            height: 'inherit',
            borderRadius: 2,
            overflow: 'hidden',
            bgcolor: '#EAEAEA',
          }}
        >
          {/* Car Title Section with Gradient Background */}
          <Box 
            display="flex"
            justifyContent="space-between"
            sx={{
              background: 'linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.2) 100%)',
              p: 1.5,
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h5" fontWeight="bold" color="white">
                {`${merk} ${groupModel} ${modelYear}`}
              </Typography>
              <Typography variant="subtitle1" color="white">
                {model}
              </Typography>
            </Box>
            <Box>
              <Button 
                size="small" 
                onClick={handleOpenModal} 
                startIcon={<EditIcon />} 
                sx={{ color: 'white', fontWeight: 'bold' }}
              >
                Ubah
              </Button>
            </Box>
          </Box>

          <Box sx={{ p: 1, display: {xs: 'none', md: 'block'}}}>
            <img 
              src={pictureUrl || mobilImage} 
              alt="Car" 
              onError={(e) => {
                e.target.src = mobilImage;
              }}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
            />
          </Box>
          {/* Details Section */}
          <Box sx={{ p: 1.5, pt: 0 }}>
            <Box
              sx={{
                width: 'inherit',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: {xs: 'flex-start'},
                flexDirection: {xs: 'column'},
                p: 1,
                background: 'linear-gradient(90deg, rgba(255,255,255,0.9) 30%, rgba(255,255,255,0.7) 80%)',
                border: '1px solid #e5e7e9',
                borderRadius: 2,
              }}
            >
              {details.map((detail, index) => (
                <React.Fragment key={index}>
                  <Box 
                    display="flex" 
                    gap={0.7} 
                    sx={{ 
                      flex: 1, 
                      width: {xs: 'fill-available'},
                      alignItems: 'flex-start',
                      justifyContent: {xs: 'space-between'},
                      flexDirection: {xs: 'row'},
                      px: {xs: 1, },
                      py: {xs: 0.7,}
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      {detail.label}
                    </Typography>
                    <Typography variant="body1" fontWeight="500" display="flex" alignItems="center">
                      {detail.value()}
                    </Typography>
                  </Box>
                  {index < details.length - 1 && (
                    <Divider 
                      orientation="vertical" 
                      flexItem 
                      sx={{ 
                        mx: {xs: 1,}, 
                        borderWidth: 1, 
                        borderColor: 'green' 
                      }} 
                    />
                  )}
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>

      {/* Add Confirmation Modal */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent>
          <Typography>Apakah anda akan merubah detail kendaraan?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Tidak</Button>
          <Button onClick={handleConfirm} autoFocus>Ya</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CarDetailSection;
