import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
// MATERIAL UI
import { Box, Button, Dialog, Divider, Grid, IconButton, InputAdornment, Popover, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
// ICONS
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
// CONSTANTS
import { fontPreset } from '../../constants/styling/fontStyleConstant'
import { backgroundColor, colorPalette, neutralColor } from '../../constants/styling/colorPalette'
import { captureEventGAWithParams, captureEventPixel, captureEventToDB } from '../../constants/capture_event/captureEventConstant'
import { scrollToElem } from '../../constants/utils/scrollActions'
import { BARU_CONDITION, BEKAS_CONDITION } from '../../constants/utils/conditionName'
// ACTIONS
import { getSearchCount, listModelByRangeYear, listModelByYear, listModelReset, resetModel, resetModelYear, resetModelYear2, selectModelYear, selectModelYear2, setFocusField } from '../../actions/carActions'
import { getLiquidationEstimation } from '../../actions/danaTunaiActions'
import { getKkbEstimation, resetKkbEstimation } from '../../actions/promoActions'

const DANA_TUNAI = 'Dana Tunai'
const SEARCH_CAR = 'Search Car'
const KKB_SECTION = 'KKB'

const SelectModelYearMenu = ({ 
    yearList = [],
    selectedVal,
    label,
    disabled,
    category = SEARCH_CAR,
    page = '',
    sectionId = '',
    condition = "semua",
    isSingleYear = false,
    bottonStyleProps = {},
    sellType = ""
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
  const downSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const location = useLocation()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null)
  const [searchValue, setSearchValue] = useState("")
  const [yearOptions, setYearOptions] = useState(yearList)
  const [openDialog, setOpenDialog] = useState(false)

  const selectedDropdown = useSelector((state) => state.selectedDropdown)

//   const handleOpenDialog = () => { 
//     if(downMediumScreen) scrollToElem({ elemName: "MODEL_YEAR", options: { duration: 500, smooth: true, offset: -76 } })
//     setOpenDialog(true)
//   }
  const handleCloseDialog = () => { 
    window.history.back();
    // navigate(`${location.pathname}${location.search}`, { replace: true })
    // setOpenDialog(false) 
  }

  const handleClose = () => { setAnchorEl(null) }

  const handleClick = (event) => { 
    // if(downMediumScreen) handleOpenDialog()
    // if (downMediumScreen) {
    //     if(sectionId === "Model Year 1") navigate(`${location.pathname}#open-modelYear`)
    //     else if(sectionId === "Model Year 2") navigate(`${location.pathname}#open-modelYear2`)
    // }
    if (downMediumScreen) {
        if(sectionId === "Model Year 1") window.location.hash = "#open-modelYear";
        else if(sectionId === "Model Year 2") window.location.hash = "#open-modelYear2";
    } else {
        scrollToElem({ elemName: "MODEL_YEAR", options: { duration: 500, smooth: true, offset: -132 } })
        setAnchorEl(event.currentTarget)
    }
  }

  const handleClickWhatsApp = () => {
    if (nonAdmin()) {
        // SAVE TO DB
        captureEventGAWithParams({
          eventName: "Hubungi DjuBli - Model Year Tidak Ada",
          params: { category: category, page: page },
        });
        // SAVE TO DB
        captureEventToDB({
          event: { category: category, eventName: "Hubungi DjuBli - Model Year Tidak Ada", page: page },
        });
        // SAVE TO PIXEL
        captureEventPixel({
          eventName: `Hubungi DjuBli - Model Year Tidak Ada`,
          params: { category: category, page: page },
        });
      }

    window.open(`https://wa.me/62895808993131?text=Hai DjuBli! Saya inign mencari mobil *${selectedDropdown.brands?.name ?? ""} ${selectedDropdown.groupModel?.name ?? ""} ${searchValue}* pada *${page}*%0A%0A Selengkapnya ada di ${window.location.href}`)
  }

  const userLogin = useSelector((state) => state.userLogin)
  const nonAdmin = () => {
    if(userLogin.userInfo?.id !== 0) return true
    else return false
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const buttonStyle = { 
    height: "100%",
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    // padding: downMediumScreen && selectedVal === "" ? '14px 12px' : '6px 12px',
    padding: selectedVal === "" ? '14px 12px' : '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: 'white',
    borderColor: neutralColor.neutral50,
    color: neutralColor.neutral100,
    display: "flex",
    justifyContent: "space-between",
    '&:hover': {
      backgroundColor: backgroundColor,
      borderColor: colorPalette.primary,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: backgroundColor,
      borderColor: colorPalette.primary,
    },
    '&:focus': { },
    '&:disabled': { 
        backgroundColor: neutralColor.neutral10,
        borderColor: neutralColor.neutral10,
    },
  }

  const selectModelYearValue = (selectedModelYear) => {
    if(selectedVal.model !== null) {
        // RESET SELECTED VALUE
        dispatch(resetModel())
        // RESET LIST VALUE
        dispatch(listModelReset())
    }

    if(nonAdmin()) {
        // SAVE TO ANALYTICS
        captureEventGAWithParams({
            eventName: "Select Model Year",
            params: { category: category, page: page }
        })
        // SAVE TO DB
        captureEventToDB({
            event: { category: category, eventName: "Select Model Year", page: page }
        })
        // SAVE TO PIXEL
        captureEventPixel({
            eventName: "Select Model Year",
            params: { category: category, page: page }
        })
    }

    // GET ESTIMATION LIQUIDATION (DANA TUNAI) / COUNT TOTAL CARS (SEARCH CAR)
    if(category === DANA_TUNAI) {
        dispatch(
            getLiquidationEstimation({ 
                modelYearId: { year: selectedModelYear, groupModelId: selectedDropdown.groupModel?.id ?? '' }
            })
        )
    } else if(category === "Cari Mobil") {
        if (sectionId === "Model Year 1"){
            const start = selectedModelYear === "Semua" ? yearList[yearList.length - 1] : selectedModelYear
            const end = selectedDropdown.modelYear2 === "Semua" ? yearList[1] : selectedDropdown.modelYear2

            let params = {}
            if(condition !== "semua") {
                params = {
                    brandId: selectedDropdown.brands.id,
                    groupModelId: selectedDropdown.groupModel.id,
                    minYear: start,
                    condition: condition === 'bekas' ? BEKAS_CONDITION : BARU_CONDITION,
                    sellType
                }
            } else {
                params = {
                    brandId: selectedDropdown.brands.id,
                    groupModelId: selectedDropdown.groupModel.id,
                    minYear: start,
                    sellType
                }
            }

            // console.log({ ...params, maxYear: start > end ? start : end })

            if(selectedDropdown.modelYear2 !== null) dispatch(getSearchCount({ ...params, maxYear: start > end ? start : end }))
            else dispatch(getSearchCount(params))

        } else if (sectionId === "Model Year 2") {
            const start = selectedDropdown.modelYear === "Semua" || selectedDropdown.modelYear === null ? yearList[yearList.length - 1] : selectedDropdown.modelYear
            const end = selectedModelYear === "Semua" ? yearList[1] : selectedModelYear

            let params = {}
            if(condition !== "semua") {
                params = {
                    brandId: selectedDropdown.brands.id,
                    groupModelId: selectedDropdown.groupModel.id,
                    minYear: start,
                    condition: condition === 'bekas' ? BEKAS_CONDITION : BARU_CONDITION,
                    sellType
                }
            } else {
                params = {
                    brandId: selectedDropdown.brands.id,
                    groupModelId: selectedDropdown.groupModel.id,
                    minYear: start,
                    sellType
                }
            }

            dispatch(
                getSearchCount(selectedModelYear === "Semua" ? params : { ...params, maxYear: end } )
            )
        }
    }

    // SET VALUE DI SELECTED DROPDOWN
    if(sectionId === "Model Year 1") {
        dispatch(selectModelYear(selectedModelYear))
        if (isSingleYear) {
            dispatch(selectModelYear2(selectedModelYear))
        } else if(selectedDropdown.modelYear2 === null || selectedModelYear !== "Semua") {
            if(selectedModelYear > selectedDropdown.modelYear2) {
                dispatch(selectModelYear2(selectedModelYear))
            }
        }
    } else if(sectionId === "Model Year 2") {
        if(selectedDropdown.modelYear === null) dispatch(selectModelYear("Semua"))
        dispatch(selectModelYear2(selectedModelYear))
    }

    // GET MODELS
    if (isSingleYear) {
        dispatch(listModelByYear(selectedModelYear))
    }
    else if(category === DANA_TUNAI || category === "KKB") {
        dispatch(
            listModelByRangeYear(
                { yearStart: selectedModelYear,
                  yearEnd: selectedModelYear,
                  focusToModel: "Model" }
            )
        )
    } else if (category === "Cari Mobil") {
        if(sectionId === "Model Year 1") {
            const start = selectedModelYear === "Semua" ? yearList[yearList.length - 1] : selectedModelYear
            const end = selectedDropdown.modelYear2 === "Semua" ? yearList[1] : selectedDropdown.modelYear2
            
            dispatch(
                listModelByRangeYear(
                    { 
                        yearStart: start,
                        yearEnd: selectedDropdown.modelYear2 === null || start > selectedDropdown.modelYear2 ? start : end,
                        focusToModel: category === DANA_TUNAI || sectionId === "Model Year 2"
                    }
                )
            )
        } else if(sectionId === "Model Year 2") {
            const start = selectedDropdown.modelYear === "Semua" ? yearList[yearList.length - 1] : selectedDropdown.modelYear
            const end = selectedModelYear === "Semua" ? yearList[1] : selectedModelYear
    
            dispatch(
                listModelByRangeYear({ 
                    yearStart: start,
                    yearEnd: end,
                    focusToModel: category === DANA_TUNAI || sectionId === "Model Year 2"
                })
            )
        }
    }

    // GET ESTIMATION
    if(category === KKB_SECTION) {
        dispatch(getKkbEstimation({ 
            type: "years",
            years: selectedModelYear,
            groupModelId: selectedDropdown.groupModel.id
        }))
    }

    // SET NEXT FOCUS
    if(category === DANA_TUNAI) dispatch(setFocusField('MODEL')) 
    else dispatch(setFocusField('MODEL_YEAR_2'))

    // CLOSE DIALOG / CLOSE POPOVER
    if(downMediumScreen) handleCloseDialog()
    else handleClose()
  }

  useEffect(() => {
    setYearOptions(yearList)
  }, [yearList])

//   useEffect(() => {
//     if(downMediumScreen && !disabled) {
//       if(location.hash === "#open-modelYear" && sectionId === "Model Year 1") handleOpenDialog()
//       else if(location.hash === "#open-modelYear2" && sectionId === "Model Year 2") handleOpenDialog()
//       else setOpenDialog(false)
//     }
//   }, [location.hash])

  useEffect(() => {
    const onHashChange = () => {
        if(window.location.hash === "#open-modelYear" && sectionId === "Model Year 1") setOpenDialog(window.location.hash === "#open-modelYear");
        else if(window.location.hash === "#open-modelYear2" && sectionId === "Model Year 2") setOpenDialog(window.location.hash === "#open-modelYear2");
        else setOpenDialog(false)
    }
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchSection = () => (
    <>
        <Box className='groupModel__search'
            sx={{ padding: downMediumScreen ? "1.5rem 1.5rem 1rem" : "1.5rem" }}
        >
            <TextField
                placeholder={"Cari Tahun"}
                value={searchValue}
                onChange={(e) => {
                    setSearchValue(e.target.value)

                    const searchResult = yearList.filter((year) => {
                        if (e.target.value === "") return year
                        return year.includes(e.target.value)
                    })
                    setYearOptions(searchResult)
                }}
                size={"regular"}
                variant={"outlined"}
                InputProps={{ 
                    endAdornment: 
                        <InputAdornment
                            position="end"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {}}
                        >
                            <SearchIcon height={18} width={18} />
                        </InputAdornment>
                }}
                fullWidth
            />
        </Box>

        <Box className='root__brands' 
            sx={{ 
                display: "flex",
                flexDirection: "column",
                rowGap: "16px",
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: downMediumScreen ? "calc(100vh - 146px - 1.7rem)" : "320px",
                padding: "0 1.5rem 1.5rem"
            }}
        >
            { searchValue === "" || yearOptions.length !== 0 ? 
                <Box className='alphabetical__year'>
                    <Typography variant='body1' sx={{ ...fontPreset.body16Bold, marginBottom: "8px" }}>
                        Pilih Tahun
                    </Typography>
                    <Grid container spacing={"8px"}>
                        { yearOptions.map((year, index) => (
                            <Grid key={`${year}__${index + 1}`} item xs={4}>
                                <Box className='year__item'
                                    sx={{ 
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        transition: "all .1s ease",
                                        borderRadius: "8px",
                                        color: selectedVal === year ? neutralColor.neutral10 : "",
                                        bgcolor: selectedVal === year ? colorPalette.primary : "",
                                        padding: "4px",
                                        "&:hover": { 
                                            cursor: "pointer",
                                            color: neutralColor.neutral10,
                                            bgcolor: colorPalette.primary
                                        }
                                    }}
                                    onClick={() => selectModelYearValue(year)}
                                > 
                                    <Typography
                                        variant='body2'
                                        sx={{ ...fontPreset.body14Reg }}
                                    >
                                        {year}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box> :
                NotFoundCallDjuBliElement({ })
            }

            { yearOptions.length !== 0 && 
                <>
                    <Divider />
                    { NotFoundCallDjuBliElement({ title: "Tidak Menemukan Pilihan?" }) }
                </>
            }   
        </Box>
    </>
  )

  const NotFoundCallDjuBliElement = ({ title = "Hasil pencarian tidak ditemukan.", body = "Coba hubungi DjuBli" }) => {
    return (
      <Box className="not__found__result"
        sx={{ 
          padding: "16px",
          display: "flex",
          alignItems: "center",
          rowGap: "12px",
          flexDirection: "column"
        }}
      >
        <Box>
          <Typography
            variant="body1"
            sx={downSmallScreen ? fontPreset.body14Bold : fontPreset.body16Bold}
            align="center"
          >
            { title }
          </Typography>
          <Typography
            variant="body1"
            sx={downSmallScreen ? fontPreset.body14Reg : fontPreset.body16Reg}
            align="center"
          >
            { body }
          </Typography>
        </Box>

        <Button
          variant="contained"
          onClick={handleClickWhatsApp}
          endIcon={<WhatsAppIcon />}
          size={downSmallScreen ? "small" : "regular"}
        >
          Hubungi DjuBli
        </Button>
      </Box>
    )
  }

  const handleClearSelectedVal = () => {
    let searchState = {}

    if(category === DANA_TUNAI) {
        dispatch(getLiquidationEstimation({ groupModelId: selectedDropdown.groupModel.id }))
    } else if(category === "Cari Mobil") {
        if(condition !== "semua") {
            searchState = {
                brandId: selectedDropdown.brands.id,
                groupModelId: selectedDropdown.groupModel.id,
                condition: condition === 'bekas' ? BEKAS_CONDITION : BARU_CONDITION,
                sellType
            }
        } else {
            searchState = {
                brandId: selectedDropdown.brands.id,
                groupModelId: selectedDropdown.groupModel.id,
                sellType
            }
        }
    } else if(category === "KKB") {
        dispatch(resetKkbEstimation())
    }

    if(sectionId === "Model Year 1") {
        dispatch(resetModelYear())
        dispatch(resetModelYear2())
        // UPDATE SEARCH COUNT
        dispatch(getSearchCount(searchState))
    } else if(sectionId === "Model Year 2") {
        dispatch(resetModelYear2())
        // UPDATE SEARCH COUNT
        dispatch(
            getSearchCount({ 
                ...searchState, 
                minYear: selectedDropdown.modelYear === "Semua" || selectedDropdown.modelYear === null ? 
                    yearList[yearList.length - 1] : 
                    selectedDropdown.modelYear 
            })
        )
    }

    if(selectedVal.model !== null) {
        // RESET SELECTED VALUE
        dispatch(resetModel())
        // RESET LIST VALUE
        dispatch(listModelReset())
    }
  }

  return (
    <div style={{ height: "100%", display: "flex", columnGap: "8px", alignItems: "center" }}>
        <Button 
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            fullWidth
            disableRipple
            sx={{...buttonStyle, ...bottonStyleProps}}
            endIcon={
                <div className='clear__button'
                    style={{ display: "flex", alignItems: "center", columnGap: "2px", padding: "0", margin: "0" }}
                >
                    { selectedVal !== "" &&
                        <Box 
                            sx={{ 
                                padding: "0",
                                margin: "0",
                                borderRadius: "50%",
                                width: "25px",
                                height: "25px",
                                transition: "background-color .1s",
                                "&:hover": { bgcolor: neutralColor.neutral30 }
                            }}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleClearSelectedVal()
                            }}
                        >
                            <CloseIcon fontSize="small" sx={{ color: neutralColor.neutral90 }} />
                        </Box>
                    }
                    { open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon/> }
                </div>
            }
            disabled={disabled}
        >
            <Box>
                <Typography 
                    variant='body1'
                    sx={selectedVal !== "" ? { ...fontPreset.body12Reg } : { ...fontPreset.body16Reg }}
                    align="left"
                >
                    { label }
                </Typography>
                <Typography 
                    variant='body2'
                    sx={downMediumScreen ? { ...fontPreset.body16Bold } : { ...fontPreset.body18Bold }}
                    align="left"
                >
                    { selectedVal !== "" ? selectedVal : "" }
                </Typography>
            </Box>
        </Button>

        { downMediumScreen ? 
            <Dialog
                fullScreen
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <Box className='header__dialog'
                    sx={{ display: "flex", padding: "8px 16px", alignItems: "center" }}
                >
                    <Typography
                        variant='body1'
                        sx={{ ...fontPreset.body16Bold, flexGrow: 1 }}
                        align="center"
                    >
                        Tahun
                    </Typography>

                    <IconButton
                        onClick={handleCloseDialog}
                        size="small"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />

                <Box className='search__section'>
                    { searchSection() }
                </Box>
            </Dialog> :
          // DESKTOP SCREEN
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box className='root__search' 
                    sx={{ maxWidth: "540px" }}
                >
                    { searchSection() }
                </Box>
            </Popover>
        }
    </div>
  )
}

SelectModelYearMenu.propTypes = {
    yearList: PropTypes.array,
    selectedVal: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    category: PropTypes.string,
    page: PropTypes.string,
}

export default SelectModelYearMenu