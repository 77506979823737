import React from "react";
import { useSelector } from "react-redux";
// MATERIAL UI
import { Grid } from "@mui/material";
// COMPONENTS
import SelectBrandMenu from "../../components/search_car/SelectBrandMenu";
import SelectGroupModelMenu from "../../components/search_car/SelectGroupModelMenu";
import SelectModelYearMenu from "../../components/search_car/SelectModelYearMenu";
import SelectModelMenu from "../../components/search_car/SelectModelMenu";
import { neutralColor } from "../../constants/styling/colorPalette";

const ScrapingVariantMenu = ({ sellType = "", availableCarModels = [] }) => {

  // Redux state selectors
  const brandList = useSelector((state) => state.brandList);
  const groupModelList = useSelector((state) => state.groupModelList);
  const modelList = useSelector((state) => state.modelList);
  const modelYearList = useSelector((state) => state.modelYearList);
  const selectedCarValue = useSelector((state) => state.selectedDropdown);

  // Destructured values from redux state
  const { groupModels } = groupModelList;
  const { models } = modelList;
  const { modelYears } = modelYearList;

  // filter group model based on availableCarModels
  const availableGroupModelList = availableCarModels?.[selectedCarValue?.brands?.name?.toUpperCase()] || {};
  const availableGroupModel = groupModels.filter(groupModel => {
    return Object.prototype.hasOwnProperty.call(availableGroupModelList, groupModel.name?.toUpperCase());
  });


  // filter model year based on availableCarModels
  const availableModelYearList = availableCarModels?.[selectedCarValue?.brands?.name?.toUpperCase()]?.[selectedCarValue?.groupModel?.name?.toUpperCase()] || {};
  const availableModelYear = modelYears.filter(modelYear => {
    return Object.prototype.hasOwnProperty.call(availableModelYearList, modelYear); 
  });

  // filter models based on availableCarModels
  const availableModelList = availableCarModels?.[selectedCarValue?.brands?.name?.toUpperCase()]?.[selectedCarValue?.groupModel?.name?.toUpperCase()]?.[selectedCarValue?.modelYear] || {};
  const availableModels = models.filter(model => {
    return Object.prototype.hasOwnProperty.call(availableModelList, model.name?.toUpperCase());
  });


  return (
    <>
      {/* Brand Selection */}
      <Grid item xs={12} sm={6} id="MERK">
        <SelectBrandMenu
          selectedVal={selectedCarValue.brands !== null ? selectedCarValue.brands : ""}
          label={brandList.loading ? "Mengambil Data..." : "Merek"}
          brands={
            brandList.brands.length !== 0
              ? brandList.brands.data.sort((a, b) => a.name.localeCompare(b.name))
              : []
          }
          disabled={brandList.loading ?? false}
          category="Cek Harga Mobil"
          page="Home Cek Harga Mobil"
          sellType={sellType}
          isShowCountResult={false}
        />
      </Grid>

      {/* Group Model Selection */}
      <Grid item xs={12} sm={6} id="GROUP_MODEL">
        <SelectGroupModelMenu
          selectedVal={selectedCarValue.groupModel !== null ? selectedCarValue.groupModel : ""}
          label={groupModelList.loading ? "Mengambil Data..." : "Group Model"}
          groupModel={availableGroupModel.length !== 0 ? availableGroupModel : []}
          disabled={groupModelList.loading || selectedCarValue.disableGroupModel}
          category="Cek Harga Mobil"
          page="Home Cek Harga Mobil"
          sellType={sellType}
          isShowCountResult={false}
          bottonStyleProps={{
            '&:disabled': { 
                backgroundColor: neutralColor.neutral10,
                borderColor: neutralColor.neutral30,
            }
          }}
        />
      </Grid>

      {/* Model Year Selection */}
      <Grid item xs={12} sm={6} id="MODEL_YEAR">
        <SelectModelYearMenu
          selectedVal={selectedCarValue.modelYear ?? ""}
          label={modelYearList.loading ? "Mengambil Data..." : "Tahun"}
          yearList={availableModelYear.length !== 0 ? [...availableModelYear.sort((a, b) => b - a)] : []}
          disabled={modelYearList.loading || selectedCarValue.disableModelYear}
          category="Cek Harga Mobil"
          page="Home Cek Harga Mobil"
          sectionId="Model Year 1"
          isSingleYear={true}
          sellType={sellType}
          bottonStyleProps={{
            '&:disabled': { 
                backgroundColor: neutralColor.neutral10,
                borderColor: neutralColor.neutral30,
            }
          }}
        />
      </Grid>

      {/* Model Selection */}
      <Grid item xs={12} sm={6} id="MODEL">
        <SelectModelMenu
          selectedVal={selectedCarValue.model !== null ? selectedCarValue.model : ""}
          label={modelList.loading ? "Mengambil Data..." : "Model"}
          modelList={availableModels.length !== 0 ? availableModels : []}
          disabled={modelList.loading || selectedCarValue.disableModel}
          category="Cek Harga Mobil"
          page="Home Cek Harga Mobil"
          sellType={sellType}
          isShowCountResult={false}
          bottonStyleProps={{
            '&:disabled': { 
                backgroundColor: neutralColor.neutral10,
                borderColor: neutralColor.neutral30,
            }
          }}
        />
      </Grid>
    </>
  );
};

export default ScrapingVariantMenu;
