import React from 'react';
import { Typography, Skeleton } from '@mui/material';

/**
 * SkeletonText Component
 * Reusable component that shows either a skeleton loader or text content
 * 
 * @param {boolean} isLoading - Loading state
 * @param {string} variant - MUI Typography variant
 * @param {string} text - Text to display when not loading
 * @param {object} typographyProps - Additional props for Typography component
 * @param {object} skeletonProps - Additional props for Skeleton component
 */
const SkeletonText = ({ 
  isLoading, 
  variant = "body1",
  text,
  typographyProps = {},
  skeletonProps = {},
  ...props
}) => {
  const defaultSkeletonProps = {
    variant: "rounded",
    width: "100%",
    height: 24,
    sx: { ...skeletonProps.sx },
    ...props
  };

  return isLoading ? (
    <Skeleton {...defaultSkeletonProps} {...skeletonProps} />
  ) : (
    <Typography variant={variant} {...typographyProps}>
      {text}
    </Typography>
  );
};

export default SkeletonText; 