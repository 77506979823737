import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
// MATERIAL UI
import { Box, Button, Dialog, Divider, IconButton, InputAdornment, Popover, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
// ICONS
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
// CONSTANTS
import { fontPreset } from '../../constants/styling/fontStyleConstant'
import { backgroundColor, colorPalette, neutralColor } from '../../constants/styling/colorPalette'
import { captureEventGAWithParams, captureEventPixel, captureEventToDB } from '../../constants/capture_event/captureEventConstant'
import { scrollToElem } from '../../constants/utils/scrollActions'
import { priceFormat } from '../../constants/djubliKreditConstant'
import { BARU_CONDITION, BEKAS_CONDITION } from '../../constants/utils/conditionName'
// ACTIONS
import { getSearchCount, resetModel, selectModel } from '../../actions/carActions'
import { getLiquidationEstimation } from '../../actions/danaTunaiActions'
import { getKkbEstimation } from '../../actions/promoActions'

const SelectModelMenu = ({ 
    modelList = [],
    selectedVal,
    label,
    disabled,
    category = '',
    page = '',
    condition = "semua",
    isShowCountResult = true,
    bottonStyleProps = {},
    sellType = ""
}) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const downMediumScreen = useMediaQuery(theme.breakpoints.down("md"))
    const downSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const location = useLocation()
    const navigate = useNavigate()
  
    const [anchorEl, setAnchorEl] = useState(null)
    const [searchValue, setSearchValue] = useState("")
    const [modelOptions, setModelOptions] = useState(modelList)
    const [openDialog, setOpenDialog] = useState(false)

    const selectedDropdown = useSelector((state) => state.selectedDropdown)
  
    // const handleOpenDialog = () => {
    //     if(downMediumScreen) scrollToElem({ elemName: "MODEL", options: { duration: 500, smooth: true, offset: -76 } })
    //     setOpenDialog(true)
    // }
    const handleCloseDialog = () => { 
        window.history.back();
        // navigate(`${location.pathname}${location.search}`, { replace: true })
        // setOpenDialog(false)
    }
  
    const handleClose = () => { setAnchorEl(null) }
  
    const handleClick = (event) => { 
    //   if(downMediumScreen) handleOpenDialog()
    //   if (downMediumScreen) navigate(`${location.pathname}#open-model`)
      if (downMediumScreen) window.location.hash = "#open-model";
      else {
        scrollToElem({ elemName: "MODEL", options: { duration: 500, smooth: true, offset: -132 } })
        setAnchorEl(event.currentTarget)
      }
    }

    const handleClickWhatsApp = () => {
        if (nonAdmin()) {
            // SAVE TO DB
            captureEventGAWithParams({
              eventName: "Hubungi DjuBli - Model Tidak Ada",
              params: { category: category, page: page },
            });
            // SAVE TO DB
            captureEventToDB({
              event: { category: category, eventName: "Hubungi DjuBli - Model Tidak Ada", page: page },
            });
            // SAVE TO PIXEL
            captureEventPixel({
              eventName: `Hubungi DjuBli - Model Tidak Ada`,
              params: { category: category, page: page },
            });
        }

        window.open(`https://wa.me/62895808993131?text=Hai DjuBli! Saya inign mencari mobil *${selectedDropdown.brands?.name ?? ""} ${selectedDropdown.groupModel?.name ?? ""} ${selectedDropdown.modelYear1 ?? ""} ${searchValue}* pada *${page}*%0A%0A Selengkapnya ada di ${window.location.href}`)
    }

    const userLogin = useSelector((state) => state.userLogin)
    const nonAdmin = () => {
      if(userLogin.userInfo?.id !== 0) return true
      else return false
    }
  
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
  
    useEffect(() => {
      setModelOptions(modelList)
    }, [modelList])

    // useEffect(() => {
    //     if(downMediumScreen && !disabled) {
    //       if(location.hash === "#open-model") handleOpenDialog()
    //       else setOpenDialog(false)
    //     }
    // }, [location.hash])

    useEffect(() => {
        const onHashChange = () => setOpenDialog(window.location.hash === "#open-model");
        window.addEventListener("hashchange", onHashChange);
        return () => window.removeEventListener("hashchange", onHashChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    const buttonStyle = { 
      height: "100%",
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      //   padding: downMediumScreen && selectedVal === "" ? '14px 12px' : '6px 12px',
      padding: selectedVal === "" ? '14px 12px' : '6px 12px',
      border: '1px solid',
      lineHeight: 1.5,
      backgroundColor: 'white',
      borderColor: neutralColor.neutral50,
      color: neutralColor.neutral100,
      display: "flex",
      justifyContent: "space-between",
      '&:hover': {
        backgroundColor: backgroundColor,
        borderColor: colorPalette.primary,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: backgroundColor,
        borderColor: colorPalette.primary,
      },
      '&:focus': { },
      '&:disabled': { 
          backgroundColor: neutralColor.neutral10,
          borderColor: neutralColor.neutral10,
      },
    }
  
    const selectModelValue = (selectedModel) => {
      if(nonAdmin()) {
        // SAVE TO ANALYTICS
        captureEventGAWithParams({
            eventName: "Select Model",
            params: { category: category, page: page }
        })
        // SAVE TO DB
        captureEventToDB({
            event: { category: category, eventName: "Select Model", page: page }
        })
        // SAVE TO PIXEL
        captureEventPixel({
            eventName: "Select Model",
            params: { category: category, page: page }
        })
      }

      if(category === "Dana Tunai") {
        dispatch(
            getLiquidationEstimation({ 
                modelId: { 
                    id: selectedModel.id,
                    groupModelId: selectedDropdown.groupModel?.id ?? '',
                    year: selectedDropdown.modelYear ?? ''
                }
            })
        )
      } else if(category === "KKB") {
        dispatch(
            getKkbEstimation({ 
                type: "models",
                years: selectedDropdown.modelYear,
                groupModelId: selectedDropdown.groupModel.id,
                modelId: selectedModel.id
            })
        )
      } else if(category === "Cari Mobil") {
        const { modelYear1, modelYear2 } = printModelYear({ modelYear1: selectedDropdown.modelYear, modelYear2: selectedDropdown.modelYear2 })

        if(condition !== "semua") {
            dispatch(
                getSearchCount({ 
                    brandId: selectedDropdown.brands.id,
                    groupModelId: selectedDropdown.groupModel.id,
                    minYear: modelYear1,
                    maxYear: modelYear2,
                    modelId: selectedModel.id,
                    condition: condition === 'bekas' ? BEKAS_CONDITION : BARU_CONDITION,
                    sellType
                })
            )
        } else {
            dispatch(
                getSearchCount({ 
                    brandId: selectedDropdown.brands.id,
                    groupModelId: selectedDropdown.groupModel.id,
                    minYear: modelYear1,
                    maxYear: modelYear2,
                    modelId: selectedModel.id,
                    sellType
                })
            )
        }
      }
      
      // JIKA VALUE YANG DIPILIH MODEL MOBIL
      dispatch(selectModel(selectedModel))
      // if(section === 'search_car') searchCarRedirect({ modelValue: newValue, id: 'Model' })
      
      if(downMediumScreen) handleCloseDialog()
      else handleClose()
    }
  
    const searchSection = () => (
        <>
            <Box className='model__search'
                sx={{ padding: downMediumScreen ? "1.5rem 1.5rem 1rem" : "1.5rem" }}
            >
                <TextField
                    placeholder={"Cari Model"}
                    value={searchValue}
                    onChange={(e) => {
                        setSearchValue(e.target.value)
    
                        const searchResult = modelList.filter((model) => {
                            if (e.target.value === "") return model
                            return model.name.toLowerCase().includes(e.target.value.toLowerCase())
                        })
                        setModelOptions(searchResult)
                    }}
                    size={"regular"}
                    variant={"outlined"}
                    InputProps={{ 
                        endAdornment: 
                            <InputAdornment
                                position="end"
                                sx={{ cursor: "pointer" }}
                                onClick={() => {}}
                            >
                                <SearchIcon height={18} width={18} />
                            </InputAdornment>
                    }}
                    fullWidth
                />
            </Box>
    
            <Box className='root__brands' 
                sx={{ 
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "16px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    maxHeight: downMediumScreen ? "calc(100vh - 146px - 1.7rem)" : "320px",
                    padding: "0 1.5rem 1.5rem"
                }}
            >
                { searchValue === "" || modelOptions.length !== 0 ? 
                    <Box className='alphabetical__model'>
                        <Typography variant='body1' sx={{ ...fontPreset.body16Bold, marginBottom: "8px" }}>
                            Pilih Model
                        </Typography>
                        <ul style={{ listStyleType: "none", margin: 0, padding: 0, columns: downMediumScreen ? "1" : "2" }}>
                            { modelOptions.map((model) => (
                                <li key={`model-${model.id}`}
                                    style={{ marginBottom: downMediumScreen ? "14px" : "8px" }}
                                    onClick={() => selectModelValue(model)}
                                >
                                    <Box 
                                        sx={{ 
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: downMediumScreen ? "center" : "flex-start",
                                            transition: "color .1s ease",
                                            columnGap: "4px",
                                            color: selectedVal.id === model.id ? colorPalette.primary : "",
                                            "&:hover": { 
                                                cursor: "pointer",
                                                color: colorPalette.primary,
                                            }
                                        }}
                                    > 
                                        <Typography
                                            variant='body2'
                                            sx={{ ...fontPreset.body14Reg, marginBottom: "8px" }}
                                            style={{ 
                                                color: selectedVal.id === model.id ? colorPalette.primary : "",
                                                fontWeight: selectedVal.id === model.id ? "bold" : "regular",
                                            }}
                                        >
                                            {model.name}
                                        </Typography>

                                        { category !== "Dana Tunai" && isShowCountResult &&
                                            <Typography 
                                                variant='body2'
                                                sx={{ 
                                                    ...fontPreset.body14Reg,
                                                    marginBottom: "8px",
                                                    transition: "color .1s ease",
                                                    color: selectedVal.id === model.id ? colorPalette.primary : "",
                                                    fontWeight: selectedVal.id === model.id ? "bold" : "regular",
                                                    "&:hover": { 
                                                        cursor: "pointer",
                                                        color: colorPalette.primary,
                                                    }
                                                }}
                                            >
                                                ({priceFormat(model.countResult ?? 0)})
                                            </Typography>
                                        }
                                    </Box>
                                </li>
                            ))}
                        </ul>
                    </Box> :
                    NotFoundCallDjuBliElement({ })
                }

                { modelOptions.length !== 0 && 
                    <>
                        <Divider />
                        { NotFoundCallDjuBliElement({ title: "Tidak Menemukan Pilihan?" }) }
                    </>
                }   
            </Box>
        </>
    )

    const NotFoundCallDjuBliElement = ({ title = "Hasil pencarian tidak ditemukan.", body = "Coba hubungi DjuBli" }) => {
        return (
          <Box className="not__found__result"
            sx={{ 
              padding: "16px",
              display: "flex",
              alignItems: "center",
              rowGap: "12px",
              flexDirection: "column"
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={downSmallScreen ? fontPreset.body14Bold : fontPreset.body16Bold}
                align="center"
              >
                { title }
              </Typography>
              <Typography
                variant="body1"
                sx={downSmallScreen ? fontPreset.body14Reg : fontPreset.body16Reg}
                align="center"
              >
                { body }
              </Typography>
            </Box>
    
            <Button
              variant="contained"
              onClick={handleClickWhatsApp}
              endIcon={<WhatsAppIcon />}
              size={downSmallScreen ? "small" : "regular"}
            >
              Hubungi DjuBli
            </Button>
          </Box>
        )
    }

    const handleClearSelectedVal = () => {
        dispatch(resetModel())

        if(category === "Dana Tunai") {
            dispatch(
                getLiquidationEstimation({ 
                    modelYearId: { year: selectedDropdown.modelYear, groupModelId: selectedDropdown.groupModel?.id ?? '' }
                })
            )
        } else if(category === "Cari Mobil") {
            const { modelYear1, modelYear2 } = printModelYear({ modelYear1: selectedDropdown.modelYear, modelYear2: selectedDropdown.modelYear2 })

            // UPDATE SEARCH COUNT
            if(condition !== "semua") {
                dispatch(
                    getSearchCount({ 
                        brandId: selectedDropdown.brands.id,
                        groupModelId: selectedDropdown.groupModel.id,
                        minYear: modelYear1,
                        maxYear: modelYear2,
                        condition: condition === 'bekas' ? BEKAS_CONDITION : BARU_CONDITION,
                        sellType
                    })
                )
            } else {
                dispatch(
                    getSearchCount({ 
                        brandId: selectedDropdown.brands.id,
                        groupModelId: selectedDropdown.groupModel.id,
                        minYear: modelYear1,
                        maxYear: modelYear2,
                        sellType
                    })
                )
            }
        } else if(category === "KKB") {
            dispatch(
                getKkbEstimation({ type: "years", years: selectedDropdown.modelYear, groupModelId: selectedDropdown.groupModel.id })
            )
        }
    }

    const printModelYear = ({ modelYear1, modelYear2 }) => {
        const lowestYear = '2000'
        const currentYear = new Date().getFullYear().toString()

        if(modelYear1 === "Semua") {
            if(modelYear2 === "Semua") return { modelYear1: lowestYear, modelYear2: "" }
            else return { modelYear1: lowestYear, modelYear2: modelYear2 }
        } else {
            // MODEL YEAR 1 !== SEMUA
            if(modelYear2 === "Semua") return { modelYear1: modelYear1, modelYear2: currentYear }
            else return { modelYear1, modelYear2 }
        }
    }

    return (
      <div style={{ height: "100%", display: "flex", columnGap: "8px", alignItems: "center" }}>
          <Button 
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              fullWidth
              disableRipple
              sx={{...buttonStyle, ...bottonStyleProps}}
              endIcon={
                <div className='clear__button'
                    style={{ display: "flex", alignItems: "center", columnGap: "2px", padding: "0", margin: "0" }}
                >
                    { selectedVal !== "" &&
                        <Box
                            sx={{ 
                                padding: "0",
                                margin: "0",
                                borderRadius: "50%",
                                width: "25px",
                                height: "25px",
                                transition: "background-color .1s",
                                "&:hover": { bgcolor: neutralColor.neutral30 }
                            }}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleClearSelectedVal()
                            }}
                        >
                            <CloseIcon fontSize="small" sx={{ color: neutralColor.neutral90 }} />
                        </Box>
                    }
                    { open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon/> }
                </div>
              }
              disabled={disabled}
          >
              <Box>
                  <Typography 
                      variant='body1'
                      sx={selectedVal !== "" ? { ...fontPreset.body12Reg } : { ...fontPreset.body16Reg }}
                      align="left"
                  >
                      { label }
                  </Typography>
                  <Typography 
                      variant='body2'
                      sx={downMediumScreen ? { ...fontPreset.body16Bold } : { ...fontPreset.body18Bold }}
                      align="left"
                  >
                      { selectedVal !== "" ? selectedVal.name : "" }
                  </Typography>
              </Box>
          </Button>
  
          { downMediumScreen ? 
              <Dialog
                  fullScreen
                  open={openDialog}
                  onClose={handleCloseDialog}
              >
                  <Box className='header__dialog'
                      sx={{ display: "flex", padding: "8px 16px", alignItems: "center" }}
                  >
                      <Typography
                          variant='body1'
                          sx={{ ...fontPreset.body16Bold, flexGrow: 1 }}
                          align="center"
                      >
                          Model
                      </Typography>
  
                      <IconButton
                          onClick={handleCloseDialog}
                          size="small"
                      >
                          <CloseIcon />
                      </IconButton>
                  </Box>
                  <Divider />
  
                  <Box className='search__section'>
                      { searchSection() }
                  </Box>
              </Dialog> :
            // DESKTOP SCREEN
              <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                  }}
              >
                  <Box className='root__search' 
                      sx={{ maxWidth: "680px", width: category === "Dana Tunai" ? "100%" : "540px" }}
                  >
                      { searchSection() }
                  </Box>
              </Popover>
          }
      </div>
    )
}

SelectModelMenu.propTypes = {
    modelList: PropTypes.array,
    selectedVal: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    category: PropTypes.string,
    page: PropTypes.string,
}

export default SelectModelMenu