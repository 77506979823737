export const logoImage = [
    { id: 1, image: '/images/cars/logo/ToyotaLogo.png', title: 'toyota-logo' },
    { id: 2, image: '/images/cars/logo/HondaLogo.png', title: 'honda-logo' },
    { id: 4, image: '/images/cars/logo/MitsubishiLogo.png', title: 'mitsubishi-logo' },
    { id: 8, image: '/images/cars/logo/NissanLogo.png', title: 'nissan-logo' },
    { id: 9, image: '/images/cars/logo/BMWLogo.png', title: 'bmw-logo' },
    { id: 11, image: '/images/cars/logo/SuzukiLogo.png', title: 'suzuki-logo' },
    { id: 14, image: '/images/cars/logo/MazdaLogo.png', title: 'mazda-logo' },
    { id: 19, image: '/images/cars/logo/DaihatsuLogo.png', title: 'daihatsu-logo' },
    { id: 30, image: '/images/cars/logo/MercyLogo.png', title: 'mercedes-logo' },
]