import { Box, Typography } from '@mui/material';

export const PenaltySection = ({ children, hasBorder = false, styleProps={}, ...props }) => (
  <Box 
    sx={{ 
      pb: 3, 
      pt: 1,
      ...(hasBorder && { borderBottom: '1px solid #e0e0e0' }) ,
      ...styleProps
    }}
    {...props}
  >
    {children}
  </Box>
);

export const PenaltyGridContainer = ({ gridTemplateColumns = 'repeat(4, 1fr)', children,styleProps={} }) => (
  <Box 
    sx={{ 
      display: 'grid', 
      gridTemplateColumns: { xs: 'repeat(1, 1fr)',md:'repeat(2, 1fr)', lg: gridTemplateColumns  }, 
      gap: 1, 
      mt: 1, 
      pl: 1 ,
      ...styleProps
    }}
  >
    {children}
  </Box>
);

export const PenaltyInfoItem = ({ label, value, status="", rightIcon = null, isError = false, children, labelProps={}, valueProps={}, containerProps={}}) => (
  <Box borderLeft={`2px solid #0F967E`} pl={2} height="100%" {...containerProps}>
    <Typography color="text.secondary" variant="body2" display="flex" alignItems="center" {...labelProps}>
      {label}
      {rightIcon}
    </Typography>
    <Typography 
      variant="body2" 
      fontWeight="500"
      color={status ? "black" : "white"}
      pb={1}
      sx={{ textTransform: 'uppercase' }}
    >
      {status ||"-"}
    </Typography>
    {value && (
      <Typography 
        variant="body1" 
        fontWeight="500"
        color={isError ? "error.main" : "inherit"}
        {...valueProps}
      >
        {value}
      </Typography>
    )}
    {children}
  </Box>
);

export const PenaltySectionTitle = ({ title }) => (
  <Typography variant="subtitle1" gutterBottom fontWeight="500">
    {title}
  </Typography>
);
