import * as React from 'react';
import {Popover, Box, Typography} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';


export const ValueEmptyPopover = ({label}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <span
        aria-owns={open ? 'value-empty-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ cursor: 'pointer', color: '#0F967E', fontWeight: 'bold' ,marginLeft: '5px', position: 'absolute'}}
      >
        <InfoIcon fontSize="small" />
      </span>
      <Popover
        id="value-empty-popover"
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1, backgroundColor: '#f5f5f5' }}>
          <Typography variant="body1" fontSize="14px">Belum ada data biaya untuk <strong>{label?label:"kategori ini"}</strong></Typography>
          <Typography variant="body1" fontSize="14px">Silahkan lakukan <strong>Ajukan Inspeksi Kendaraan</strong> <br/> untuk mendapatkan detail biaya</Typography>
        </Box>
      </Popover>
    </>
  );
}
