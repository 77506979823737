import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  ToggleButton,
  ToggleButtonGroup,
  Divider
} from '@mui/material';
import { priceFormat } from '../../constants/djubliKreditConstant';
import SkeletonText from '../../components/common/SkeletonText';
import { PenaltyGridContainer, PenaltyInfoItem, PenaltySection, PenaltySectionTitle } from './PenaltyDetail/PenaltyDetailSectionComponents';
import { ValueEmptyPopover } from './PenaltyDetail/PopoverDetails/ValueEmptyPopover';

/**
 * PriceAnalysisSection Component
 * Displays price analysis information including market average price and recommended price
 * 
 * @param {Object} salePriceData - Contains price range information
 * @param {Function} handleOpenMarketPriceModal - Handler for opening market price details modal
 */
const PriceAnalysisSection = ({ isLoading, salePriceData, handleOpenMarketPriceModal }) => {
  const [recommendedPrice, setRecommendedPrice] = useState("recommended_price_1");

  const recommendedLabel = {
    "recommended_price_3":{label: "Sedang", bgColor: "#FFEADD", color: "#FF9555",repair: "repair_cost_3"},
    "recommended_price_2":{label: "Baik", bgColor: "#E7EEFF", color: "#4876E0",repair: "repair_cost_2"},
    "recommended_price_1":{label: "Sangat Baik", bgColor: "#D2FFE1", color: "#0F967E",repair: ""},
  }
  const recommendedCondition = {
    "recommended_price_1":["Terlihat sangat bagus dan seperti baru","Bebas goresan atau penyok","Semua bagian utuh dan tidak perlu diperbaikan"],
    "recommended_price_2": ["Terlihat dan bisa dikemudikan dengan baik","Sedikit goresan kecil, guratan, atau penyok","Beberapa bagian butuh sedikit perbaikan/cat ulang"],
    "recommended_price_3": ["Terlihat bagus dan bisa dikemudikan","Beberapa goresan, somplak, atau penyok besar","Beberapa bagian hilang/butuh perbaikan"],
  }


  const taxDetailsData = salePriceData?.Tax?.Data
    ? Object.fromEntries(
        Object.entries(salePriceData?.Tax?.Data).sort(([keyA], [keyB]) => {
          if (keyA.toLowerCase().includes("overdue")) return -1;
          if (keyB.toLowerCase().includes("overdue")) return 1;
          if (keyA.toLowerCase().includes("total")) return 1;
          if (keyB.toLowerCase().includes("total")) return -1;
          return 0;
        })
      )
    : {};

  const taxStatus = taxDetailsData.overdue || taxDetailsData.OVERDUE;
  const taxPaid = taxStatus ? taxDetailsData.total || taxDetailsData.TOTAL || 0 : 0  ;

  const marginPrice = Math.abs(salePriceData?.margin_price || 0);
  const warnaPenaltyPrice = Math.abs(salePriceData?.color_penalty_price || 0);
  const kmPenaltyPrice = Math.abs(salePriceData?.km_penalty_price || 0);
  const kmPenaltyAbsolutePrice = Math.abs(salePriceData?.km_penalty_absolute_price || 0);
  // const carAgePenaltyPrice = Math.abs(salePriceData?.car_age_penalty_price || 0);

  const valueMap = (value, label) => {
    if(value === 0) {
      return <>- Rp 0 <ValueEmptyPopover label={label} /></>;
    }
    return `- Rp ${priceFormat(value)}`;
  }

  // Market Price section data
  const marketPriceData = [
    { label: "Pergerakan Pasar",
      status:salePriceData?.margin, 
      value: salePriceData?.margin_price < 0 ? `- Rp ${priceFormat(marginPrice)}` : `+ Rp ${priceFormat(marginPrice)}`,
      originalValue: salePriceData?.margin_price || 0,},
    // { label: "Rata-rata harga pasar", value: `Rp ${priceFormat(salePriceData?.average_price || 0)}`, originalValue: 0 },
    // {
    //   label: "Penalti Market Movement",
    //   value: salePriceData?.margin_price < 0 ? `- Rp ${priceFormat(marginPrice)}` : `+ Rp ${priceFormat(marginPrice)}`,
    //   originalValue: salePriceData?.margin_price || 0,
    // },
    {
      label: "Pemakaian Kilometer",
      status:salePriceData?.km_penalty_category,
      value: salePriceData?.km_penalty_price < 0 ? `- Rp ${priceFormat(kmPenaltyPrice)}` : `+ Rp ${priceFormat(kmPenaltyPrice)}`,
      originalValue: salePriceData?.km_penalty_price || 0,
    },
    {
      label: "Persepsi Kilometer",
      status:salePriceData?.km_penalty_absolute_category,
      value: salePriceData?.km_penalty_absolute_price < 0 ? `- Rp ${priceFormat(kmPenaltyAbsolutePrice)}` : `+ Rp ${priceFormat(kmPenaltyAbsolutePrice)}`,
      originalValue: salePriceData?.km_penalty_absolute_price || 0,
    },
    {
      label: "Preferensi Warna",
      status:salePriceData?.color_penalty_category,
      value: salePriceData?.color_penalty_price < 0 ? `- Rp ${priceFormat(warnaPenaltyPrice)}` : `+ Rp ${priceFormat(warnaPenaltyPrice)}`,
      originalValue: salePriceData?.color_penalty_price || 0,
    },
    // {
    //   label: "Penalti Umur Mobil",
    //   value: salePriceData?.car_age_penalty_price < 0 ? `- Rp ${priceFormat(carAgePenaltyPrice)}` : `+ Rp ${priceFormat(carAgePenaltyPrice)}`,
    //   originalValue: salePriceData?.car_age_penalty_price || 0,
    // },
    {
      label: "Pajak Kendaraan",
      status: salePriceData?.Tax?.Category,
      value: valueMap(taxPaid, "Pajak Kendaraan"),
      originalValue: taxPaid > 0 ? -1 : 0,
    },
    // {
    //   label: "Biaya Perbaikan",
    //   value: valueMap(repairCost, "Biaya Perbaikan"),
    //   originalValue: repairCost ===0 ? 0:-1,
    // },
  ];

  return (
    <>
      <Paper elevation={0} sx={{ p: 2, borderRadius: 2, border: '1px solid #D0D6DC' }}>
        <Typography variant="h6" gutterBottom>
          Kondisi Mobil
        </Typography>
        <Box>
          {/* Toggle button for recommended price */}
          <ToggleButtonGroup
            exclusive
            size="small" 
            value={recommendedPrice}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                setRecommendedPrice(newValue);
              }
            }}
            sx={{ 
              boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.07)',
              mb: 2, 
              width: '100%', 
              "& .css-r4rftc-MuiToggleButtonGroup-root": { borderColor: "transparent !important" },
              "& .Mui-selected": { backgroundColor: `${recommendedLabel[recommendedPrice].bgColor} !important`, color: `${recommendedLabel[recommendedPrice].color} !important` }, 
            }}
          >
            {Object.keys(recommendedLabel).map((key) => (
              <ToggleButton key={key} value={key} sx={{ width: 1/3}}>{recommendedLabel[key].label}</ToggleButton>
            ))}
          </ToggleButtonGroup>

          <Box display="flex" flexDirection="column" gap={1}>
            <Box sx={{  
              mb: 2,
              p: 1.5,
              backgroundColor: recommendedLabel[recommendedPrice].bgColor, 
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.05)',
            }}>
              <SkeletonText
                isLoading={isLoading}
                variant="h5"
                text={`Rp ${priceFormat(salePriceData[recommendedPrice] || 0)}`}
                typographyProps={{ fontWeight: "bold" }}
                skeletonProps={{
                  height: 32,
                }}
              />
            </Box>
            <Typography variant="caption" color="text.secondary" fontWeight="500">
            Kondisi mobil {recommendedLabel[recommendedPrice].label}:
            </Typography>
            <Typography variant="caption" color="text.secondary">
              <ul style={{ listStyleType: 'disc', paddingLeft: 20, margin: 0 }}>
                {recommendedCondition[recommendedPrice].map((condition, index) => (
                  <li key={index}>{condition}</li>
                ))}
              </ul>
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Paper elevation={0} sx={{ p: 2, borderRadius: 2, border: '1px solid #e6e5e5' }}>
        <Box>
            <Typography variant="h6">
              Penilaian Harga
            </Typography>
            <Divider sx={{my: 1, borderColor: "#f5f5f5"}}/>
            <Box display="flex" flexDirection={{xs: "column", md: "row"}} gap={1} pb={1}>
              <Box display="flex" pb={2} flexDirection="column" gap={0} width={{xs: "100%", md: "21%"}} borderRight={{xs: "none", md:`1px solid #e6e5e5`}} borderBottom={{xs: "1px solid #D0D6DC", md: "none"}}>
                <PenaltySectionTitle title="Average Harga Pasar" />
                <Typography variant="caption" color="text.secondary" pt={.75}>
                  Estimasi harga transaksi setelah nego
                </Typography>
                <Typography 
                  pt={1}
                  variant="body1" 
                  fontWeight="500"
                >
                  Rp {priceFormat(salePriceData?.nego_price || 0)}
                </Typography>
              </Box>
              <PenaltySection styleProps={{mt: 0, pt: 0, width: {xs: "100%", md: "79%"}}}>
                <PenaltySectionTitle title="Modifiers" />
                <PenaltyGridContainer 
                  gridTemplateColumns={ "repeat(5, 1fr)"} 
                  styleProps={{
                    pl: 0, 
                    gap: {xs: 1, lg: 0}, 
                    height: "fit-content",
                    // border: "1px solid #00937c", 
                    borderRadius: 1, 
                  }}
                >
                  {marketPriceData.map((item, index) => (
                    <PenaltyInfoItem key={index} {...item} 
                      labelProps={{variant: "caption", noWrap: true}} 
                      containerProps={{
                        p: 0.5, 
                        // borderLeft: "none", 
                        height: "inherit",
                        borderLeft: {xs: "none", md: "1px solid #00937c"}, borderBottom: {xs: "1px solid #00937c", md: "none"} 
                      }}
                      valueProps={{fontWeight: "bold", noWrap: true, color: item.originalValue < 0 ? "error.main" :item.originalValue > 0 ? "success.main" : "inherit"}}
                    />
                  ))}
                </PenaltyGridContainer>
              </PenaltySection>
            </Box>
        </Box>
      </Paper>
    </>
  );
};

export default PriceAnalysisSection;
