import React from 'react';
import { 
  Box, 
  TableContainer, 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell,
  TablePagination, 
  Button
} from '@mui/material';
import { priceFormat } from '../../constants/djubliKreditConstant';
import SortIcon from './SortIcon';

const COLOR_MAPPING = {
  "HITAM": "000000",
  "ABU-ABU": "808080",
  "PUTIH": "FFFFFF",
  "SILVER": "C0C0C0",
  "COKLAT": "964B00",
  "MERAH": "FF0000",
  "KUNING": "FFFF00",
  "EMAS": "FFD700",
  "BIRU": "0000FF",
  "ORANYE": "FFA500",
  "MARUN": "800000",
  "HIJAU": "00FF00",
  "UNGU": "A020F0",
  "PINK": "FFC0CB"
};

const MarketPriceTable = ({ 
    setViewMore = () => {},
    salePriceData,
    tableData:_tableData = [], 
    maxRows,
    isPagination=true,
    tableStyleProps={}
}) => {
  const isViewMore = maxRows && _tableData?.length > maxRows;

  // Pagination and sorting states
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState('asc');

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Sorting handlers
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  // Sort data based on selected column and order
  const sortData = (data) => {
    if (!orderBy) return data;

    return [...data].sort((a, b) => {
      let aValue = a[orderBy];
      let bValue = b[orderBy];

      // Special handling for Price and Kilometer columns
      if (orderBy === 'Price') {
        aValue = Number(aValue);
        bValue = Number(bValue);
      } else if (orderBy === 'Kilometer') {
        // Convert to string first to safely handle both number and string inputs
        aValue = String(aValue).split('-')[0].trim();
        bValue = String(bValue).split('-')[0].trim();
        aValue = Number(aValue);
        bValue = Number(bValue);
      }

      if (order === 'desc') {
        return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
      }
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    });
  };

  const tableData = maxRows? _tableData.slice(0, maxRows) : _tableData;

  const sortedData = sortData(tableData);
  const getDisplayedData = () => {
    if (isViewMore && !isPagination && _tableData.length > 5) {
      // Mengambil 4 data pertama dan 1 data terakhir
      const firstFourRows = sortedData.slice(0, 4);
      const lastRow = sortedData[sortedData.length - 1];
      
      // Menambahkan row kosong sebagai indikator skip
      const skipRow = {
        ..._tableData[0],
        id: 'skip-row',
        Year: '...',
        'Master Name': '...',
        Color: '',
        Kilometer: '...',
        'Price Cash': '...',
        Marketplace: '...',
        Link: null
      };

      return [...firstFourRows, skipRow, lastRow];
    }

    return sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };

  const displayedData = getDisplayedData();

  // Modifikasi handleRowClick untuk mengabaikan row skip
  const handleRowClick = (link) => {
    if (link) {
      window.open(link, '_blank', 'noopener,noreferrer');
    }
  };
  return (
    <Box borderRadius={1} border={1} borderColor="divider">
      <TableContainer sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 421 , ...tableStyleProps}}>
        <Table size="small">
          <TableHead sx={{ bgcolor: '#F6F6F9' }}>
            <TableRow>
              <TableCell align="center">No</TableCell>
              <TableCell onClick={() => handleRequestSort('Year')} sx={{ cursor: 'pointer' }}>
                <Box display='flex' justifyContent='flex-start'>
                  Tahun<SortIcon order={order} isOrder={orderBy === 'Year'} />
                </Box>
              </TableCell>
              <TableCell onClick={() => handleRequestSort('Master Name')} sx={{ cursor: 'pointer' }}>
                <Box display='flex' justifyContent='flex-start'>
                  Model<SortIcon order={order} isOrder={orderBy === 'Master Name'} />
                </Box>
              </TableCell>
              <TableCell align="center">Warna</TableCell>
              <TableCell onClick={() => handleRequestSort('Kilometer')} sx={{ cursor: 'pointer' }}>
                <Box display='flex' justifyContent='center'>
                  Jarak Tempuh<SortIcon order={order} isOrder={orderBy === 'Kilometer'} />
                </Box>
              </TableCell>
              <TableCell onClick={() => handleRequestSort('Price Cash')} sx={{ cursor: 'pointer' }}>
                <Box display='flex' justifyContent='center' alignItems='center'>
                  Harga Cash<SortIcon order={order} isOrder={orderBy === 'Price Cash'} />
                </Box>
              </TableCell>
              <TableCell onClick={() => handleRequestSort('Marketplace')} align="center" sx={{ cursor: 'pointer' }}>
                <Box display='flex' justifyContent='center'>
                  Sumber<SortIcon order={order} isOrder={orderBy === 'Marketplace'} />
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedData.map((row, index) => (
              <TableRow 
                key={row.id}
                onClick={() => handleRowClick(row.Link)}
                sx={{ 
                  cursor: row.id === 'skip-row' ? 'default' : 'pointer',
                  '&:hover': { 
                    bgcolor: row.id === 'skip-row' ? 'inherit' : 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <TableCell align="center" sx={{ width: '5%' }}>
                  {row.id === 'skip-row' ? '...' : 
                    isViewMore && !isPagination && index === displayedData.length - 1 
                      ? _tableData.length 
                      : isPagination 
                        ? page * rowsPerPage + index + 1
                        : index + 1
                  }
                </TableCell>
                <TableCell align="left" sx={{ width: '9%' }}>{row.Year}</TableCell>
                <TableCell align="left" sx={{ width: '25%' }}>{row['Master Name']}</TableCell>
                <TableCell align="center" sx={{ width: '13%' }}>
                  {row.id === 'skip-row' ? (
                    '...'
                  ) : (
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Box 
                        sx={{ 
                          width: 15, 
                          height: 15, 
                          backgroundColor: `#${COLOR_MAPPING[row.Color?.toUpperCase()] || 'FFFFFF'}`,
                          borderRadius: "50%", 
                          marginRight: "5px", 
                          border: '1px solid #E0E3E7' 
                        }} 
                      />
                      {row.Color}
                    </Box>
                  )}
                </TableCell>
                <TableCell align="center" sx={{ width: '13%' }}>
                  {row.id === 'skip-row' ? '...' : `${priceFormat(row.Kilometer)} km`}
                </TableCell>
                <TableCell align="center" sx={{ width: '22%' }}>
                  {row.id === 'skip-row' ? '...' : `Rp ${priceFormat(row['Price Cash'])}`}
                </TableCell>
                <TableCell align="center" sx={{ width: '13%' }}>{row.Marketplace}</TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ bgcolor: 'lightgray' }}>
                <TableCell align="left" sx={{ width: '52%', fontWeight: 'bold' }} colSpan={4}>Average</TableCell>
                <TableCell align="center" sx={{ width: '13%', fontWeight: 'bold' }}>{priceFormat(salePriceData?.average_kilometer || 0)} km</TableCell>
                <TableCell align="center" sx={{ width: '22%', fontWeight: 'bold' }}>Rp {priceFormat(salePriceData?.average_price || 0)}</TableCell>
                <TableCell align="center" sx={{ width: '13%' }}></TableCell>
            </TableRow>
            <TableRow sx={{ bgcolor: '#f9ffcf' }}>
                <TableCell align="left" sx={{ width: '52%', fontWeight: 'bold' }} colSpan={4}>Estimasi average harga transaksi setelah nego</TableCell>
                <TableCell align="center" sx={{ width: '13%' }}></TableCell>
                <TableCell align="center" sx={{ width: '22%', fontWeight: 'bold' }}>Rp {priceFormat(salePriceData?.nego_price || 0)}</TableCell>
                <TableCell align="center" sx={{ width: '13%' }}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {isPagination && (
            <TablePagination
                component="div"
                count={tableData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
        )}
        {isViewMore && (
          <Button variant="text" sx={{ bgcolor: '#E2F9F5' }} onClick={setViewMore}>
            Lihat Lebih Banyak
          </Button>
        )}
      </TableContainer>
    </Box>
  );
};

export default MarketPriceTable; 