import {
    SELECT_MERK,
    SELECT_GROUP_MODEL,
    SELECT_MODEL,
    SELECT_MODEL_YEAR,
    RESET_MERK,
    RESET_GROUP_MODEL,
    RESET_MODEL,
    RESET_MODEL_YEAR,
} from '../constants/brandConstants'
import { 
    RESET_EXTERIOR_COLOR,
    RESET_KILOMETER,
    RESET_NOMOR_POLIS,
    SELECT_EXTERIOR_COLOR,
    SELECT_KILOMETER,
    SELECT_NOMOR_POLIS,
} from '../constants/sellPriceContants'

const initialState = {
    brands: null,
    groupModel: null,
    modelYear: null,
    model: null,
    exteriorColor: null,
    kilometer: null,
    nomorPolis: null    
}

export const sellPriceSelectedReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_MERK:
            return { ...state, brands: action.payload, disableGroupModel: false }
        case SELECT_GROUP_MODEL:
            return { ...state, groupModel: action.payload, disableModelYear: false }
        case SELECT_MODEL_YEAR:
            return { ...state, modelYear: action.payload, disableModelYear2: false }
        case SELECT_MODEL:
            return { ...state, model: action.payload }
        case SELECT_EXTERIOR_COLOR:
            return { ...state, exteriorColor: action.payload  }
        case SELECT_KILOMETER:
            return { ...state, kilometer: action.payload  }
        case SELECT_NOMOR_POLIS:
            return { ...state, nomorPolis: action.payload  }
        case RESET_MERK:
            return { ...state, brands: null }
        case RESET_GROUP_MODEL:
            return { ...state, groupModel: null }
        case RESET_MODEL:
            return { ...state, model: null }
        case RESET_MODEL_YEAR:
            return { ...state, modelYear: null }
        case RESET_EXTERIOR_COLOR:
            return { ...state, exteriorColor: null  }
        case RESET_KILOMETER:
            return { ...state, kilometer: null  }
        case RESET_NOMOR_POLIS:
            return { ...state, nomorPolis: null }
        default:
            return state
    }
}
